import {locPermissionDenied, locNotAvailable, locTimedOut, locUnknownError, locBrowserNotComaptible} from "../components/Toast";

async function getLocation() {

    //Dont need state in this one since it will only return a value once..unlike watchLocation
    const geoLoc = navigator.geolocation

    async function checkPermission(){ //Catch on error
        const permission = await navigator.permissions.query({ name: "geolocation" });
        return permission
    }

    const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge:10000
      };

    function getPosition(){
        const locationPromise = new Promise((resolve, reject)=>{
            geoLoc.getCurrentPosition(resolve, reject, options)
        })
        return locationPromise
    }

    if(!geoLoc){ //Browser doesnt support navigator.geolocation
        alert("sideQuest is not compatible with your current browser.  Please try Chrome or Edge")
        return "error"
    } 

    const permission = await checkPermission()    

    if(permission.state == "denied"){
        //alert("GPS is not enable for your browser.  You need GPS enabled to play sideQuest")
        console.log("promise rejected", permission)
        return "error"
    } 
 
    try{
        const position = await getPosition()
        console.log("promise position", position)
        return (position)

    }catch(err){
        console.log("promise error", err)
        return "error"
    }
}

export default getLocation;