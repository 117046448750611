import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useQuestContext} from "../../../context/QuestContext"
import QuestList from '../QuestList';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { ButtonIcon } from 'react-rainbow-components';
import CloseButton from '../common/CloseButton';


const Drawer = styled.div`
    height:96vh;
    width:30vw;
    background:#F9F9F9;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 2px 3px 5px grey;
    position: absolute;
    left:-30vw;
    transform: ${props => props.isOpen ? `translateX(30vw)` : `translateX(0vw)` };
    transition: all ease 0.4s;
    z-index:1001;
`;

function SideDrawer({children, selectedQuest, showButton}) {

    //This will need state passed in from the parent to determine isOpen
    const {quests} = useQuestContext()
    const [isOpen, setIsOpen] = useState(false);
    console.log("sideDrawer selectedQuest", selectedQuest, isOpen)

    useEffect(()=>{
        if(selectedQuest !== null && Object.keys(selectedQuest).length > 0){
            setIsOpen(true)
        } else{
            setIsOpen(false)
        }
    }, [selectedQuest])



    function selectQuestTemp(){
        console.log("quest selected")
    }


    return (
        <div >
            {<Drawer isOpen={isOpen}>
                <CloseButton close={()=>setIsOpen(!isOpen)}/>
                {children}
            </Drawer>}
            
            {showButton && 
            <div className="rainbow-p-right_large">
                <ButtonIcon 
                    shaded 
                    variant="border-filled" 
                    icon={<MenuRoundedIcon style={{color:"white"}}/>}
                    style={menuButton}
                    onClick={()=>setIsOpen(!isOpen)}
                />
            </div>}

        </div>
    );
}

export default SideDrawer;


const menuButton = {
    position:"absolute", 
    //height:50, 
    //width:50, 
    //borderRadius:25, 
    backgroundColor:"#6600FF", 
    right:0, 
    top:200, 
    //display:"flex", 
    //justifyContent:"center", 
    //alignItems:"center",
    //boxShadow: "2px 3px 5px grey"

}