import { useMediaQuery } from 'react-responsive'

//TODO: Replace isMobile with this function 
//TODO: This is used often and should be moved to a context
export default function useIsTablet() {

    const isTablet = useMediaQuery(
        {minDeviceWidth: 768, maxDeviceWidth:1024}
    )

    console.log("isTablet", isTablet)
    return (isTablet)
} 