//import getLocation from "../location/GetUserLocation";
import getLocation from "../../../location/GetUserLocationState"
import BaseClient from "../../../API/BaseClient";

const URL = "/api/areaquest/get_area_quests/"

//TODO: Fix up this API call...poor architecture
async function getAreaQuests(userLocation={}) {

    let obj = {};
    //const userLocation = await getLocation()

    if(userLocation == "error"){userLocation=null; return "error"}

    try{
        
        const response = await BaseClient.post(URL, 
            {latitude: userLocation?.latitude, longitude: userLocation?.longitude}
        )

        console.log("API response", response)

        if(response.data){
            obj.status="ok"
            obj.data = response.data
        }

        return obj
    }

    catch(error){
        
        if(error.response){ obj.status="Error"}
        else if(error.request){ obj.status="Error"}
        return obj
    }
}

export default getAreaQuests;