//import getLocation from "../location/GetUserLocation";
import BaseClient from "./BaseClient";
import getLocation from "../location/GetUserLocationState"

const URL = "/api/randomprize/open_random_prize/"

async function openRandomPrize(prize) {

    let obj = {};
    const userLocation = await getLocation()
    console.log("userLocation", userLocation)

    if(userLocation == "error") {
        obj.status = "error"
        return obj
    }
    

    try{
        const response = await BaseClient.post(URL, {
                random_prize_id: prize?.id, 
                user_location: {
                    latitude: userLocation?.coords?.latitude, 
                    longitude: userLocation?.coords?.longitude
                }
        })

        console.log("openRandomPrize API response", response)

        if(response.data){
            obj.status="ok"
            obj.data = response.data
        }

        return obj
    }

    catch(error){
        
        if(error.response){ obj.status="error"}

        else if(error.request){ obj.status="error"}

        return obj
    }
}

export default openRandomPrize;