import React, {useEffect, useState} from 'react';
import CountDownTimer from '../../../../../common/components/CountDownTimer';
import AreaQuestPrizeList from '../AreaQuestPrizeList';
import { QuestContext, useQuestContext } from '../../../../context/questContext';
import RechargeScratcher from '../scratcher/RechargeScratcher';
import openRechargePrize2 from '../../../../api/openRechargePrize';
import RechargeHintText from '../hints/RechargeHintText';
import ErrorComponent from '../scratcher/ErrorComponent';
import { useUserLocContext } from '../../../../../../context/UserLocContext';
import { useWonPrizeContext } from '../../../../context/wonPrizeContext';


function AreaQuestRechargeModal({showInfo, isLoading, setIsLoading, apiErrorCode, setApiErrorCode}) {

    const [refresh, setRefresh] = useState(false)
    const {updateQuest, selectedQuestRaw, selectedQuestModified, isUserInside} = useQuestContext()
    const {userLocation} = useUserLocContext()
    const {refreshWonPrizes} = useWonPrizeContext()


    const now = new Date()
    const recharged = selectedQuestModified?.recharged
    const isRecharged = selectedQuestModified?.recharged ? now > selectedQuestModified?.recharged : true
    const isAlreadyWon = selectedQuestModified?.winningEntrees?.length > 0
    const isClaimable = isRecharged && isUserInside && !isAlreadyWon  //isAlreadyWon shouldnt be part of isClaimable


    async function handleOpenPrize(){
        setIsLoading(true)
        const response = await openRechargePrize2(selectedQuestModified?.id, userLocation?.simple)
        
        if(response.status != 200) {
            setIsLoading(false) 
            if(response.status == 406) setApiErrorCode(response?.data?.code)
            else setApiErrorCode(99) //Render generic error component
            return
        } 
        
        if(response?.data?.prize) refreshWonPrizes()
        const copy = {...selectedQuestRaw}
        copy.goal[0].entrants.push(response.data)
        copy.goal[0].latest_entrant = response.data.created
        updateQuest(copy)
        setIsLoading(false)
    }

    useEffect(()=>{

        if(recharged - now < 0 ) return    
        const timer = setTimeout(()=>setRefresh(!refresh) ,recharged - now)
        return ()=> clearTimeout(timer)

    }, [selectedQuestModified])


    return ( 
        showInfo ? <InfoModal/> :
        <>  
            <div style={{marginBottom:45}}></div>
            <div style={titleStyle}>{selectedQuestModified?.name}</div>
            <div style={descriptionStyle}>{selectedQuestModified?.description}</div>
            <span style={{fontSize:12, paddingTop:15}}>Try again in:</span>

            <CountDownTimer 
                scale={0.55} 
                raffleStatus={false} 
                subTitle={false} 
                endDate={!isAlreadyWon && recharged?.toString()} //Dont reset timer on a win
                endCallback={()=> setRefresh(!refresh)}
            />

            <AreaQuestPrizeList prizes={selectedQuestModified?.prizes}/>
            
            <div style={{marginBottom:15}}></div>

            {apiErrorCode ? <ErrorComponent apiErrorCode={apiErrorCode}/> : 
            <RechargeScratcher 
                isEnabled={isClaimable} 
                apiCall={handleOpenPrize} 
                isLoading={isLoading} 
                isRecharged={isRecharged} 
            />}

            <RechargeHintText isAlreadyWon={isAlreadyWon} isUserInside={isUserInside} isRecharged={isRecharged}/>
        </>
    );
}

const descriptionStyle = {
    display:"flex", 
    justifyContent:"center", 
    width:"90%", 
    textAlign:"center", 
    fontSize:12, 
}

const titleStyle = {
    fontSize:16, 
    fontWeight: "bold"
}

export default AreaQuestRechargeModal;


function InfoModal(){

    const container = {
        display:"flex", 
        flexDirection:"column",
        justifyContent:"space-around",
        alignItems: "center", 
        width:"100%",
    }

    const section = {
        display:"flex", 
        flexDirection:"column",
        width:"100%", 
    }

    const heading = {
        fontSize: 12, 
        fontWeight: "bold", 
    }

    const text = {
        fontSize:12, 
        color:"grey", 
        textAlign: "left"
    }


    return(
        <div style={container}>
            {/*<div style={image}></div>
            <span style={{fontWeight:"bolder", color:"deeppink"}}>Recharge Quest</span>*/}
            <div style={section}>
                <span style={heading}>How to Play</span>
                <span style={text}>Visit a quest location for a chance to win</span>
                <span style={text}>Come back every 24hrs for another try</span>
            </div>

            <div>
                <span style={heading}>Rules</span>
                <span style={text}>Odds of winning are approximate</span>
                <span style={text}>No purchase necessary</span>
                <span style={text}>Errors and malfunction void wins</span>
            </div>

        </div>
    )
}