import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseUser, faPlayCircle, faWallet, faCog} from '@fortawesome/free-solid-svg-icons';
import { NavLink, useLocation } from 'react-router-dom';
import { useWonPrizeContext } from '../../context/wonPrizeContext';
import NotificationBubble from './NotificationBubble';
import { useAuthContext } from '../../../../context/AuthContext';


/*TODO 
    1. Disable wallet button if user isnt authenticated. 
*/

function TabBar(props) {

    const location = useLocation()
    const {numClaimablePrizes} = useWonPrizeContext()
    const {isAuthed}  = useAuthContext()

    console.log("isAuthed", isAuthed)

    function getIconColor(path){

        if(path == location.pathname) return selectedIcon
        else return notSelectedIcon
    }

    return (
        <div style={navContainer}>

            <NavLink to={"/home"} style={{textDecoration:"none"}}>
                <div style={iconContainer}>
                    <FontAwesomeIcon 
                        icon={faHouseUser} 
                        style={getIconColor('/home')}
                    />
                    <span style={textStyle}>Home</span>
                </div>
            </NavLink>

            <NavLink to={"/play"} style={{textDecoration:"none"}}>
                <div style={iconContainer}>
                    <FontAwesomeIcon 
                        icon={faPlayCircle} 
                        style={getIconColor('/play')}
                    />
                    <span style={textStyle}>Play</span>
                </div>
            </NavLink>

            <NavLink to="/wallet" style={{textDecoration:"none"}}>
                <div style={iconContainer}>
                    <FontAwesomeIcon 
                        icon={faWallet} 
                        style={getIconColor('/wallet')}
                    />
                    <span style={textStyle}>Wallet</span>
                    {numClaimablePrizes > 0 && <NotificationBubble number={numClaimablePrizes}/>}
                </div>
            </NavLink>

            <NavLink to="/settings" style={{textDecoration:"none"}}>
                <div style={iconContainer}>
                    <FontAwesomeIcon 
                        icon={faCog} 
                        style={getIconColor('/settings')}
                    />
                    {!isAuthed 
                    ? <div style={authNotificationBubble}>Signup</div> 
                    : <span style={textStyle}>Account</span>}
                </div>
            </NavLink>

        </div>
    );
}

export default React.memo(TabBar);

const notSelectedIcon = {
    color:"white",
    stroke:"grey", 
    strokeWidth:40, 
    fontSize:20
}

const selectedIcon = {
    color:"deeppink", 
    fontSize:20
}

const navContainer = {
    height:53, 
    width:"100%", 
    position:"fixed", 
    display:"flex", 
    alignItems:"center",
    justifyContent:"space-around", 
    bottom:0, 
    backgroundColor:"white",
    borderTopWidth:1,
    borderTopStyle:"solid",
    borderColor:"#DDDDDD", 
    zIndex:1000 //Prevent tab bar from being over-layed by the map and homepage. 
}

const textStyle = {
    color:"grey", 
    fontSize:10, 
}

const iconContainer = {
    display:"flex", 
    position:"relative",
    flexDirection:"column", 
    justifyContent:"center", 
    alignItems:"center", 
}

const authNotificationBubble = {
    display:"flex", 
    paddingRight: "10%", 
    paddingLeft: "10%", 
    backgroundColor:"dodgerblue", 
    borderRadius:50, 
    fontSize:10, 
    fontWeight:"bolder", 
    color:"white", 
}


