import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Input, DateTimePicker, Textarea, Card, TableWithBrowserPagination, HelpText, Column, Button, Picklist, Option, ButtonIcon, MenuItem, ButtonGroupPicker, ButtonOption, Accordion, AccordionSection, Pagination} from 'react-rainbow-components';
import PrizePicker2 from './PrizePicker2';
import LocalActivityRoundedIcon from '@mui/icons-material/LocalActivityRounded';
import AddIcon from '@mui/icons-material/Add';
import _, { min } from "lodash";
import  Joi from 'joi-browser';
import httpService from '../../API/Client';
import URL_ROOT from '../URL_ROOT';
import RaffleTimer from '../common/RaffleTimer';
import PrizeTable from '../PrizeTable';
import WinnerTable from '../WinnerTable';
import MediaQuery from '../common/MediaQuery';
import CardInputContainer from './CardInputContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faTrashAlt, faPenToSquare} from '@fortawesome/free-regular-svg-icons';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import {prizeInputParams} from  '../../utils/inputParams'
import { toggleButtonClasses } from '@mui/material';
import { Create, KeyboardReturnRounded } from '@mui/icons-material';
import DynamicText from '../create/DynamicText';
import CreateCardHeaderText from '../create/CreateCardHeaderText';


/*
Need a switch statement to render a different component depending on the prize type selected 
or will it...."goal" will always be "goal"
Status will change

NOTES
1. Can pass in a prop to tell component if create mode or review mode for what is rendered.
2. Have awaded etc. as seperate columns if in dshboard...turn off in create mode 
3. Add section for details (not create) i.e. status (not awarded, awardng), awarded, claimed
4. Add info popup to each header item
5. Hide the column headers for awarded etc if create mode.
6. Disable the next button if less than 1 token is selected
*/

/* 
TODO
1. Add "prize_code" to the redemption options
*/

const StyledText = styled.div`
    color:grey;
    font-size:20px;
    font-weight:bold;
`;

const Row = styled.div`
    display:flex;
    flex-direction:row;
    align-items: center;
    margin:20px;
`;

const ColumnTitle = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    flex:1
`;

const ColumnData = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    flex:5
`;


function QuestPrizeLayout2({ 
    prizeExpire,  // 
    setPrizeExpire, //
    questEnd, //
    quest, // quest object 
    refreshQuestData, // Gets a fresh set of quest data when the raffle timer ends.  
    questCreate=false, // Selects if the layout should be in create or edit mode. 
    markers, // Create the goal picker object i.e. number or location to receive a raffle. 
    goal, // Main state variable that holds the goal object for the quest. 
    setGoal, // Method to modify the goal state variable. 
    setError // 
    }) {
    
    
    const [isEditable, setIsEditable] = useState(questCreate ? true : false); //Set editabilty of the goal and associated prizes. 
    const [origData, setOrigData] = useState([]); //Copy of the original data for revert if "save" is cancelled. 
    const [isErrors, setIsErrors] = useState([]); //tracks errors for "next" or "save"


    //MINIMUM DATE FOR THE PRIZE EXPIRY -> Quest End = 24hrs. 
    let minDate = new Date(questEnd || quest?.quest_end);
    minDate.setDate(minDate.getDate() + 1)


    //MODIFY THE DISPLAY BASED ON THE DEVICE
    const isMobile = MediaQuery();
    const deviceStyle = {
        containerWidth: isMobile ? "90%" :  "70vw", 
        raffleTimerBox: isMobile ? "100%" : "65%",
        addPrizeButtonLeft: isMobile ? "10%" : "18%",
        minInputWidth: isMobile ? "80%" : "55%", 
        awardDateInputWidth: isMobile ? "80%" : "55%"
    }


    //DYNAMIC STYLES INSIDE RENDER METHOD
    const addPrizeButtonStyle = {
        position: "absolute",
        bottom: 15, 
        left:deviceStyle.addPrizeButtonLeft
    }


    //PRIZE ERROR CHECKING
    const prizeSchema = Joi.array().min(1).items({
        id: Joi.number().allow(""),
        prize_name: Joi.string().min(5).max(30),
        prize_description: Joi.string().allow("").max(50),
        prize_quantity: Joi.number().min(1),
        prize_identifier: Joi.number().allow("", null),
        prize_redem_loc: Joi.string().required(),
        prize_redem_style: Joi.string().allow(""),
        //prize_code: Joi.string().allow(""), // Database doesnt have a "prize-code" column yet
        prize_expires: Joi.date().required().greater(minDate).label("Prize Expiry"),
        awarded: Joi.number().allow(""),
        claimed: Joi.number().allow(""),
        status: Joi.string().allow(""),
    });
    





    //PRIZE WINNER SECTION THAT LIKELY DOESNT BELONG IN EDIT QUEST
    const [prizeWinners, setPrizeWinners]  = useState([]);
    const [showTable, setShowTable] = useState("prizes");

    //API Call to get the prize winners for the associated quest/goal. 
    const getPrizeWinners = async () => {        
        const response = await httpService("GET",`${URL_ROOT}/api/get_prize_winners/${goal.id}/`, {})
        setPrizeWinners(response.data)
    }

    //Automatically get the prize winners if the goal is complete. 
    useEffect(()=>{

        if(questCreate) return //Return if create mode

        if (!goal?.id) return //Return if goal not yet loaded

        if(goal?.goal_complete === true){
            getPrizeWinners()}

    }, [goal?.goal_complete])





    //TABLE STUFF THAT CAN LIKELY BE REMOVED SINCE USING CARDS NOW
    function handleDeletePrize(event, row){
        const newData = _.cloneDeep(goal);
        const index = _.indexOf(goal?.prize, row)
        newData.prize.splice(index,1)
        setGoal(newData)
    }

    function handleEditPrize(col,{value, row}){
        console.log("questPrizeLayout", col, value, row)
        const index = _.indexOf(goal.prize, row)
        const newData = _.cloneDeep(goal);
        newData.prize[index][col] = value
        setGoal(newData)
    }

    function handleTableChange(value){
        console.log("handleTableChange", value)
        setShowTable(value)
    }
    

    
    
    async function handleSaveGoal(){

        const goalData = {}
        goalData["id"] = goal?.id 
        goalData["goal_award_style"] = goal?.goal_award_style 
        goalData["goal_goal_amount"] = goal?.goal_goal_amount
        //goalData["goal_raffle_date"] = "" This should be updated if the quest_end is updated. 

        const newPrizes = goal?.prize?.filter(prize => prize.id < 1)
        const deletedPrizes = _.difference(origData?.prize?.map(prize => [prize.id]).flat(2), goal?.prize?.map(prize=>[prize.id]).flat(2))        
        const notNew = goal?.prize?.filter(prize => prize.id > 1)
        const updatedPrizes = notNew?.filter(prize => {
            const origIndex = origData?.prize?.findIndex(item => item.id === prize.id)
            const origPrize = origData?.prize?.[origIndex]
            return !_.isEqual(prize, origPrize)
        })
        
        const data = {
            goal: goalData, 
            newPrizes: newPrizes, 
            deletedPrizes: deletedPrizes, 
            updatedPrizes: updatedPrizes
        }

        //TODO make this its own API call and remove from the general method. 
        const response = await httpService("POST", `${URL_ROOT}/api/update_quest_prizes/`, {data} )
        if(response === "error") return 

        setGoal(response.data)
        setIsEditable(false)
        getPrizeWinners() //This can likely be removed since not handling on this view. 
    }

    useEffect(()=>{
        if(questCreate){ //Wont get passed in a goal object if in quest create mode. 
          return
        }
        setGoal(quest.goal?.[0])
    }, [quest])


function handleAddPrize(){

    const prizeObj = {
        id: parseFloat(Math.random()), //Creates a random ID between <1 and greater than 0. 
        prize_name:"", 
        prize_description:"",
        prize_quantity:"",
        prize_identifier:"",
        prize_redem_loc: "",
        prize_redem_style:"",
        prize_expires:prizeExpire,
        status:"" 
    };
    const newData = _.cloneDeep(goal)
    newData?.prize?.unshift(prizeObj)
    setGoal(newData)
}


function handleDeletePrizeAccordion(prizeID){
    console.log("prizeID Delete", prizeID)
    const goalCopy = _.cloneDeep(goal);
    const index = goalCopy?.prize?.findIndex(prize => prize.id === prizeID)
    goalCopy?.prize?.splice(index,1)
    setGoal(goalCopy)

}

function editPrizeAccordion(e, field, prizeID){
    console.log("editPrizeAccordion", e, field, prizeID)
    const goalCopy = _.cloneDeep(goal);
    const index = goal.prize.findIndex((prize)=> prize.id === prizeID)
    goalCopy.prize[index][field] = e.target.value
    setGoal(goalCopy)
}


function editExpireDateTime(value, prizeID){
    console.log("editExpireDateTime", value, prizeID)
    const goalCopy = _.cloneDeep(goal);
    const index = goal.prize.findIndex((prize)=> prize.id === prizeID)
    goalCopy.prize[index]["prize_expires"] = value
    setGoal(goalCopy)
}

//Makes the options for the goal picker
const makeOptions = (token, index) => {
        return(
            <Option 
                name={index + 1} 
                label={`${index + 1} Locations per entry`} 
                value={index + 1} 
                icon={<LocalActivityRoundedIcon 
                    style={{color:"grey", marginLeft:10 ,padding:2}}/>}/>
        )
    }

function handleGoalAmountChange({value}){
    const newData = _.cloneDeep(goal)
    newData.goal_goal_amount = value;
    console.log("switch newData", newData)
    setGoal(newData)
}


const stuff = {
    name: goal?.goal_goal_amount ?? 0,
    label:`${goal?.goal_goal_amount ?? 0} Locations`,
    icon:<LocalActivityRoundedIcon style={{color:"grey", marginLeft:10 ,padding:2}}/>}

const GoalPickList = () => {
    
    return(
        <Picklist
            id="numGoal"
            style={{width: deviceStyle.minInputWidth}}
            placeholder="Choose Goal"
            disabled={!isEditable}
            onChange={value => handleGoalAmountChange(value)}
            value={stuff}
            label="Locations to Visit"
            hideLabel
            //enableSearch
        >
            {
                questCreate 
                    ? 
                markers?.map((token, index)=> makeOptions(token, index))
                    :
                quest?.tokens?.map((token, index)=> makeOptions(token, index))
            }
        </Picklist>
    )
}

//Resets the expiry date for all the prizes if prizeExpire input is changed. 
useEffect(()=>{
    const newData = {...goal};
    newData?.prize?.map(prize => prize.prize_expires = prizeExpire)
},[prizeExpire])

function handleIsEditable(){
    const copyData = _.cloneDeep(goal);
    setOrigData(copyData)
    setIsEditable(true)
}

function handleCancelEdit() {
    setGoal(origData)
    setIsEditable(false)
}

const EditButton = () => {
    return(
         !isEditable ?
        <Button 
            label={isEditable === false ? "edit" : "save"}
            variant="outline-brand" 
            size= {isMobile && "small"} 
            onClick={handleIsEditable}
            disabled={goal?.goal_complete}
        />
        :
        <>
            <Button 
                label={"save"}
                disabled={isErrors}
                variant="brand"
                size= {isMobile && "small"} 
                onClick={()=>handleSaveGoal()}
                style={{marginRight:10}}
            />
        
            <Button 
                label={"Cancel"}
                size= {isMobile && "small"} 
                variant="outline-brand" 
                onClick={handleCancelEdit}
            />
        </>
    ) 
}


const RaffleDate = (
    questCreate && goal?.goal_award_style === "RAFF" &&
    <Row>
        <ColumnTitle><StyledText>Winners Drawn:</StyledText></ColumnTitle>
        <ColumnData>
            <DateTimePicker
                type="datetime-local"
                value={(goal?.goal_raffle_date)}
                disabled={true}
                style={{width:300}}
            />
            <div style={{color:"grey", margin:10}}>Winners drawn at the end of the quest</div>
        </ColumnData>
    </Row>
);

const AwardDate = (
    
    <DateTimePicker
        type="datetime-local"
        value={(prizeExpire)}
        //readOnly={true}
        //disabled={isEditable}
        disabled={true}
        minDate={minDate}
        onChange={(date)=>{setPrizeExpire(date)}}
        style={{width:deviceStyle.awardDateInputWidth}}
    />
);

console.log("isPrize Errors", isErrors)

useEffect(()=>{
    const valOptions ={abortEarly:false};
    const validate = Joi.validate(goal?.prize , prizeSchema, valOptions)
    console.log("goal errors", validate)

    if(questCreate && validate?.error?.details){setError("quest_goal", true)}
    else if(questCreate){setError("quest_goal", false)}

    setIsErrors(validate?.error?.details);
    
}, [goal, prizeExpire])

const prizeHint = () => {

    switch (goal?.goal_award_style){
        
        case "RAFF":
            return `Pariticpants receive 1 raffle entry for every ${goal?.goal_goal_amount} locations they visit`
        break;
    }
}


const [activePage, setActivePage] = useState(1);
const numPerPage = 4;
const lastItem = activePage * numPerPage; 
const firstItem = lastItem - numPerPage; 
const pages = Math?.ceil(goal?.prize?.length / numPerPage) || 0;


//Status should be inside its own card or container with border. 
    return (
        <Card
            title={<CreateCardHeaderText>Prize Award</CreateCardHeaderText>}
            style={{backgroundColor:"white", width:deviceStyle.containerWidth, margin:20}}
            actions={!questCreate && <EditButton/>}
            footer={ 
                
                <div>
                    <CardInputContainer isMobile={isMobile} title={"Prize Award"}>
                        <PrizePicker2 goal={goal?.goal_award_style} questCreate={questCreate} isMobile={isMobile} isEditable={isEditable}/>
                    </CardInputContainer>

                    {!isMobile && <br/>}

                    <CardInputContainer isMobile={isMobile} title={"Goal"}>
                        <GoalPickList quest={quest}/>
                        {/*<div style={{color:"grey", margin:10, textAlign:"left", fontSize:10}}>{prizeHint()}</div>*/}
                    </CardInputContainer>

                    {!isMobile && <br/>}

                    <CardInputContainer title={"Award Date"} isMobile={isMobile}>
                        {AwardDate}
                        <div style={{color:"deeppink", fontSize:10}}>Winners will be drawn at the end of the quest:</div>
                    </CardInputContainer>

                    {!isMobile && <br/>}

                    {!questCreate && <CardInputContainer title={"Status"} isMobile={isMobile}>
                         <div 
                            style={{
                                display:"flex",
                                justifyContent:"center", 
                                alignItems:"center", 
                                width:deviceStyle.raffleTimerBox, 
                                borderRadius:10, 
                                paddingTop:15, 
                                paddingBottom:15,
                                borderStyle:"solid", 
                                borderWidth:1, 
                                borderColor:"#EEEE"
                            }}>
                            <RaffleTimer 
                                endDate={goal?.goal_raffle_date} 
                                goal_complete = {goal?.goal_complete}
                                endCallback={refreshQuestData}
                            />
                        </div>
                    </CardInputContainer>}

                    {/*!isMobile && 
                    <CardInputContainer>
                        <div>
                            <Button 
                                label={<div><AddIcon></AddIcon>Prize</div>}
                                variant='brand'
                                icon={<AddIcon/>}
                                onClick={handleAddPrize}
                                disabled={!isEditable}
                                />
                        </div>
                    </CardInputContainer>*/}

                    <br/>
                    <PrizeHeading isMobile={isMobile}>   
                            <Button 
                                label={<div><AddIcon></AddIcon>Prize</div>}
                                variant='brand'
                                //icon={<AddIcon/>}
                                size= {isMobile && "small"} 
                                onClick={handleAddPrize}
                                disabled={!isEditable}
                                />
                    </PrizeHeading>
             
                    <br/>
                    
                    {goal?.prize?.length <= 0 && <div style={{color:"red"}}>You need to add at least 1 prize</div>}

                    { isMobile && 
                    <Accordion style={{width:"100%"}}>
                        {goal?.prize?.slice?.(firstItem, lastItem)?.map((prize) => <MyPrizeCard isEditable={isEditable} prize={prize} editPrizeAccordion={editPrizeAccordion} key={prize.id} editExpireDateTime={editExpireDateTime} deletePrize={handleDeletePrizeAccordion}/>)}
                    </Accordion>
                    }

                    {!isMobile &&
                        goal?.prize?.slice?.(firstItem, lastItem)?.map((prize) => <DskPrizeCard isEditable={isEditable} prize={prize} editPrizeAccordion={editPrizeAccordion} key={prize.id} editExpireDateTime={editExpireDateTime} deletePrize={handleDeletePrizeAccordion} onChange={(e)=>editPrizeAccordion(e,"prize_name", prize?.id)}/>)
                    }

                    <Pagination
                        pages={pages}
                        style={{paddingTop:10}}
                        activePage={activePage}
                        onChange={(event, page) => setActivePage(page)}
                    />

                    
                    
                    {/*RaffleDate*/}

                    {/* !questCreate &&
                    <Row>
                        <StyledText>
                            <ButtonGroupPicker
                                //label="Select view type"
                                value={showTable}
                                onChange={(value)=>handleTableChange(value)}
                                name="filter"
                                size="medium"
                                //bottomHelpText="Select one option"
                            >
                                <ButtonOption
                                    label={"Prizes"}
                                    name="prizes"
                                />
                                 <ButtonOption
                                    label={"Winners"}
                                    name="winners"
                                    disabled={!goal?.goal_complete}
                                />
                            </ButtonGroupPicker>
                        </StyledText>
                    </Row>
                    }   

                    { showTable === "prizes" 
                        ? 
                        <PrizeTable goal={goal} isEditable={isEditable} handleEditPrize={handleEditPrize} handleDeletePrize={handleDeletePrize}/>
                        :
                        <WinnerTable prizeWinners={prizeWinners}/>
                    */}

                    {/*CAN DELETE THIS IF NOT USING TABLES ANYMORE*/}
                    <br/><br></br><br/>

                    {/* isMobile &&
                        <div style={addPrizeButtonStyle}>
                            <Button 
                                label={<div><AddIcon></AddIcon>Prize</div>}
                                variant='brand'
                                icon={<AddIcon/>}
                                onClick={handleAddPrize}
                                disabled={!isEditable}
                                />
                        </div>
                    */}

                </div>}
        >
        </Card>
    );
}

export default QuestPrizeLayout2;


function PrizeHeading({children, isMobile}){

    const container = {
        width: "100%", 
        display: "flex",
        justifyContent: isMobile ? "space-between" : "flex-start",
        alignItems:"center",
        padding: 10, 
        //fontSize:30,
        //fontWeight:"bold", 
        borderBottomWidth:1, 
        borderBottomStyle:"solid", 
        borderColor:"#cccccc"

    }

    return( 
        <div style={container}>
            <CreateCardHeaderText>Prizes</CreateCardHeaderText>
            <div style={{position:"relative", left: !isMobile && 50}}>
                {children}
            </div>
        </div>
    )
}



function MyPrizeCard({isEditable, prize, editPrizeAccordion, editExpireDateTime, deletePrize}){

    const isMobile = MediaQuery()
    const deviceStyle = {
        accordionWidth: isMobile ? "100%" : "60%"
    }

    function space(e){
        if (e.key === " ") {
            editPrizeAccordion(e,"prize_name", prize?.id)
            e.preventDefault()
            return
            //e.stopPropagation()
          }
    }


    return(

        <AccordionSection 
            label={MainContainer(prize, isEditable, (e)=>editPrizeAccordion(e,"prize_name", prize?.id), space)}
            style={{width:deviceStyle.accordionWidth, textAlign:"left", position:"relative"}}
            //icon={<FontAwesomeIcon icon={faGift} style={{position: "absolute", top:10, left:15}}/>}
        >

            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", width:"100%"}}>

                <InputContainer title="How many prizes are you giving out?">
                    {/*<MyInput 
                        value={prize?.prize_quantity} 
                        width="25%" 
                        isEditable={isEditable}
                        onChange={(e)=>editPrizeAccordion(e,"prize_quantity", prize?.id)}
                    />*/}

                    <Input 
                        value={prize?.prize_quantity} 
                        style={{width:"25%"}} 
                        type="number"
                        disabled={!isEditable}
                        onChange={(e)=>editPrizeAccordion(e,"prize_quantity", prize?.id)}
                    />
                </InputContainer>
                
                <InputContainer title="Describe the prize">
                    <Textarea 
                        name={"prize_description"}
                        value={prize?.prize_description} 
                        style={{minWidth:"100%"}} 
                        disabled={!isEditable}
                        onChange={(e)=>editPrizeAccordion(e,"prize_description", prize?.id)}
                    />
                </InputContainer>
               
                <InputContainer title="When will the prize expire?">
                    <DateTimePicker 
                        value={new Date(prize?.prize_expires).toLocaleString()}
                        disabled={!isEditable}
                        onChange={(value)=> editExpireDateTime(value, prize?.id)}
                    />
                    {/*<MyInput value={new Date(prize?.prize_expires).toLocaleString()} type="datetime-local"/>*/}
                </InputContainer>

                {/* Code hasnt been added to the database yet. 
                <InputContainer title="Code" >
                    <MyInput 
                        value={prize?.prize_identifier} 
                        isEditable={isEditable}
                        onChange={(e)=>editPrizeAccordion(e,"prize_code", prize?.id)}
                    />
                </InputContainer>
                */}

                <InputContainer title="Where can winners redeem their prize?">
                    <Input 
                        value={prize?.prize_redem_loc} 
                        disabled={!isEditable}
                        style={{width:"100%"}}
                        type="text"
                        onChange={(e)=>editPrizeAccordion(e,"prize_redem_loc", prize?.id)}
                    />
                </InputContainer>
                
                <br/>

                <Button 
                    variant="outline-brand"
                    disabled={!isEditable}
                    onClick={()=> deletePrize(prize.id)}
                >
                    Delete
                </Button>
            
            </div>

        </AccordionSection>
    )

}


function InputContainer({title, children}){

    return(
        <div style={{display:"flex", alignItems:"flex-start", textAlign:"left", flexDirection:"column", margin:5, width:"100%", color:"grey", fontSize: `clamp(1.2vw, 1.6vw, 20)`}}>
            <div>{title}</div>
            {children}
        </div>
    )
}

function MyInput({value, width="100%", type="text", readOnly=false, isEditable, onChange}){

    return(
        <Input 
            value={value} 
            style={{width:width}} 
            variant=""
            disabled={!isEditable}
            type={type}
            readOnly={readOnly}
            onChange={onChange}
        />
    )
}


const MainContainer = (prize, isEditable, onChange, space) => {

    const accordionLabel = {
        display: "flex", 
        width:"100%",
        alignItems:"center",         
    }

    return(
        <div style={accordionLabel}>
            <Input 
                value={prize?.prize_name} 
                variant=""
                placeholder='Enter prize name?'
                type="text"
                readOnly={!isEditable}
                style={{minWidth:"80%"}}
                onChange={onChange}
                onClick={(e)=> e.stopPropagation()}
            />
        </div>
    )
}


function DskPrizeCard({isEditable, prize, editPrizeAccordion, editExpireDateTime, onChange, deletePrize}){

    const container = {
        display:"flex",
        position:"relative",
        flexWrap:"wrap",
        width:"95%", 
        borderRadius: 10, 
        borderWidth: 1, 
        borderStyle: "solid", 
        borderColor: "#DDDDDD", 
        margin: "1%",
    }

    const column = {
        width:"50%", 
        padding: "2%"
    }

    const deleteButtonStyle = {
        position:"absolute",
        top:5, 
        right:5 
    }

    return(
        <div style={container}>

            <div style={column}>

                <InputContainer title="Whats your prize's name">
                    <Input 
                        value={prize?.prize_name} 
                        variant=""
                        placeholder='Enter prize name?'
                        type="text"
                        disabled={!isEditable}
                        style={{minWidth:"80%"}}
                        onChange={onChange}
                        onClick={(e)=> e.stopPropagation()}
                    />
                </InputContainer>               

                <InputContainer title="Describe the prize">
                    <Textarea 
                        name={"prize_description"}
                        value={prize?.prize_description} 
                        style={{minWidth:"100%"}} 
                        disabled={!isEditable}
                        onChange={(e)=>editPrizeAccordion(e,"prize_description", prize?.id)}
                    />
                </InputContainer>

            </div>

            <div style={column}>

                <InputContainer title="How many prizes?">
                    <Input 
                        value={prize?.prize_quantity} 
                        style={{width:"35%"}} 
                        type="number"
                        disabled={!isEditable}
                        onChange={(e)=>editPrizeAccordion(e,"prize_quantity", prize?.id)}
                    />
                </InputContainer>

                <InputContainer title="When will the prize expire?">
                    <DateTimePicker 
                        value={new Date(prize?.prize_expires).toLocaleString()}
                        style={{width:"90%"}}
                        disabled={!isEditable}
                        onChange={(value)=> editExpireDateTime(value, prize?.id)}
                    />
                </InputContainer>

                <InputContainer title="Where is the prize redeemed?">
                    <Input 
                        value={prize?.prize_redem_loc} 
                        disabled={!isEditable}
                        style={{width:"90%"}}
                        type="text"
                        onChange={(e)=>editPrizeAccordion(e,"prize_redem_loc", prize?.id)}
                    />
                </InputContainer>

                <ButtonIcon 
                    variant="outline-brand"
                    style={deleteButtonStyle}
                    disabled={!isEditable}
                    onClick={()=> deletePrize(prize.id)}
                    icon={<FontAwesomeIcon icon={faTrashAlt}/>}
                >
                </ButtonIcon>

            </div>
        </div>
    )
}