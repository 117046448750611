import React, {createContext, useContext, useEffect, useState, useCallback} from 'react';
import { useUserLocContext } from '../../../context/UserLocContext';
import {cloneDeep} from "lodash"
import getWonPrizes from '../api/getWonPrizes';
import { useQuestContext } from './questContext';


//TODO - Consider moving randomPrize Context to the random prize folder
//TODO - Make sure to useCallback() for context function to prevent unecessary rerenders. 

export const WonPrizeContext = createContext()

export function useWonPrizeContext(){
    return useContext(WonPrizeContext)
}

export function WonPrizeContextProvider({children}){
    
    const [wonPrizes, setWonPrizes] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [numClaimablePrizes, setNumClaimablePrizes] = useState(null)

    useEffect(()=>{
        console.log("wonPrizes", wonPrizes)
        if(!wonPrizes) return
        setNumClaimablePrizes(wonPrizes?.
            filter(prize => prize?.claimed == false)?.
            filter(prize => isNotExpired(prize))?.
            length)
    }, [wonPrizes])

    const numWonPrizes = Math.min(wonPrizes?.length, 99) //Deprecated. 
    
    function isNotExpired(prize){
        const now = new Date()
        const expires = new Date(prize?.prize?.expires)
        return now < expires
    }

    console.log("wonPrizesObj", wonPrizes)

    //const numClaimablePrizes = 
    //    wonPrizes?.
    //        filter(prize => prize?.claimed == false)?.
    //        filter(prize => isNotExpired(prize))?.
    //        length

    //TODO: Make sure to thuroughly test this
    async function handleGetPrizes(){
        if(!wonPrizes) setIsLoading(true)

        const response = await getWonPrizes()
        if(response.status < 200){setIsLoading(false); return}
        if(response.status >= 300){setIsLoading(false); return}

        if(!wonPrizes) setIsLoading(false)
        setWonPrizes(response.data)
    }

    function refreshWonPrizes(){
        setRefresh(!refresh)
    }

    useEffect(()=>{
        handleGetPrizes()
    }, [refresh])

    function updatePrizeClaimed(id, isClaimed){
        const copy = [...wonPrizes]
        const index = copy?.findIndex(prize => prize.id == id)
        copy[index].claimed = isClaimed    
        console.log("updatePrizeClaimed", id, isClaimed, copy)    
        setWonPrizes(copy)
    }

    function getPrize(id){
        if(!wonPrizes) return
        const copy = [...wonPrizes]
        const index = copy?.findIndex(prize => prize?.id == id)
        return copy[index]
    }

    function clearWonPrizeContext(){
        setWonPrizes([])
        setNumClaimablePrizes(null)
    }

    /*Calls the API to refresh the data. 
    const refreshRandomPrizes = ()=> setRefresh(!refresh)

    //Deletes the random prize from the data set. 
    const deleteRandomPrize = useCallback(async(prizeID) => {
        
        if(!randomPrizes) return

        const copy = cloneDeep(randomPrizes)
        const index = copy.findIndex(randPrize => randPrize.id == prizeID)
        copy.splice(index,1)
        
        setRandomPrizes(copy)

    }, [randomPrizes])


    const {userLocation} = useUserLocContext()


    useEffect(()=>{
        handleGetRandomPrizes()
    }, [refresh])
    
    async function handleGetRandomPrizes(){
        const result = await getRandomPrizes()
        setRandomPrizes(result.data)
    }   
    */

    return(
        <WonPrizeContext.Provider 
            value={{wonPrizes, isLoading, refreshWonPrizes, numClaimablePrizes, updatePrizeClaimed, getPrize , clearWonPrizeContext}}
        >
            {children}
        </WonPrizeContext.Provider>
    )

} 





