//This function should be moved to a utils page. 

export function getPrizeStatus(claimed, expiresRaw){

    const now = new Date()
    const expires = new Date(expiresRaw)

    if(claimed == true) return "claimed"
    else if (now > expires) return "expired"
    else return "Not claimed"
}