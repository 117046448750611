import React, {useState, useCallback, useEffect, useRef} from 'react';
import { Card, Button } from 'react-rainbow-components';
import styled from 'styled-components';
import _, { map } from 'lodash';
import  Joi from 'joi-browser';
import MediaQuery from '../common/MediaQuery';
import httpService from '../../API/Client';
import MapBoxQuestCreatev2 from '../MapBoxQuestCreatev2';
import URL_ROOT from '../URL_ROOT';
import CreateCardHeaderText from './CreateCardHeaderText';


//This should be moved to its own folder and file at some point. 
const locationSchema = Joi.array().min(1).items({
    id: Joi.number().allow(""),
    token_created: Joi.date().allow(""),
    token_updated: Joi.date().allow(""),
    count: Joi.number().allow(""),
    id: Joi.number().allow(""),
    token_name: Joi.string().required().min(5),
    token_description: Joi.string().allow(""),
    token_radius: Joi.number().required().min(10).max(5000),
    token_lat: Joi.number().required().min(-90).max(90),
    token_long: Joi.number().required().min(-180).max(180)
});


function CreateTokenCard({markers, setMarkers, questDetails, refreshQuestData, create=false, setParentError}) {
    //isEditable state can be handled locally...doesnt need to be lifted to the parent component. 

    const [isEditableLocs, setIsEditableLocs] = useState( create ? true : false);
    const [selectedMarker, setSelectedMarker] = useState({});
    const [origData, setOrigData] = useState([]);
    const [isErrors, setIsErrors] =useState([]);

    const isAdded = useRef(false)

    /*useEffect(()=>{

        if(!isAdded.current) return

        console.log("isAdded", markers?.length)
        
        isAdded.current = false
        selectMarker(markers?.length - 1)

    }, [markers])
    */

    console.log("isErrors", isErrors)

    const mapView = useRef({
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 8
    })

    function setMapView(lat, long, zoom){
        mapView.current.latitude=lat
        mapView.current.longitude=long
        mapView.current.zoom=zoom
    }

    const isMobile = MediaQuery();

    const deviceStyle = {
        containerWidth: isMobile ? "90%" : "70vw", 
        mapWidth: isMobile ? "100%" : "100%", 
        //mapWidth: isMobile ? "80vw" : "68vw", 
        containerAlignItems: isMobile ? "center" : "flex-start"
    }

    function addMarker(viewport){ //viewport will have to come from the map

        const newMarker = {
            id:Math.random(), //Necessary for the table to track unique
            token_name:"",
            token_description:"", 
            token_radius:500,
            token_lat:viewport.latitude, 
            token_long:viewport.longitude, 
        }

        const copy = [newMarker, ...markers];
        setMarkers(copy)
        setSelectedMarker(newMarker)
    }

    console.log("selectedMarker", selectedMarker)

    const handleOnDragUpdate = useCallback((event, index) => {
        console.log("handleOnDragUpdate")

        const copy = [...markers];
        copy[index].token_long = event.lngLat[0]
        copy[index].token_lat = event.lngLat[1]
        setMarkers(copy);
    }, [markers]);

    function handleMarkerUpdate(event){

        const index = _.findIndex(markers, selectedMarker)
        const copy = [...markers];
        copy[index][event.target.name] = event.target.value 
        setMarkers(copy)
    }
    
    function handleMarkerRadiusUpdate(value){
        const index = _.findIndex(markers, selectedMarker)
        const valueSlide = value.target.value

        if( valueSlide <= 1000 ){
            const copy = [...markers];
            copy[index].token_radius = valueSlide
            setMarkers(copy)
        } return
    }

    function handleDeleteMarker(marker){

        const index = _.findIndex(markers, selectedMarker)
        const copy = [...markers];
        copy.splice(index, 1)
        setMarkers(copy)
        setSelectedMarker({})
    }

    const selectMarker = useCallback ((index) => {

        const selected = markers?.[index]
        setSelectedMarker(selected)
        }, [selectedMarker, markers]);


    function clearSelected (){
        setSelectedMarker({})
    }

    const StyledCardTitle = styled.div`
        font-size:30px;
        font-weight:bold;
    `;

    async function handleSaveLocs(){

        const createMarkers = markers.filter(marker => marker.id < 1 )
    
        const deleteMarkers2 = _.difference(origData.map(marker => {return[marker.id]}).flat(), markers.map(marker => {return[marker.id]}).flat())
    
        const updateMarkers = markers.filter(marker1 => marker1.id > 1).filter(marker2 => {
            const index = origData.findIndex(item => item.id === marker2.id)
            const origObj = origData[index]
            return !_.isEqual(marker2, origObj)
        })

        const data = {
            questId:questDetails.id,
            create:createMarkers, 
            update:updateMarkers,
            delete:deleteMarkers2
        };
        
        const response = await httpService("POST", `${URL_ROOT}/api/update_quest_locations/`, {data})

        if (response === "error") return
       
        setIsEditableLocs(false)
        refreshQuestData()
    }
    
    function handleCancelLocs(){
        setMarkers([...origData])
        setIsEditableLocs(!isEditableLocs)
        clearSelected()
    }

    function handleSetEditableLocs(){
        console.log("handleSetEditableLocs markers", markers)

        const newArray = markers.map(a => ({...a}));
        //const newArray2 = _.cloneDeep(markers) 
        console.log("handleSetEditableLocs newArray", newArray)
        setOrigData([...newArray])        
        setIsEditableLocs(!isEditableLocs)
    }

    const EditButton = () => { //This should be moved to its own component since its used by several functional components. 
        console.log("EditButton Render")

        //Worked before adding useMemo....any issues may be the result of it. 
        return(

            !isEditableLocs ?
            <Button 
                label={isEditableLocs === false ? "edit" : "save"}
                variant="outline-brand" 
                size={isMobile && "small"}
                onClick={()=>handleSetEditableLocs()}
            />
            :
            <>
                <Button 
                    label={"save"}
                    disabled={isErrors}
                    variant="brand" 
                    size={isMobile && "small"}
                    onClick={handleSaveLocs}
                    style={{marginLeft:10, marginRight:10}}
                />
        
                <Button 
                    label={"Cancel"}
                    variant="outline-brand" 
                    size={isMobile && "small"}
                    onClick={handleCancelLocs}
                />
            </>
        ) 
    }

    useEffect(()=>{
        localValidate()
    }, [markers])
    
    function localValidate(){
        const valOptions ={abortEarly:false};
        const validate = Joi.validate(markers, locationSchema, valOptions)
        const errors = validate?.error?.details;
        handleErrors(errors)
    }

    //sets local and parent errors
    function handleErrors(errors){
        console.log("errors new", errors)
        create ? setParentError("quest_markers", errors?.length > 0) : setIsErrors(errors)
    }

    return(
        <Card
            style={{width:deviceStyle.containerWidth, height:"80vh", backgroundColor:"white", margin:20}}
            title={
                <div>
                    <CreateCardHeaderText>Locations</CreateCardHeaderText>
                </div>
                }
            actions={
                !create && 
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                        <EditButton/>
                    </div>
                }
            footer={
                <MapBoxQuestCreatev2 //Dont think this need to be an import...could define inside this functional component
                    width={deviceStyle.mapWidth}
                    markers={markers}
                    isEditableLocs={isEditableLocs}
                    setMapView={setMapView}
                    mapView={mapView}
                    addMarker={addMarker} 
                    onDragUpdate={handleOnDragUpdate} 
                    updateMarker={handleMarkerUpdate} 
                    updateMarkerRadius={handleMarkerRadiusUpdate}
                    deleteMarker={handleDeleteMarker} 
                    selectMarker={selectMarker}
                    selectedMarker={selectedMarker}
                    clearSelected={clearSelected}
                    questImage={questDetails.quest_image}
                />
            }
        >
        </Card>
    )


}

export default CreateTokenCard;