import React, { useCallback } from 'react';
import {Marker} from "react-map-gl";
import {ReactComponent as MapMarkerSVG3} from "../Images/MapMarkerSVG3.svg";
import styled, {keyframes} from "styled-components"; 
import { useQuestContext } from '../context/QuestContext';
import MyText from './MyText';


const grow = keyframes`
    from   { transform: scale(0);}
    to { transform: scale(1);}
`;


const MarkerPopup = styled.div`
    padding:2px;
    text-align:center; 
    background : white; 
    box-shadow:2px 3px 5px grey;
    width: ${props => `${props.width}px`};
    border-radius: ${props => `${props.lines*5}px`};
    position: absolute; 
    top: ${props => props.lines === 1 ? "-40px" : "-60px"};
    right:${props => `${-props.width/2}px`}; 
    transform: scale(1.0);
    transition: all 0.4s ease;
`;


const AnimatedDiv = styled.div`
    display: flex;
    animation-name: ${grow};
    animation-duration: 0.35s;
    animation-timing-function: ease-in-out;
`;


const SIZE = 30; 

function PlayMapMarkerV4Selected({ tokens, handleTap, showLabel=true}) {
    
    console.log("PlayMapMarkerV4Selected rerendered")

    const {selectedQuest} = useQuestContext();
    const isSingleLoc = selectedQuest?.tokens?.length === 1; 

    //TODO handleCollectHelper move to PlayLayout...delete after thurough testing. 
    /*const handleCollectHelper = useCallback( async (tokenData) => {
        collectMultiToken(authObj, collectToken, tokens, revertCollectToken, updateRaffleEntries, mapRef, tokenData)
    }, [tokens, selectedQuest])*/


    function MarkerPopUp({token, isCollected}){

        const leng = token?.token_name.length;
        let width;
        let lines;
        
        if(leng <= 18){width = leng * 7.2; lines = 1}
        else{width = 100; lines = 2 }
        
        console.log("length", leng, lines)

        return(
            /*<AnimatedDiv>*/ // Removed the AnimatedDiv; caused flicker on token collect
                <MarkerPopup width={width} lines={lines} isCollected={isCollected}>
                    <MyText maxLines={2} fontSize={10} wrap={true} color="black">{token?.token_name}</MyText>
                </MarkerPopup>
            /*</AnimatedDiv>*/
        )
    }

    return (

        tokens?.map( token => {

            const isSelected = token?.quest_id === selectedQuest?.id;
            const isCollected = token?.token_collected_count > 0 ? true : false;

            const tokenData = {
                token_id: token?.id, 
                quest_id: token?.quest_id,
                token_radius: token?.token_radius,
                token_lat: token?.token_lat,
                token_long: token?.token_long, 
                token_collected_count: token?.token_collected_count
            }

            return (
                
                <Marker
                    latitude={token.token_lat}
                    longitude={token.token_long}
                    key={token.token_id}
                    onClick={() => handleTap(tokenData, isSingleLoc)}
                    //onClick={() => handleCollectHelper(tokenData)}  //See above TODO
                >
                    <AnimatedDiv >
                        
                        <div style={{height:0, width:0}}>
                            
                            <MapMarkerSVG3 viewBox='0 0 80 80' height={SIZE} fill={isCollected ? "grey" : "deeppink"} style={{cursor:"pointer", transform: `translate(${-SIZE / 2}px,${-SIZE}px) ${isSelected ? "scale(1)" : "scale(0.5)"}`, position:"relative", top:15, left:6 }}>
                            </MapMarkerSVG3>
                            
                        </div>
                    
                        {showLabel && <MarkerPopUp token={token} isCollected={isCollected}/>}

                    </AnimatedDiv>

                            
                </Marker>
            )
        })
        
    );
}

export default React.memo(PlayMapMarkerV4Selected);

