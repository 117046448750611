import React, {useState} from 'react';
import styled from "styled-components";
import useIsTablet from '../../../utils/useIsTablet';
import CloseButton from '../common/CloseButton';
import HamburgerButton from '../common/HamburgerButton';

const Drawer = styled.div`
    height:99vh;
    width: ${props => props.width};
    background:#F9F9F9;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 2px 3px 5px grey;
    position: absolute;
    top:0;
    left:-30vw;
    left: ${props => "-"+ props.width}
    transform: ${props => props.isOpen ? `translateX(${props.width})` : `translateX(0vw)` };
    transition: all ease 0.4s;
    z-index:1001;
    will-change: transform;
    overflow:auto;
    padding-bottom: 60px; 
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;               
      }   
`;

function SideDrawerQuestList({children}) {

    const [isOpen, setIsOpen] = useState(false);
    const isTablet = useIsTablet()
    const drawerWidth = isTablet ? "50vw" : "30vw"
    console.log("sideDrawerQuest Rendered", isTablet)

    return (
        <div >
            {<Drawer isOpen={isOpen} width={drawerWidth}>
                <CloseButton close={()=>setIsOpen(!isOpen)}/>
                {children}
            </Drawer>}
            
            <HamburgerButton click={()=>setIsOpen(!isOpen)}/>

        </div>
    );
}

export default React.memo(SideDrawerQuestList);
