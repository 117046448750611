import axios from "axios";
import { BASE_URL} from "./GeneralVars";


//TODO Delete after testing creation flow
//const BASE_URL_PRODUCTION = "https://sidequestapiv4-beta.herokuapp.com"
//const BASE_URL_LOCAL = "https://bab98b44a3b5.ngrok.io"

//TODO break the switch into separate API files


export default async function httpService(method, url, data={}, on401){

    console.log("on401", on401)

    const token = (localStorage.getItem("auth_token"))
    console.log("Auth_token", token)

    const client= axios.create({
        baseURL: BASE_URL,
        headers: {
            //"Authorization": "Token 87e74b1af23ed3f214ed38bc86c58dc814d9f836"
            "Authorization": token
         },
        timeout: 15000
    });

    //TODO Check to make sure this doesnt cause problems with quest create auth
    client.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('auth_token');
            if (token) {
                config.headers.Authorization = token;
                console.log("Auth intercepted", token)
            } else {

          }
          return config;
        },
        (error) => Promise.reject(error),
      );

    try{
        const response = await selectMethod();
        console.log("response", response.data )
        //alert(response.data.quests)
        return response
        
    } catch(error) {

        if (error.response){
            console.log(error.request)

            if(error.response.status === 403){
                alert("Email has already been registered")
                return "error"
            }

            if(error.response.status === 401){
                alert("Invalid login credentials")
                on401?.()
                return"error"
            }

            alert("Unfortunately there is a problem with your request.  Please try again")
            return "error"
        } 
        else if(error.request){
            alert("Sorry! There is a problem with the server. Please try again later")
            console.log(error.request)
            return "error"
        }
        else{
            alert("an unexpected error has occured")
            return "error"
        }
    }

    async function selectMethod() {
        switch (method){

            case "GET":
               return await client.get(url)
            break;

            case "POST":
                return await client.post(url, data)
            break;

            case "DELETE":
                return await client.delete(url, data)
            break;

            case "PUT":
                return await client.put(url, data)
            break;
        } 
    }
}

