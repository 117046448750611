import React from 'react';
import SubHeaderText from '../../common/SubHeaderText.js';
import HeadingText from '../../HeadingText';
import MyText from '../../MyText.js';

function PrizeListItem(props) {

    const innerContainer = {
        backgroundColor:"white", 
        borderRadius:15, 
        padding:5, 
        boxShadow:"3px 5px 3px #EEEEEE", 
        height:80, 
        margin:8,
        width:props.listType !== "vertical" ? 230 : "90%", 
        display:"flex", 
        alignItems:"center", 
        borderWidth:1, 
        borderStyle:"solid", 
        borderColor:"#EEEEEE"
    }

    const quantityContainer = {
        height:50, 
        width:50, 
        borderRadius:12, 
        margin:10, 
        backgroundColor:"#EEEEEE", 
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center", 
        flexDirection:"column"
    }


    return (

        //<div style={container}>
            
            <div style={innerContainer}>
                
                <div style={quantityContainer}>
                    <MyText fontWeight={"bolder"} fontSize={20} color={"#6600FF"}>{props.quantity}</ MyText>
                    
                    <div style={{fontSize:10, color:"grey"}}>
                        available
                    </div>

                </div>

                <div style={{display:"flex", width:"65%"}}> {/* The 65% prevents the quantity cards from squishing*/}
                    <MyText maxLines={2} color="#5f5f5f" align="flex-start" fontSize={14}>
                        {props.name}  
                    </MyText>
                </div>

                </div>

        //</div>
    );
}

export default PrizeListItem;