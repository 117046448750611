import React from 'react';
import styled, {keyframes} from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';


const pulse = keyframes`

0% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(255, 20, 147, 0.7);
}

70% {
  transform: scale(1);
  box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
}

100% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
`;

const Icon = styled.div`
  display: inline-block;
  border-radius: 100px;
  animation: ${pulse} 2s linear infinite;
  padding:10px;
  font-size: 1.2rem;
  box-shadow: 0 0 0 0 rgba(255, 20, 147, 1);
	transform: scale(1);
`;

function RaffleLoadingIcon(props) {

    return (
        <div style={container}>
          <div style={innerContainer}>
            <Icon>
                <FontAwesomeIcon icon={faTicketAlt} style={{color:"deeppink", fontSize:40}}/>
            </Icon>
          </div>
            <div style={textStyle}>Checking results</div>
        </div>
    );
}

export default RaffleLoadingIcon;

const innerContainer = {
  display:"flex", 
  borderRadius:100, 
  borderColor:"deeppink", 
  justifyContent:"center", 
  alignItems:"center", 
  borderWidth:4, 
  borderStyle:"solid", 
  backgroundColor:"white"
}

const container = {
  display:"flex",
  height:100, 
  width: 100,
  justifyContent:"center", 
  alignItems:"center", 
  flexDirection:"column"
}

const textStyle = {
  fontWeight:"bolder", 
  fontSize:14, 
  textAlign:"center", 
  color:"deeppink"
}