import React from 'react';

function TrafficHintText({isUserInside, numEntrees, isTrafficGoalMet, isAlreadyWon}) {

    const textStyle = {fontSize:10}
    
    if (numEntrees > 0 && !isTrafficGoalMet) return <span style={textStyle}>Can only unlock once</span>
    else if(!isUserInside) return <span style={textStyle}>Get inside quest area to remove lock</span>
    else if(isAlreadyWon) return <span style={textStyle}>You can only win once</span>
    else return <></>
    
}

export default TrafficHintText;