import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter} from "react-router-dom"; //Browser router passes history object through the DOM tree
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.css";
import App from './App';
import AuthContextProvider from './context/AuthContext';
import { QuestContextProvider } from './context/QuestContext';
import HttpsRedirect from 'react-https-redirect';//Switch to server side redirect in the future
import ReactGA from "react-ga";
import { QuestContextProvider as QuestContextProviderNew } from './pages/play/context/questContext';


ReactGA.initialize('UA-233905035-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <BrowserRouter> 
    <React.StrictMode>
      <AuthContextProvider>
        {/*<QuestContextProvider>*/}
          {/*<QuestContextProviderNew>*/}
            <HttpsRedirect>
              <App/>
            </HttpsRedirect>
          {/*</QuestContextProviderNew>*/}
        {/*</QuestContextProvider>*/}
      </AuthContextProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
