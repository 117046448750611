import React, {createContext, useContext, useEffect, useState, useRef} from 'react';
import useWatchLocation from '../location/useWatchLocation';
import getLoc from "../location/GetUserLocationState"
import { useNavigate } from 'react-router-dom';
import {isPointWithinRadius} from "geolib"


//The user location ref data.....although this may not be needed. 
//TODO: Add an state to check if accuracy is met and if watch location is functioning. 
//TODO: Can have the "isInside" check in this context and pass it to components for use 
//NOTE: The map has its own location call...so this isnt need for the user location on the map. 


const MIN_GPS_ACCURACY = 3500

export const UserLocContext = createContext()

export function useUserLocContext(){
    return useContext(UserLocContext)
}

export function UserLocContextProvider({children}) {

    const [isAccurate, setIsAccurate] = useState(false) //This could render the error object
    const isAccurateRef = useRef(false)
    const [isError, setIsError] = useState(false)
    const [locationPermission, setLocationPermission] = useState(null)
    const [initialLocation, setInitialLocation] = useState(false) //Sets when the first location has been recieved
    
    const [isLocationError, setIsLocationError] = useState(null)
    const isLocationErrorRef = useRef(null)
    
    //const {location:{timestamp, coords}, error} = useWatchLocation() //This is the first time location is called
    const timestamp = new Date() //This can likely be removed
    const coords = 0 //This can likely be removed.
    const navigate = useNavigate()

    //Need the watch and get methods to both access this state to update if issues
    const [userLocation, setLocation] = useState({
        coordinates: null, 
        isAccurate: null, 
        isError: null, 
        errorCode: null
    })

    function updateState(key, value){
        const copy = {...userLocation}
        copy[key] = value 
        setLocation(copy)
    }

    async function getLocation(){
        const loc = await getLoc(updateState) 
        if(loc?.error == true) updateState("isError", true)
        return loc
    }

    function isUserInside(coords, radius){
        
        if(!userLocation.coordinates) return false

        const isInside =  isPointWithinRadius(
            {latitude: userLocation?.coordinates?.latitude, longitude: userLocation?.coordinates?.longitude},
            coords,
            radius
        )
        return isInside
    }

    const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 10000
    };

    function onPosition(position){
        const copy = {...userLocation}
        copy.rawData = position.coords
        copy.simple = {latitude:position?.coords?.latitude, longitude:position?.coords?.longitude}
        copy.coordinates = position.coords
        copy.isError = false
        copy.isAccurate = position?.coords?.accuracy < MIN_GPS_ACCURACY || false
        setLocation(copy)
        setInitialLocation(true)
    }

    function onError(error){
        const copy = {...userLocation}
        copy.isError = true
        copy.errorCode = error
        copy.isAccurate = false
        console.log("onError", copy)
        setLocation(copy)
        setInitialLocation(true)
    }

    async function requestLocationPermission(){
        //TODO: This is a bit hacky and should be improved
        
        async function success(pos){
            //alert("success"+ pos.coords.latitude + permission?.state)
            const permission = await navigator.permissions.query({name:"geolocation"})
            if(permission?.state === "prompt") setLocationPermission("granted")
            console.log("coords" + pos?.coords?.latitude)
        }
        function error(err){alert("there is an error" + err.message)}
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        }
        navigator.geolocation.getCurrentPosition(success, error, options)
        //navigator.geolocation.watchPosition(success, error, options)
    }

    //Checks the location permission.  Refreshes if there a change in the permission.
    useEffect(()=>{

        (async () => {
            const permission = await navigator.permissions.query({name:"geolocation"})
            //const browserCompatible = navigator.geolocation
            //navigator.permissions.query({name:"geolocation"})
                //.then(result=> result.addEventListener("change", navigate(0)))
            //if(!browserCompatible) setLocationPermission("not_supported")
            if(permission.state == "pending") setLocationPermission("pending") 
            else if(permission.state == "granted") setLocationPermission("granted")
            else if(permission.state == "denied") setLocationPermission("denied")
            permission.addEventListener("change", ()=>navigate(0)) //Refresh page when permission changes..except on iOS since it doesnt change permission
            //PermissionStatus.addEventListener("change", ()=>navigate(0))  // Tested to fix iOS
        })();

    }, [])

    useEffect(()=>{
    
        if(locationPermission != "granted") return
        else if(locationPermission == "incompatible browser") return

        const geoLoc = navigator.geolocation
        if(!geoLoc){
            updateState("isError", true)
            return
        } 

        const watcher = geoLoc.watchPosition(onPosition, onError, options)
        return () => geoLoc.clearWatch(watcher)

    }, [locationPermission])
    

    return (
        <UserLocContext.Provider value={{timestamp, coords, isAccurate, isAccurateRef, isError, getLocation, userLocation, isUserInside, locationPermission, requestLocationPermission, initialLocation}}>
            {children}
        </UserLocContext.Provider>
    );
}



export default React.memo(UserLocContextProvider); //Recently memoized, but havent tested. 