import React, {useEffect, useState} from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import styled from "styled-components";
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css'; //Required to style the bottomsheet
import QuestDetailsNavigator from './QuestDetailsNavigator';
import { useQuestContext } from '../../../context/QuestContext';
import BottSheetQuestHeader from './BottSheetQuestHeader';
//import "../../../Drawer.css"


function BottSheetDetailsV2() {

    console.log("Bottomsheet rendered")
    const {selectedQuest} = useQuestContext()

    const [open, setOpen] = useState(true);

    function closeDrawer(){
        setOpen(false)
    }

    useEffect(()=>{
        if(selectedQuest != null && Object.keys(selectedQuest).length > 0){
            setOpen(true)
        } else{setOpen(false)}
    }, [selectedQuest])

    const SwipeDrawer = styled(SwipeableDrawer)`
        .MuiBackdrop-root {
            background-color: lightgreen;
            width: 10px;
            height:0px;
            disable-enforce-focus=true;
        }
    `;
    
    const BottomSheet2 = styled(SwipeableDrawer)`
        :root {
            --rsbs-bg: deeppink;
        }
    `;

    const Container = styled.div`
        transform: ${props => props.isOpen ? `translateY(30vw)` : `translateY(0vw)` };
        transition: all ease 0.4s;
    `;

    return (
            <div style={{zIndex:100}}> {/*ensure the details sheet renders on top of the list sheet*/}
                 <QuestDetailsNavigator closeDrawer={closeDrawer}/>
            </div>            
    );
}

export default BottSheetDetailsV2 = React.memo(BottSheetDetailsV2);