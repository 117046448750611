import React from 'react';


//This is "rule" not "how to play"
function GeneralRules(props) {

    function RuleItem({description}){

        const container = {
            display:"flex",
            alignItems:"center", 
            width:"80%", 
            borderRadius:15, 
            borderWidth:1, 
            borderColor:"grey", 
            borderStyle:"solid", 
            margin:"1.5%", 
            padding:"1%", 
            fontSize:12
            //backgroundColor:"dodgerblue", 
        }

        return (
            <div style={container}>
                <div style={{height:5, width:5, borderRadius:10, backgroundColor:"deeppink", margin:"2.5%"}}/>
                {description}
            </div>
        )
    }

    return (
        <div style={container}>
            <RuleItem description={"No purchase necessary"}/>
            <RuleItem description={"Contest is open to everyone"} />
            <RuleItem description={"(1) account per person/device"}/>
            <RuleItem description={"(1) quest entree per person/device"}/>
            <RuleItem description={"Use of VPN's is prohibited"}/>
            <RuleItem description={"Location 'spoofing' is prohibited"}/>

            <span style={{textAlign:"center", fontSize:12, padding:"1%"}}>
                **Users suspected of cheating will not be eligible to win prizes and will not be notified.
            </span>

        </div>
    );
}

export default GeneralRules;

const container = {
    display:"flex", 
    marginTop:50,
    flexDirection:"column",
    alignItems: "center",
    width:"100%",
}
