import React from 'react';
import { Popup } from 'react-map-gl';


export function PolyMarkerPopup({popupInfo, setPopupInfo}){

    const style = {
        borderRadius:30, 
    } 

    return (

        <Popup
            anchor="top" 
            latitude={popupInfo.props.latitude}
            longitude={popupInfo.props.longitude}
            style={style}
            onClose={()=>setPopupInfo(null)}
        >
            <div>Get inside the quest area</div>
        </Popup>
    )
}