import React from 'react';
import styled from 'styled-components';
import Phone3 from "../Images/Phone3.png";
import DogIcon from "../Images/DogIcon.png";
import CupMarker from "../Images/CupMarker.png";
import Tooltip from '@mui/material/Tooltip';
import Lottie from './Lottie';
import RadarPink from "../Images/RadarPink.json";


function PhoneIcon(props) {

    const Logo = styled.img`
    position: absolute;
    width: 30px;
    margin: 15px;
    transition: all .2s ease-in-out
    &:hover {
        transform: scale(0.8);
    }
`;

    return (
        <>
            <img 
                src={Phone3}
                alt="new"
                style={{height:"100%", width:"100%", objectFit:"contain"}}
            />
            <Tooltip title="Pet Rescue $1 donated for every dog park visited"  arrow={true}>
                <Logo src={DogIcon} style={{top:80, left:50}}/>
            </Tooltip>

            <Tooltip title="Pet Rescue $1 donated for every dog park visited" arrow={true}>
                <Logo src={DogIcon} style={{top:120, right:20}}/>
            </Tooltip>

            <Tooltip title="Pet Rescue $1 donated for every dog park visited"  arrow={true}>
                <Logo src={DogIcon} style={{top:250, left:70}}/>
            </Tooltip>

            <Tooltip title="Come in for a chance to win a free coffee" arrow={true}>
                <Logo src={CupMarker} style={{top:180, right:55}}/>
            </Tooltip>
            <div style={{height:60, width:60, position:"absolute", bottom:70, right:20}}>
                <Lottie animation={RadarPink} divID="RadarPink"/>  
            </div>
        </>
    );
}

export default PhoneIcon;