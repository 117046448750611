import registerUser from "../API/RegisterUser";
import signinUser from "../API/SigninUser";
//Consider making local storage a bit more specific by adding "sideQuest"


export async function handleSignup(authData){

    const response = await registerUser(authData) 
    console.log("handleSignup", response.data)
    
    if(response.status !== "ok") return response //returns error code to the calling function

    localStorage.setItem("auth_token", response?.data?.auth_token)
    localStorage.setItem("user_name", response?.data?.user_name)
    localStorage.setItem("is_staff", response?.data?.is_staff)

    return response
}

export async function handleSignin(authData){

    const response = await signinUser(authData)
    console.log("handleSignin", response.data)
    
    if(response.status !== "ok") return response

    localStorage.setItem("auth_token", response?.data?.auth_token)
    localStorage.setItem("is_staff", response?.data?.is_staff)
    localStorage.setItem("user_name", response?.data?.user_name)

    return
}

export function handleLogout(){
    
    localStorage.removeItem("auth_token")
    localStorage.removeItem("is_staff")
    localStorage.removeItem("user_name")

    return
}
