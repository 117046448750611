import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import sideQuestLogo from '../../../../../../Images/MapDefault.png';
import CountDownTimer from '../../../../../common/components/CountDownTimer';
import ProgressBar from '../../../../components/common/ProgressBar';
import RechargeProgressBar from '../../details/RechargeProgressBar';
import { faMapMarkerAlt, faBolt, faKey } from '@fortawesome/free-solid-svg-icons';


function QuestMedallion({onClick, isSelected, quest}) {
    
    function ProgressBarPicker(){
        if(quest?.goal?.[0]?.traffic > 0 ) return <TrafficProgressBar/>
        else if(quest?.goal?.[0]?.recharge_time > 0 ) return <RechargeProgress/>
        else return <GeneralProgressBar/>
    }
    
    function RechargeProgress(){
        const now = new Date()
        //const latest = new Date(quest?.goal?.[0]?.latest_entrant?.date)
        const latest = new Date(quest?.goal?.[0]?.latest_entrant) //Removed ?.date to accomodate new api payload model
        const ellapsed = Math.floor((now - latest)/60000)
        const requiredTime = quest?.goal?.[0]?.recharge_time
        const percentage = Math.min((ellapsed/requiredTime) * 100, 100) 

        console.log("medallion", latest, ellapsed, requiredTime, percentage)

        return (
            <div style={{display:"flex", justifyContent:"center", margin:0, alignItems:"center"}}>
                <span style={{fontSize:9, color:"dodgerblue", fontWeight:"bolder"}}>{`${percentage}%`}</span>
                <FontAwesomeIcon icon={faBolt} style={{fontSize:8, color:"deeppink", marginLeft:2}}/>
            </div>
        )
    }

    function TrafficProgressBar(){
        //Could consder changing this to a number
        return (
            <div style={{display:"flex", justifyContent:"center", margin:3, alignItems:"center"}}>
                <ProgressBar 
                    current={quest?.goal?.[0]?.traffic_count} 
                    max={quest?.goal?.[0]?.traffic} 
                    text={false} 
                    thickness={0.25}
                />
                {/*<FontAwesomeIcon icon={faKey} style={{fontSize:8, color:"deeppink", marginLeft:2}}/>*/}
            </div>
        )
    }

    function GeneralProgressBar(){
        return(
            <div style={{display:"flex", justifyContent:"center", margin:1}}>
                <FontAwesomeIcon icon={faMapMarkerAlt} style={{fontSize:8, color:"deeppink", marginLeft:2}}/>
            </div>
        )
    }

    return (
        <div style={container} onClick={()=>onClick(quest?.id)}>
            
            <div style={subcontainer}>

                <div style={{...medallionStyle, backgroundColor: isSelected ? "deeppink" : "#EEEEEE"}}>
                    <img style={imageStyle} src={quest?.image ? quest?.image : sideQuestLogo}/>
                </div>

                {<ProgressBarPicker/>}
            
            </div>

            <div style={countDownStyle}>
                <CountDownTimer 
                    endDate={quest?.end} 
                    subTitle={false} 
                    raffleStatus={false} 
                    scale={0.45} 
                    style={"chip"} 
                    colour={"white"}/>
            </div>
        </div>
    );
}

const container = {
    position: "relative",
    display:"flex", 
    flexDirection:"column",
    justifyContent:"center", 
    alignItems:"center", 
    marginTop:15
}

const subcontainer = {
    display: "flex", 
    flexDirection: "column", 
    backgroundColor:"white", 
    boxShadow: "2px 3px 5px grey", 
    fontSize:10, 
    borderRadius: 5
}

const medallionStyle = {
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center",
    height: 35, 
    width: 40, 
    borderTopRightRadius:5,
    borderTopLeftRadius:5
}

const imageStyle = {
    height:"70%", 
    width: "70%", 
    padding:"2%",
    objectFit: "contain"
}

const countDownStyle = {
    height:15, 
    width:50, 
    borderRadius:100,
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center",
    backgroundColor:"white",  
    fontSize:10, 
    fontWeight:"bold",
    boxShadow: "2px 3px 5px grey", 
    margin:3
}

export default React.memo(QuestMedallion);