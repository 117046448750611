import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

function NoQuestsMapChip(props) {
 
    return (
        <div style={containerStyle}>
            <FontAwesomeIcon icon={faMapMarkerAlt} style={iconStyle}/>
            <span style={{paddingLeft:10}}>There aren't any quests in your area</span>
        </div>
    )
}

export default React.memo(NoQuestsMapChip);

const containerStyle = {
    display:"flex", 
    position: "absolute", 
    bottom:"4.5%",
    padding:"2.5%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width:"90%",
    backgroundColor:"white", 
    borderRadius:15, 
    justifyContent:"center",
    alignItems:"center", 
    boxShadow: "2px 3px 5px grey", 
}

const iconStyle = {
    color:"deeppink", 
    fontSize:25
}