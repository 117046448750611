import React, {useState} from 'react';
import ProgressBar from '../../../../components/common/ProgressBar';
import AreaQuestPrizeList from '../AreaQuestPrizeList';
import { useQuestContext } from '../../../../context/questContext';
import { useEffect } from 'react';
import getTrafficCount from '../../../../api/getTrafficCount';
import set from 'lodash/set';
import TrafficHintText from '../hints/TrafficHintText';
import TrafficScratcher2 from '../scratcher/TrafficScratcher';
import ErrorComponent from '../scratcher/ErrorComponent';
import openTrafficPrize from '../../../../api/openTrafficPrize';
import removeTrafficLock from '../../../../api/removeTrafficLock';
import { useUserLocContext } from '../../../../../../context/UserLocContext';
import { useWonPrizeContext } from '../../../../context/wonPrizeContext';



function AreaQuestTrafficModal({showInfo, isLoading, setIsLoading, apiErrorCode, setApiErrorCode}) {

    const {selectedQuestModified, selectedQuestRaw, updateQuest, isUserInside} = useQuestContext()
    const {userLocation} = useUserLocContext()

    const isAlreadyWon = selectedQuestModified?.winningEntrees.length > 0
    const isTrafficGoalMet = selectedQuestModified?.traffic?.count >= selectedQuestModified?.traffic?.goal //Could move to context for traffic quests

    const {refreshWonPrizes} = useWonPrizeContext()
    
    async function handleOpenPrize(){
        setIsLoading(true)
        const response = await openTrafficPrize(selectedQuestRaw?.id)

        if(response.status != 200 || !response?.status) {
            setIsLoading(false) 
            if(response.status == 406) setApiErrorCode(response?.data?.code)
            else setApiErrorCode(99) //Render generic error component
            return
        } 
        
        if(response?.data?.prize) refreshWonPrizes()
        const copy = {...selectedQuestRaw}
        copy.goal[0].entrants.push(response.data)
        updateQuest(copy)
        setIsLoading(false)
    }

    async function handleRemoveKey(){
        setIsLoading(true)
        const response = await removeTrafficLock(selectedQuestRaw?.id, userLocation?.simple)
        if(response.status != 200 || !response?.status) {
            setIsLoading(false) 
            if(response.status == 406) setApiErrorCode(response?.data?.code)
            else setApiErrorCode(99) //Render generic error component
            return
        } 
        
        const copy = {...selectedQuestRaw}
        copy.goal[0].traffic_count = response?.data?.traffic_count
        copy.goal[0].num_entrees = copy.goal[0].num_entrees + 1
        setIsLoading(false)
        updateQuest(copy)
    }

    useEffect(()=>{
        //handleTrafficCountUpdate() //This should be handles inside of context. 
    }, [])


    return ( 
        <>
            <div style={{marginTop:45}}/>

            <div style={titleStyle}>{selectedQuestModified?.name}</div>
            <div style={descriptionStyle}>{selectedQuestModified?.description}</div>

            <div style={{width:"90%", display:"flex", justifyContent:"center", marginTop:20, flexDirection:'column', alignItems:"center"}}>
                <ProgressBar current={selectedQuestModified?.traffic?.count} max={selectedQuestModified?.traffic?.goal}/>
                <span style={{fontSize:10}}>Remove the locks for a chance at the prizes</span>
            </div>

            <AreaQuestPrizeList prizes={selectedQuestModified?.prizes}/>

            <div style={{marginTop:"6.5%"}}>
                {apiErrorCode ? <ErrorComponent apiErrorCode={apiErrorCode}/> :
                    <TrafficScratcher2 handleRemoveKey={handleRemoveKey} handleOpenPrize={handleOpenPrize} isLoading={isLoading}/>
                }
            </div>

            <TrafficHintText isUserInside={isUserInside} numEntrees={selectedQuestModified?.traffic?.numEntrees} isTrafficGoalMet={isTrafficGoalMet} isAlreadyWon={isAlreadyWon}/>
        </>
    );
}

export default AreaQuestTrafficModal;


const descriptionStyle = {
    display:"flex", 
    justifyContent:"center", 
    width:"90%", 
    textAlign:"center", 
    fontSize:12, 
}

const titleStyle = {
    fontSize:16, 
    fontWeight: "bold"
}

const imageContainerStyle = {
    height:65, 
    width:65,
    marginTop:15, 
    marginBottom:5 
}

const imageStyle = {
    height:"90%", 
    width:"90%", 
    objectFit:"contain"
}
