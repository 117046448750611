import React from 'react';
import styled, {keyframes} from "styled-components";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';


function CheckMarkBubble(props) {

    const scaleAnimation = keyframes `
        0%{transform: scale(0.1); opacity:0.1}
        100%{transform: scale(1.0); opacity:1.0}
    `;

    const CollectBadge = styled.div`
        position: relative; 
        height: 20px; 
        width: 20px; 
        border-radius:10px;
        background: deeppink; 
        top: 0px;
        right: 0px;
        display: flex; 
        justify-content: center; 
        align-items: center;
        animation-name: ${scaleAnimation};
        animation-duration: 0.3s;
        animation-iteration-count: 1;
    `;


    return (
        <CollectBadge>
            <CheckRoundedIcon style={{ fontSize:9, color:"white"}}/>
        </CollectBadge>
    );
}

export default CheckMarkBubble;