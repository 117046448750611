import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faQuestionCircle, faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons';
import AreaQuestTrafficModal from './AreaQuestTrafficModal';
import AreaQuestRechargeModal from './AreaQuestRechargeModal';
import DetailsImageContainer from '../DetailsImageContainer';
import GeneralQuestModal from './GeneralQuestModal';
import { useQuestContext } from '../../../../context/questContext';
import { tabBarHeight } from '../../../../utils/stdVariables';


function AreaQuestModal({isOpen, closeModal}) {

    const [showRules, setShowRules] = useState(false) //handle rule component at this level...dont pass down as prop
    const [isLoading, setIsLoading] = useState(false) //pass this down
    const [apiErrorCode, setApiErrorCode] = useState(null) //pass this down

    const {selectedQuestRaw, selectedQuestModified, isUserInside}  = useQuestContext()

    useEffect(()=>{
        setShowRules(false)
        setApiErrorCode(null)
        setIsLoading(false)
    },[isOpen])

    //useEffect(()=>{
    //    setApiErrorCode(null)
    //}, [selectedQuestModified])

    function ModalLayoutSelector(){
        if(selectedQuestRaw?.goal?.[0]?.traffic > 0) return (
            <AreaQuestTrafficModal 
                isInside={isUserInside}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                apiErrorCode={apiErrorCode}
                setApiErrorCode={setApiErrorCode}
            />
        )
        else if(selectedQuestRaw?.goal?.[0]?.recharge_time > 0) return (
            <AreaQuestRechargeModal
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                apiErrorCode={apiErrorCode}
                setApiErrorCode={setApiErrorCode}
            />
            )
        else return (
            <GeneralQuestModal 
                isInside={isUserInside} 
                showRules={showRules}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                apiErrorCode={apiErrorCode}
                setApiErrorCode={setApiErrorCode}
            />
        )
    }

    return ( 
        isOpen && 
            ReactDOM.createPortal(
                <div style={wrapperStyle}>
                    <div style={containerStyle}>

                        <div style={imageContainer}>
                            <DetailsImageContainer image={selectedQuestModified?.image}/>
                            {/*<div style={questTypeMedallionStyle}> Add a picking object for the type medallion..move to own component
                                <FontAwesomeIcon icon={faBolt} style={{fontSize:10, color:"white"}}/>
                            </div>*/}

                        </div>

                        <div style={closeButtonStyle} onClick={closeModal}>
                            X   
                        </div> 

                        <div style={rulesButtonStyle} onClick={()=>setShowRules(!showRules)}>
                            {showRules ? "Quest" : "Rules"}
                        </div>

                        {/*<div style={infoButton} onClick={()=>setShowRules(!showRules)}>
                            <FontAwesomeIcon icon={showRules ? faArrowCircleLeft : faQuestionCircle} style={{color:"#DDDDDD", fontSize:24}}/>
                        </div>*/}

                        <ModalLayoutSelector/>
                    </div>
                </div>
            ,document.body
            )
    );
}

const containerStyle = {
    position: "relative",
    display:"flex", 
    flexDirection:"column", 
    justifyContent:"space-evenly",
    alignItems:"center", 
    width:"85%",
    minHeight: "40%", 
    borderRadius: 20, 
    backgroundColor: "white", 
    boxShadow: "2px 3px 5px grey", 
    //overflow:"hidden",
    paddingBottom:20
}

const rulesButtonStyle = {
    position: "absolute", 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center", 
    top:"2.5%", 
    left:"3.5%", 
    height:25, 
    width:25, 
    //borderRadius:25, 
    //borderColor:"#DDDDDD", 
    //borderWidth:1, 
    //borderStyle:"solid", 
    color:"#DDDDDD", 
    fontWeight:"bolder"

}

const questTypeMedallionStyle = {
    position: "absolute", 
    top:-8.5,
    right:2.5,
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center",   
    height:22.5, 
    width:22.5,
    borderRadius:12.5, 
    backgroundColor:"dodgerblue"
}

const imageContainer = {
    position: "absolute", 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center", 
    top:-40, 
    height:80, 
    width:80, 
    borderRadius:40, 
    backgroundColor:"white", 
    borderStyle:"solid", 
    borderColor:"black", 
    borderWidth:3
}

const wrapperStyle = {
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center",
    position: "fixed", 
    top:0, 
    bottom:tabBarHeight, 
    left:0, 
    right:0, 
    zIndex:1000, 
    //backgroundColor:"grey"
}

const closeButtonStyle = {
    position: "absolute", 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center", 
    top:"2.5%", 
    right:"2.5%", 
    height:25, 
    width:25, 
    borderRadius:25, 
    borderColor:"#DDDDDD", 
    borderWidth:1, 
    borderStyle:"solid", 
    color:"#DDDDDD", 
    fontWeight:"bolder"
}

const infoButton = {
    position: "absolute", 
    top:10, 
    left:10
}

export default AreaQuestModal;