import React, {useState} from 'react';
import styles from "./TestCSS.module.css";
import {Modal, Button} from "react-bootstrap";

function LogOut({doLogOut, doClose, doShow, show}) {

    const handleClose = () => {
        doLogOut()
        doClose()
    }

    return (
        <>
        <div onClick={doShow} style={{color:"grey", fontSize:"bold", }}>
          Logout
        </div>
  
        <Modal show={show} onHide={doClose}>
          <Modal.Header closeButton>
            <Modal.Title>Logout</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to logout?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={doClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Logout
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default LogOut;
