import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faKey, faCheckCircle} from '@fortawesome/free-solid-svg-icons';

//TODO -> Export CSS so can be used by all similar components. 
function LockRemoved() {
    
    const container = {
        display:"flex", 
        flexDirection:"column", 
        justifyContent:"center", 
        alignItems:"center", 
        height: "100%", 
        width:"100%", 
        textAlign:"center", 
        fontSize:12, 
        borderRadius:5, 
        borderWidth:1, 
    }

    const checkIcon = {
        color:"deeppink", 
        fontSize:20,
    }

    const checkIconContainer = {
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center", 
        backgroundColor:"white", 
        borderRadius:10, 
        position:"absolute", 
        top:0, 
        left:5,
        padding:"2%"
        //borderColor:"grey", 
        //borderWidth:1, 
        //borderStyle:"solid", 
    }

    const keyIcon = {
        fontSize:35, 
        color: "grey",
    }

    return(
        <div style={container}>
            <FontAwesomeIcon icon={faKey} style={keyIcon}/>
            <span style={{fontWeight:"bold", fontSize:12, color:"grey"}}>Lock</span>
            <span style={{fontWeight:"bold", fontSize:12, color:"grey"}}>removed</span>
            {
                <div style={checkIconContainer}>
                    <FontAwesomeIcon icon={faCheckCircle} style={checkIcon}/>
                </div>
            }
        </div>

    )
}

export default React.memo(LockRemoved);