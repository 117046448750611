import React, {useState} from 'react';
import styled from "styled-components";
import CloseButton from '../common/CloseButton';


//This is hacky solution to translate 200% to get the details modal off the screen.  Fix later. 

const Drawer = styled.div`
    height: ${props => props.maxHeight || "100%"};
    width: 100%;
    background: #f9f9f9;
    display:flex;
    flex-direction:column;
    border-radius: 20px;
    position: absolute;
    bottom: 0px;
    transform: ${ props => props.isOpen ? "translateY(0%)" : "translateY(100%)"};
    transition: transform 0.7s ease;
    z-index: ${ props => props.zInd};
    will-update: transform;
`;

function MyQuestListDrawer({isOpen, maxHeight, shrink, close, body, zIndex}) {

    const [isFullHeight, setIsFullHeight] = useState(false)

    function handleClose(){
        setIsFullHeight(false)
        close()
    }

    return (
            <Drawer isOpen={isOpen} isFullHeight={isFullHeight} maxHeight={maxHeight} shrink={shrink} zInd={zIndex}>
                <CloseButton close={handleClose}/>
                <div style={headerStyle} onClick={()=>setIsFullHeight(!isFullHeight)}>
                    {
                        shrink && <div style={testButton}></div>
                    }
                </div>
                <div style={bodyStyle}>
                    {body}
                </div>
            </Drawer>
    );
}

export default React.memo(MyQuestListDrawer);

const testButton = {
    height: 5, 
    width: "25%",
    justifySelf:"center",
    borderRadius:30,
    backgroundColor:"#CCCCCC", 
    margin:15,
    zIndex:5
}

const headerStyle = {
    display:"flex",
    height:40,
    justifyContent:"center",
    borderRadius:20,
    width:"100%", 
    position:"sticky", 
    top:0
}

const bodyStyle = {
    height: `calc(100%)`,
    width:"100%", 
    position:"relative",
    backgroundColor:"#f9f9f9", 
    overflowY:"scroll", 
    overflowX:"hidden"
}