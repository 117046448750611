import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faQrcode, faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import CountDownTimer from '../../../common/components/CountDownTimer';
import SQSquareLogo from '../../../../Images/SQSquareLogo.png'
import { getPrizeStatus } from './getPrizeStatus';

//TODO add a symbol for the redemption type (qr, email, mail)
//Status => claimed, expired, on-delivered, claimed - emailed

function PrizeListItem({prize}) {

    const navigate = useNavigate()
    const expires = new Date(prize?.prize?.expires).toLocaleString()
    const status = getPrizeStatus(prize?.claimed, prize?.prize?.expires)
    const redemptionType = prize?.prize?.redemption_type

    console.log("redemptionType", redemptionType)

    return (

        <div style={container} onClick={() => navigate(`/prize/${redemptionType}`, {state:{prize}})}>

            <div style={imageContainer}>
                <img 
                    src={prize?.quest_image ? prize?.quest_image : SQSquareLogo} 
                    style={{objectFit:"contain", height:"90%", width:"90%"}}
                />
            </div>

            <div style={textContainer}>
                <RedemptionTypeBadgePicker redemptionType={redemptionType}/>
                <span style={{fontWeight:"bold"}}>{prize?.prize?.name}</span>
                <div style={{fontSize:10}}>{`status: ${status}`}</div>
                <div style={{fontSize:10}}>{`expires: ${expires}`}</div>
            </div>

            <div style={claimContainer}>
                {/*<span style={buttonText}>claim</span> //Consider bringing back later to help users*/}
                <FontAwesomeIcon icon={faChevronRight} style={{fontSize:22, color:"deeppink"}}/>
            </div>
        </div>
    );
}

export default PrizeListItem;


function RedemptionTypeBadgePicker({redemptionType}){

    let icon;
    let text;

    switch(redemptionType){
        case "qr": icon = faQrcode; text= 'QR Code'; break;
        case "email": icon = faEnvelope; text = "Email"; break; 
        case "mail" : icon = faPaperPlane; text = "Mail"; break; //Change icon to mailbox. 
    }

    return (
        <div style={{fontSize:10, color:"dodgerblue", }}>
            <FontAwesomeIcon icon={icon} style={{...iconStyle}}/>{text}
        </div>
    )

}


const container = {
    display:"grid",
    gridTemplateColumns: "1.25fr 3fr 1fr", 
    width:"100%",
    borderRadius: 10, 
    borderWidth:1, 
    borderStyle:"solid", 
    borderColor:"#EEEEEE",
    padding:"4.0%", 
    marginTop:"2.5%", 
    alignItems:"center"
}

const iconStyle = {
    color:"dodgerblue", 
    fontSize:10, 
    marginRight:"2%"
}

const imageContainer = {
    display:"flex",
    justifyContent:"center", 
    alignItems:"center",
    height: 50, 
    width: 50, 
    borderRadius: 5, 
}

const textContainer = {
    display:"flex",
    flexDirection:"column", 
}

const claimContainer = {
    display:"flex", 
    justifyContent: "flex-end", 
    alignItems:"center",
}

const buttonText = {
    fontSize:10, 
    textAlign:"center", 
    color:"deeppink", 
    fontWeight:"bold"
}