import React, {useEffect, useLayoutEffect, useState, memo} from 'react';
import styled, {keyframes} from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift } from '@fortawesome/free-solid-svg-icons';


const rotate = keyframes`

0% {
  transform: scale(0.4);
}

70% {
  transform: scale(1.0);
}

100% {
  transform: scale(0.4);
}
`;

const Rotate = styled.div`
  display: flex;
  border-radius: 100px;
  animation: ${rotate} 2s linear 4;
  padding:3px;
  font-size: 1.2rem;
  box-shadow: 0 0 0 0 rgba(255, 20, 147, 1);
	transform: scale(1);
`;

function PrizeMedallion(props) {

  let location; 
  const [showIcon, setShowIcon] = useState(false);
  const [divLocation, setDivLocation] = useState({top:0, left:0});

  const container = {
    display:"flex",
    position:"fixed", 
    top: divLocation?.top - 12.5, 
    left: divLocation?.left -12.5,
    justifyContent:"center", 
    alignItems:"center", 
    flexDirection:"column",
  }

  useLayoutEffect(() => {
    //let div = document.querySelector("test")
    let div2 = document.getElementById("walletButton")
    location = div2.getBoundingClientRect();
    setDivLocation(location)
    console.log("info", location?.top)
  }, [])

    return (
      console.log("info 2", location?.top),
        <div style={container} id="test">
            <Rotate>
              <div style={innerContainer}>
                  <FontAwesomeIcon icon={faGift} style={{color:"white", fontSize:15}}/>
              </div>
            </Rotate>
            <div style={textStyle}></div>
        </div>
    );
}

export default memo(PrizeMedallion);

const innerContainer = {
  display:"flex", 
  borderRadius:100, 
  height:30,
  width:30,
  padding:3,
  borderColor:"white", 
  justifyContent:"center", 
  alignItems:"center", 
  borderWidth:3, 
  borderStyle:"solid", 
  backgroundColor:"deeppink",
  boxShadow: '1px 2px 9px black'
}

const textStyle = {
  fontWeight:"bolder", 
  fontSize:14, 
  textAlign:"center", 
  color:"deeppink"
}