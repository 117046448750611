import React, {useState, useEffect} from 'react';


function RaffleTimer({endDate, goal_complete, endCallback, scale=1, colour, subTitle=true, raffleStatus=true, style, fontWeight}) {

    const [timer, setTimer] = useState({
        days:0,
        hours:0,
        min:0,
        sec:0
    })
    
    let interval;

    useEffect(()=>{

        if(goal_complete === true) return //Cancels execution is the goal is already complete

        interval = setInterval(() => {
            calcCountDown()
         }, 1000);
        
        return () =>  clearInterval(interval)
    }, [endDate, goal_complete])
    

    function calcCountDown(){
        console.log("calcCountDown")

        let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;
        
        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            millisec: 0,
        };

        if (diff <= 0 || !diff) {
            
            if(goal_complete === false) setTimeout(()=>endCallback(), 3000) //Delay to give the server enough time to award. 
            clearInterval(interval) //Clears the interval 
            setTimer(timeLeft)
            return false
        };

        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
        timeLeft.years = Math.floor(diff / (365.25 * 86400));
        diff -= timeLeft.years * 365.25 * 86400;
        }

        if (diff >= 86400) { // 24 * 60 * 60
        timeLeft.days = Math.floor(diff / 86400);
        diff -= timeLeft.days * 86400;
        }

        if (diff >= 3600) { // 60 * 60
        timeLeft.hours = Math.floor(diff / 3600);
        diff -= timeLeft.hours * 3600;
        }

        if (diff >= 60) {
        timeLeft.min = Math.floor(diff / 60);
        diff -= timeLeft.min * 60;
        }

        timeLeft.sec = diff;

        setTimer(timeLeft)
    }

    const styles ={

        container:{
            display:"flex", 
            flexDirection:"column",
            alignItems:"center"
        },

        timerBox:{
            height: style !== "chip" && 50*scale,
            width: style !== "chip" && 35*scale,
            borderRadius: style === "chip" ? 0 : 8*scale, 
            display:"flex",
            flexDirection:"column",
            justifyContent:"center", 
            alignItems:"center",
            backgroundColor: colour ? colour : "#f4f4f4", 
            margin: style === "chip" ? 0 : 2*scale
        }, 

        row:{
            display:"flex", 
            flexDiretion:"row",
            justifyContent:"center",
            alignItems:"center"
        },

        text:{
            fontWeight: fontWeight ? fontWeight : "bolder",
            fontSize:16*scale, 
            color:"black"
        },

        subText:{
            color:"grey", 
            fontSize:10*scale, 
            fontWeight: fontWeight ? fontWeight : "bolder"
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.row}>
                <div style={styles.timerBox}> 
                    <div style={styles.text}>{String(timer.days).padStart(2,'0')}{style==="chip" && ':'}</div>
                    { subTitle && <div style={styles.subText}>days</div>}
                </div>
                
                <div style={styles.timerBox}> 
                    <div style={styles.text}>{String(timer.hours).padStart(2,'0')}{style==="chip" && ':'}</div>
                    {subTitle &&  <div style={styles.subText}>hours</div>}
                </div>
                
                <div style={styles.timerBox}> 
                    <div style={styles.text}>{String(timer.min).padStart(2,'0')}{style==="chip" && ':'}</div>
                    {subTitle && <div style={styles.subText}>min</div>}
                </div>
                
                <div style={styles.timerBox}> 
                    <div style={styles.text}>{String(timer.sec).padStart(2,'0')}</div>
                    {subTitle && <div style={styles.subText}>sec</div>}
                </div>
            </div>

            {raffleStatus &&
            <div style={{color:"grey"}}> 
                {goal_complete ? "Raffle Complete" : "Raffle in"}
            </div>}

        </div>

    );
}

export default RaffleTimer = React.memo(RaffleTimer);