import React, {useState} from 'react';
import {Card, VisualPicker, VisualPickerOption, StripeCardInput, GoogleAddressLookup, Input, Button } from "react-rainbow-components";
import  styled from "styled-components";
import ScienceRoundedIcon from '@mui/icons-material/ScienceRounded';
import { IoRocketSharp} from "react-icons/io5";
import {loadStripe} from '@stripe/stripe-js'; //Stripe
import { Elements, useStripe, useElements, PaymentElement, CardElement} from '@stripe/react-stripe-js';
import StripePayment from './StripePayment';
import { FaKey } from "react-icons/fa";
import MediaQuery from './common/MediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faRocket, faFlask } from '@fortawesome/free-solid-svg-icons';


//Make a HTTP call to update the status of the quest from "INCO" to "TEST" or "PEND"

const StyledCardTitle = styled.div`
    font-size:30px;
    font-weight:bold;
`;

const StyledContainer = styled.div`
    height: 100%;
    width:100%;
    display: flex;
    flex-direction: column;
    background: white;
    justify-content:center;
    align-items:center;
`;

const StyledRow = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items: center;
`;

const MyVisualPickerOption = styled(VisualPickerOption)`
    transform: scale(0.5);
`;


const text = {
    color:"grey",
    fontWeight:"bold"
};

const subText = {
    color:"grey", 
    fontWeight:"bold"
};

const iconStyle = {
    fontSize:75, 
    color:"deeppink"
}


const LIBRARY_STRIPE_APIKEY = "1234567890100030";
const LIBRARY_GOOGLE_MAPS_APIKEY = "AIzaSyCgzcNpBgI_hSkAA4WiYisk1O4hYa5YPZ0"//Need to manage this better 
const searchOptions = { types: ['address'] };

function SelectModeLayout({mode, setMode, questID}) {

    const stripe = useStripe(); //Needs to be housed in parent to prevent multiple instance 
    const elements = useElements(); //Needs to be housed in parent to prevent multple instance.
    const isStaff = localStorage.getItem("is_staff").toLowerCase() === "true"

    console.log("isStaff mode", isStaff)

    const isMobile = MediaQuery();
    const deviceStyle = {
        cardWidth: isMobile ? "90%" : "70%"
    }

    const cardStyle = {
        width:deviceStyle.cardWidth, 
        height:"90vh", 
        backgroundColor:"white", 
        marginBottom:30
    };

    const AdminMode = (
        <div>
            <div style={text}>As an admin, you can bypass the payment. </div> <br/>
        </div>
    );
  
    const TestMode = (
        <div>
            <div style={text}>Still not sure how sideQuest works? No worries, put it into test mode and play around with it. </div> <br/>
            <div style={text}>Its free and no one else but you can see it in the mobile app. </div><br/>
            <div style={text}>Just download and login to sideQuest on your mobile device using your same credentials. </div> <br/>
            <div style={text}>You can submit it if you decide.  Just allow 24hrs for approval </div>
        </div>
    );

    const Payment = (
        <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
            <div style={text}>Submit your quest to go live on the sideQuest mobile app!</div>
            <div style={text}>Please allow 24hrs for approval,</div>
            <StripePayment stripe={stripe} elements={elements} questID={questID}/>
        </div>
    );

    function switchComponent(){

        switch(mode){
            case("ACT"):
                return AdminMode
            case("PEND"):
                return Payment
            case("TEST"):
                return TestMode
        }
    }

    function DeskVisualPicker(){

        return(
    
            <VisualPicker
                value={mode}
                onChange={(value)=>setMode?.(value)}
                style={{width:"100%", flexDirection:"row"}}
                size={"small"}
            >
                    { isStaff &&
                    <VisualPickerOption name="ACT" style={{margin:10}}>
                        <FaKey style={iconStyle}/>
                        <div style={subText}>Admin</div>
                    </VisualPickerOption>
                    }

                    <VisualPickerOption name="PEND" style={{margin:10}}>
                        <IoRocketSharp style={iconStyle}/>
                        <div style={subText}>Submit</div>
                    </VisualPickerOption>
                
                    <VisualPickerOption name={"TEST"} style={{margin:10}}>
                        <ScienceRoundedIcon style={iconStyle}/>
                        <div style={subText}>Sandbox</div>
                    </VisualPickerOption>
            
            </VisualPicker>      
        )
    }

    function MobileVisualPicker(){

        function isSelected(item){
            return mode == item
        }

        const container = {
            display:"flex",
            padding:"10%",
            width:"100%", 
            justifyContent:"space-evenly"
        }

        const menuItem = {
            display:"flex", 
            flexDirection:"column", 
            justifyContent:"center", 
            alignItems:"center"
        }

        return(
            <div style={container}>

                {isStaff && <div style={menuItem} onClick={()=>setMode("ACT")}>
                    <FontAwesomeIcon size={"2x"} icon={faKey} color={isSelected("ACT") && "deeppink"}/>
                    Admin
                </div>}

                <div style={menuItem} onClick={()=>setMode("PEND")}>
                    <FontAwesomeIcon size={"2x"} icon={faRocket} color={isSelected("PEND") && "deeppink"}/>
                    Activate
                </div>

                <div style={menuItem} onClick={()=>setMode("TEST")}>
                    <FontAwesomeIcon size={"2x"} icon={faFlask} color={isSelected("TEST") && "deeppink"}/>
                    Sandbox
                </div>
              
            </div>
        )
    }

    return (

        <Card
            style={cardStyle}
            title={<StyledCardTitle>Next Steps</StyledCardTitle>}
            footer={
                <StyledContainer>

                    <StyledRow>
                        {isMobile ? <MobileVisualPicker/> : <DeskVisualPicker/>}                    
                    </StyledRow>

                    <StyledRow>
                        {switchComponent()}
                    </StyledRow>

                </StyledContainer>
            }
        />
    );
}

export default SelectModeLayout;



