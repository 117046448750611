import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useQuestContext} from "../../../context/QuestContext"
import CloseButton from '../common/CloseButton';
import { headerHeight } from '../../common/componentDims';
import useIsTablet from '../../../utils/useIsTablet';

//TODO Use the same drawer component for questList and questDetails

const Drawer = styled.div`
    height: calc(99vh - ${headerHeight}px);
    width:${props => props.width};
    background:#F9F9F9;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 2px 3px 5px grey;
    position: absolute;
    left:${props => "-" + props.width}
    top:0;
    transform: ${props => props.isOpen ? `translateX(${props.width})` : `translateX(0vw)` };
    transition: all ease 0.4s;
    z-index:1001;
    padding-top:15px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;               
      }
`;

function SideDrawerDetails({children}) {

    const {quests} = useQuestContext()//Could use context to get the selectedQuest but may cause memo slowdown
    const [isOpen, setIsOpen] = useState(false);
    const {selectedQuest} = useQuestContext();

    const isTablet = useIsTablet()
    const drawerWidth = isTablet ? "50vw" : "30vw"

    console.log("sideDrawerDetails rendered")

    useEffect(()=>{
        if(selectedQuest !== null && Object.keys(selectedQuest).length > 0){
            setIsOpen(true)
        } else{
            setIsOpen(false)
        }
    }, [selectedQuest])


    return (
        <>
            {<Drawer isOpen={isOpen} width={drawerWidth}>
                <CloseButton close={()=>setIsOpen(!isOpen)}/>
                {children}
            </Drawer>}
        </>
    );
}

export default React.memo(SideDrawerDetails);


const menuButton = {
    position:"absolute", 
    //height:50, 
    //width:50, 
    //borderRadius:25, 
    backgroundColor:"#6600FF",
    right:0, 
    top:200, 
    //display:"flex", 
    //justifyContent:"center", 
    //alignItems:"center",
    //boxShadow: "2px 3px 5px grey"

}