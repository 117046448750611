import React from 'react';
import {Marker} from "react-map-gl";
import MapDefault from "../Images/MapDefault.png";
import {ReactComponent as MapMarkerSVG2} from "../Images/MapMarkerSVG2.svg";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import styled from "styled-components"; 
import HeadingText from './HeadingText';
import { useQuestContext } from '../context/QuestContext';

const MarkerPopup = styled.div`
    padding:2px;
    text-align:center; 
    scale: 0;
    width: ${props => `${props.width}px`};
    background : white; 
    border-radius: ${props => `${props.lines*5}px`};
    position: absolute; 
    top: ${props => props.lines === 1 ? "-70px" : "-90px"};
    right:${props => `${-props.width/2}px`}; 
    box-shadow:2px 3px 5px grey;
    transform: scale(1.0);
    transition: all 0.4s ease;
`;

const CollectBadge = styled.div`
    position: absolute; 
    height:  15px; 
    width: 15px; 
    border-radius:10px;
    background: deeppink; 
    top: -42px; 
    right: -22px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    transform: scale(1.0);
    transition: all 0.4s ease;
`;

const imageContainer = {
    height: 20, 
    width: 20, 
    borderRadius: 15, 
    backgroundColor:"white",
    position:"absolute", 
    top:-30, 
    left: -8.5,
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center",
}

const imageStyle = {
    height: 20, 
    width: 20,  
    borderRadius:10,
    objectFit:"contain",
    top:-10,
}


function PlayMapMarkerV3Selected({ tokens, handleTap}) {
    
    console.log("marker rerendered")
    const {selectedQuest} = useQuestContext()
    const SIZE = 60; 

    function MarkerPopUp({token}){

        const leng = token?.token_name.length;
        let width;
        let lines;

        if(leng <= 25){width = leng * 7.2; lines = 1}
        else{width = 150; lines = 2 }

        return(
            <MarkerPopup width={width} lines={lines}>
                <HeadingText maxLines={2} fontSize={12} color="#4f4f4f">{token?.token_name}</HeadingText>
            </MarkerPopup>
        )
    }

    return (

        tokens?.map( token => {

            const isSelected = token?.quest_id === selectedQuest?.id;

            const tokenData = {
                token_id: token?.id, 
                quest_id: token?.quest_id,
                token_radius: token?.token_radius,
                token_lat: token?.token_lat,
                token_long: token?.token_long, 
                token_collected_count: token?.token_collected_count
            }

            return (
                
                <Marker
                    latitude={token.token_lat}
                    longitude={token.token_long}
                    key={token.token_id}
                    onClick={() => handleTap(tokenData)}
                >
                    <div style={{height:0, width:0}}>
                        
                        <MapMarkerSVG2 viewBox='0 0 80 80' height={SIZE} style={{cursor:"pointer", transform: `translate(${-SIZE / 2}px,${-SIZE}px) ${isSelected ? "scale(1)" : "scale(0.5)"}`, position:"relative", top:15, left:6 }}>
                        </MapMarkerSVG2>

                            <div style={imageContainer}>
                                    <img
                                        style={imageStyle}
                                        src={token?.quest_image ? token?.quest_image : MapDefault}
                                    />
                            </div>

                            {token?.token_collected_count > 0 &&
                            <CollectBadge>
                                <CheckRoundedIcon style={{ fontSize:7, color:"white"}}/>
                            </CollectBadge>}

                            <MarkerPopUp token={token}/>
                        
                    </div>
                        
                </Marker>
            )
        })
        
    );
}

export default React.memo(PlayMapMarkerV3Selected);

