import React from 'react';
import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import RedeemRoundedIcon from '@mui/icons-material/RedeemRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import CasinoRoundedIcon from '@mui/icons-material/CasinoRounded';
import DoNotDisturbRoundedIcon from '@mui/icons-material/DoNotDisturbRounded';
import MyText from '../../MyText';
import { useQuestContext } from '../../../context/QuestContext';
import RaffleTimer from '../../common/RaffleTimer';


function HelpLayout() {

    const {selectedQuest} = useQuestContext()
    const raffleDate = selectedQuest?.goal?.[0]?.goal_raffle_date;


    function ListItem({text, children}){

        return(
            <div style={listItem}>
                <div style={{marginRight:20, backgroundColor:"#EEEEEE", borderRadius:10, padding:5}}>
                    {children}
                </div>
                <MyText color="grey" align="left" wrap={true} >{text}</MyText>
            </div>
        )
    }

    function RaffleItem({text, children}){

        return(
            <div style={listItem}>
                <div style={{marginRight:20, backgroundColor:"#EEEEEE", borderRadius:10, padding:5}}>
                    {children}
                </div>
                <div 
                    style={{display:"flex", flexDirection:"column", alignItems:"flex-start", width:"60%", display:"flex", alignItem:"left"}}
                >
                    <MyText color={"grey"} align="left" wrap={true}>{text}</MyText>
                    <div style={{marginTop:10}}>
                        <RaffleTimer endDate={raffleDate} scale={0.6} subTitle={false}/>
                    </div>
                </div>
            </div>
        )
    }

    
    return (
        <div style={outterContainer}>
            <div style={container}>

                {/*<div style={{marginLeft:10}}>
                    <MyText fontSize={30}>Quest Directions</MyText>
                </div>*/}

                <ListItem
                    text={"No purchase necessary"}
                >
                    <DoNotDisturbRoundedIcon style={{fontSize:40, color:"deeppink"}}/>
                </ListItem>

                <ListItem
                    text={"Check-in at the quest locations with sideQuest"}
                >
                    <RoomRoundedIcon style={{fontSize:40, color:"deeppink"}} />
                </ListItem>

                <ListItem
                    text={`Check-in at ${selectedQuest?.goal?.[0]?.goal_goal_amount} location(s) to receive a chance at the prizes`}
                >
                    <RedeemRoundedIcon style={{fontSize:40, color:"deeppink"}}/>
                </ListItem>

                <RaffleItem
                    text={"You can open your prizes boxes in:"}
                >
                        <EventRoundedIcon style={{fontSize:40, color:"deeppink"}}/>
                </RaffleItem>

                <ListItem
                    text={"Odds of winning depend on the number of participants"}
                >
                    <CasinoRoundedIcon style={{fontSize:40, color:"deeppink"}}/>
                </ListItem>

            </div>
        </div>
    );
}

export default HelpLayout;


const outterContainer = {
    //height:"100vh",
    width:"100%", 
    display:"flex", 
    alignItems:"flex-start", 
    justifyContent:"center", 
}

const container = {
    //height:"100%", 
    width:"90%", 
    borderRadius:20,
    backgroundColor:"white", 
    display:"flex", 
    alignItems:"flex-start", 
    justifyContent:"flex-start", 
    flexDirection:"column", 
    marginBottom:20
}

const listItem = {
    display:"flex", 
    width:"96%", 
    //backgroundColor:"#f9f9f9", 
    alignItems:"center", 
    justifyContent:"flex-start", 
    padding:10, 
    margin:5
}