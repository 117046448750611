import { useState } from "react";
import BaseClient from "../../../API/BaseClient";
import { useUserLocContext } from "../../../context/UserLocContext";

const URL = "/api/areaquest/get_area_quests/"

async function useGetAreaQuests() {

    let obj = {};

    const {coords: userLocation, locationPermission} = useUserLocContext()
    const [results, setResults] = useState(null)

    console.log("useGetAreaQuest", results)
    setResults(true)

    if (locationPermission != "granted") return
    else if(!userLocation) return
    else if(userLocation == "error"){ userLocation=null/*return "error"*/}

    try{
        const response = 
            await BaseClient.post(URL, 
                {latitude: userLocation?.latitude, longitude: userLocation?.longitude}
            )

        if(response.data){
            obj.status="ok"
            obj.data = response.data
            console.log("useGetAreaQuests", response)
        }
        return obj
    }

    catch(error){
        
        if(error.response){ obj.status="Error"}
        else if(error.request){ obj.status="Error"}
        return obj
    }
}

export default useGetAreaQuests;