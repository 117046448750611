import React from 'react';
import AreaQuestMedallion from './AreaQuestMedallion';

function AreaMedallionContainer({children}) {

    const container = {
        position: "absolute", 
        top:"1%", 
        right:"2%",
        display: "flex", 
        flexDirection:"column",
        justifyContent: "flex-start", 
        alignItems: "center", 
        height:"90%", 
    }

    return (
        <div style={container}>
            {children}
        </div>
    );
}

export default AreaMedallionContainer;