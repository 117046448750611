import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../context/AuthContext';
import redeemPrize from '../../api/redeemPrize';
import { tabBarHeight } from '../../utils/stdVariables';


//NOTE: This is a good breakpoint to add more logic i.e. creators needs to enter auth code for redemp. 
//Note: Gives flexibility to redirect to authentication. 

function RedeemPrize(props) {

    const params = useParams()
    const [isSuccess, setIsSuccess] = useState(null)

    const {} = useAuthContext()

    async function handleRedeem(){
        const response = await redeemPrize(params?.uuid )
        if(response.status >= 200) setIsSuccess(true)
        else setIsSuccess(false)
    }

    console.log("isSucess", isSuccess)

    useEffect(()=>{
        handleRedeem()
    }, [])


    return (
        <div style={container}>
            {isSuccess == null ? null : "this was redeemed"}
        </div>
    );
}

export default RedeemPrize;

const container = {
    height: `calc(100vh - ${tabBarHeight}px)`, 
    overflowY:"auto",
    width: "100%"
}