import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';


function PrizeWin({winningEntrees}) {
    
    const container = {
        display:"flex", 
        flexDirection:"column", 
        justifyContent:"center", 
        alignItems:"center", 
        height: "100%", 
        width:"100%", 
        textAlign:"center", 
        fontSize:12, 
        borderRadius:5, 
        borderWidth:1, 
    }

    return(
        <div style={container}>
            <FontAwesomeIcon icon={faStar} style={{fontSize:22.5, color:"deeppink"}}/>
            <span style={{fontWeight:"bold", color:"deeppink"}}>Winner</span>
            <span style={{fontWeight:"normal", fontSize:16}}>{winningEntrees?.name}</span>
        </div>
    )
}

export default PrizeWin;