import React from 'react';
import styled from 'styled-components';

function CardInputContainer({isMobile, children, title}) {
    return (

        <Container isMobile={isMobile}>

            <ColumnTitle>
                <StyledText>{title}</StyledText>
            </ColumnTitle>

            <ColumnData>
                {children}
            </ColumnData>

        </Container>
    );
}

export default CardInputContainer;


const ColumnData = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    flex:5;
    width:100%;
`;

const StyledText = styled.div`
    color:grey;
    font-size:15px;
`;

const Container = styled.div`
    display:flex;
    flex-direction: ${props => props.isMobile ? "column" : "row"};
    align-items: ${props => props.isMobile ? "flex-start" : "center"};
    margin:10px;
    position: relative;
`;

const ColumnTitle = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    flex:1
`;