import React, {useState} from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
//import QRCode from "react-qr-code"
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {QRCodeSVG} from 'qrcode.react';
import { tabBarHeight } from '../../utils/stdVariables';
import { getPrizeStatus } from './getPrizeStatus';
import { useAuthContext } from '../../../../context/AuthContext';
import { Input } from 'react-rainbow-components';
import { useWonPrizeContext } from '../../context/wonPrizeContext';
import redeemEmailPrize from '../../api/redeemEmailPrize';
import LoadingIcon from '../../../../components/common/LoadingIcon';

//TODO would be better to link this to the wonPrizeContext for easier state updating. 
//TODO Add a function to update the local state of the wonPrizes Object. 
//TODO Change the status of the "confirm" button after confirmation and add note "should recieve an email in 48hrs"
//TODO Change the status of the object to claimed in the data base
//TODO Consider allowing users to change their email here.  Make sure they confirm their email. 

function EmailPrizePage(props) {

    const navigate = useNavigate()
    const location = useLocation()
    const {authObj} = useAuthContext()
    const {getPrize, updatePrizeClaimed} = useWonPrizeContext()
    const [isLoading, setIsLoading] = useState(false)

    const prizeID = location?.state?.prize?.id
    const prize = getPrize(prizeID)
    const expires = new Date(prize?.prize?.expires).toLocaleString()

    async function handleConfirmEmail(){
        setIsLoading(true)
        
        const response = await redeemEmailPrize(prize?.uuid)

        if(response?.status < 200 || response?.status >= 300) {setIsLoading(false); return} 
        
        setIsLoading(false)
        updatePrizeClaimed(prizeID, true)
    }

    function NotClaimedDetails(){
        return(
            <div style={subContainer}>
                <span style={prizeNameText}>{prize?.prize?.name}</span>
                <span>{prize?.prize?.description}</span><br/><br/>
                <span style={headingText}>This prize will be emailed to you!</span>
                <span style={descriptionText}>Confirm your email so we can make sure you get it!</span><br/><br/>
                <Input value={authObj?.userName} type="email"/><br/><br/>
                <div style={buttonContainer} onClick={handleConfirmEmail}>confirm</div>
                {/*<span style={hintText}>Youll need to change your account email if this is incorrect.</span>*/}
            </div>
        )
    }

    function ClaimedDetails(){
        return(
            <div style={subContainer}>
                <span style={prizeNameText}>{prize?.prize?.name}</span>
                <span>{prize?.prize?.description}</span><br/><br/>
                <span style={successText}> Success!</span>
                <span style={headingText}>Your prize email is on its way! Please allow 48hrs for delivery.</span><br/><br/>
                <Input value={authObj?.userName} type="email"/><br/><br/>
            </div>
        )
    }

    function ExpiredDetails(){
        return(
            <div style={subContainer}>
                <span style={prizeNameText}>{prize?.prize?.name}</span><br/><br/>
                <span style={headingText}>Unfortunately this prize has expired and can't be claimed.</span><br/><br/>
                <span style={subHeadingText}>expired</span>
                <span>{expires}</span>
            </div>
        )
    }

    function DetailsPicker(){
        const now = new Date()
        const expires = new Date(prize?.prize?.expires)

        if(now >= expires) return <ExpiredDetails/>
        else if(prize?.claimed) return <ClaimedDetails/>
        else if(!prize?.claimed) return <NotClaimedDetails/>
        else return <div>Error</div> //TODO turn this into a more formal component. 
    }

    //TODO: this needs to be filled in 
    function ErrorDetails(){
        return(
            <></>
        )
    } 

    return (
        <div style={container}>
            
            <div style={{position:"absolute", top:"50%", marginTop:"-50%"}}>
                {isLoading && <LoadingIcon fullScreen={false} text={true}/>}
            </div>

            <div style={headerContainer}>
                <div style={backButtonStyle} onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                </div>

                {!isLoading && <DetailsPicker/>}

            </div>
        </div>
    );
}

export default EmailPrizePage;


const container = {
    display:"flex",
    flexDirection:"column",
    alignItems:"center", 
    justifyContent:"space-around",
    height:`calc(100vh - ${tabBarHeight}px)`,
    overflowY: "auto",
    width: "100%", 
    position: "relative",
    padding:"3.5%", 

}

const subContainer = {
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center", 
    height:"100%", 
    width:"100%", 
}

const backButtonStyle = {
    position:"absolute",
    display:"flex", 
    padding:"2%",
    top:25, 
    left:25, 
}

const prizeNameText = {
    fontSize:26, 
    fontWeight:"bolder", 
    color:"dodgerblue"
}

const headingText = {
    fontSize:16, 
    fontWeight:"bold", 
    textAlign:"center"
}

const descriptionText = {
    fontSize:16, 
    fontWeight:"normal", 
    color:"grey", 
    textJustify:"center",
    textAlign:"center"
}

const headerContainer = {
    width: "100%", 
    display:"flex", 
    padding:"2.5%"
}

const buttonContainer = {
    display:"flex",
    justifyContent:"center",
    padding:"2%", 
    minWidth:"60%", 
    borderRadius:100, 
    backgroundColor:"deeppink",
    textAlign:"center", 
    color:"white", 
    fontWeight:"bolder"
}

const hintText = {
    fontSize:10, 
    fontColor:"grey", 
    textAlign:"center"
}

const subHeadingText = {
    fontSize:16,
    fontWeight:"bold",
    color:"grey"
}

const successText = {
    fontSize:24,
    fontWeight: "bolder",
    color:"deeppink"
}