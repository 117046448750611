import React from 'react';
import LoadingIcon from '../../../../../../components/common/LoadingIcon';
import { useQuestContext } from '../../../../context/questContext';
import UserHasAlreadyWon from './UserHasAlreadyWon';
import AllPrizesGivenOut from './AllPrizesGivenOut';
import PrizeWin from './PrizeWin';
import PrizeLose from './PrizeLose';
import PrizeAPIError from './PrizeAPIError';
import NotRecharged from './NotRecharged';
import Scratcher from './ScratcherComponent';
import TrafficRemoveLock from './RemoveLock';
import LockAlreadyRemoved from './LockRemoved';


//Will need to update the 

function TrafficScratcher2({apiCall, isLoading, isEnabled, handleRemoveKey, handleOpenPrize}) {

    const {selectedQuestModified, isUserInside} = useQuestContext(); 

    function PickComponent(){ 

        if(selectedQuestModified?.winningEntrees?.length > 0) return <PrizeWin winningEntrees={selectedQuestModified?.winningEntrees?.[0]}/> //<AlreadyClaimed/>
        
        else if(selectedQuestModified?.losingEntrees?.length > 0) return <PrizeLose/>

        else if (selectedQuestModified?.traffic?.count >= selectedQuestModified?.traffic?.goal){ 
            return <Scratcher onClick={handleOpenPrize} isEnabled={true}/> // This will always be true once the goal is met.  
        }
        else if(selectedQuestModified?.traffic?.numEntrees > 0) return (
            <LockAlreadyRemoved/>
        )
        else if(selectedQuestModified?.traffic?.numEntrees == 0) return (
            <TrafficRemoveLock handleRemoveKey={handleRemoveKey} isEnabled={isUserInside}/>
        )
        else return <PrizeAPIError/>
    }

    return(
        <div style={scratcher}>
            <PickComponent/>
            {isLoading && <div style={loadingContainer}><LoadingIcon fullScreen={false} text={false}/></div>}
        </div>
    )
}

export default React.memo(TrafficScratcher2);

const scratcher = {
    position: "relative",
    display: "flex", 
    justifyContent:"center", 
    alignItems:"center", 
    overflow: "hidden",
    minHeight:75, 
    minWidth:75, 
    borderRadius:5,
    marginBottom:10
}

const loadingContainer = {
    position:"absolute", 
}
