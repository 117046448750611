import React, {useState} from 'react';
import { useQuestContext } from '../../../context/QuestContext';
import HeadingText from '../../HeadingText';

function GoalDetailsLayout({children}) {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const {selectedQuest} = useQuestContext()
    
    return (
        <div style={{height:"100%", width:"100%", display:"flex", flexDirection:"column", alignItems:"center"}}>

            <div style={{ width:"100%", padding:20, display:"flex", flexDirection:"column", justifyContent:"center"}}>
                
                <HeadingText fontSize={28} color="black">
                    Raffle In
                </HeadingText>
                {children}
            </div>

            <div style={{ width:"100%", padding:20, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                
                <div>
                    <HeadingText fontSize={28} color="black"> You have</HeadingText>
                </div>

                <div style={{height:50, width:50, borderRadius:10, backgroundColor:"#EEEEEE", margin:20, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    {selectedQuest?.goal?.[0]?.entries ?? 0}
                </div>

                <div>
                    <HeadingText fontSize={28} color="black"> Entries</HeadingText>
                </div>

            </div>

        </div>
    );
}

export default GoalDetailsLayout;