import React, {useState, useEffect} from 'react';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import ScienceRoundedIcon from '@mui/icons-material/ScienceRounded';
import AlarmRoundedIcon from '@mui/icons-material/AlarmRounded';import { useQuestContext } from '../../context/QuestContext';
import MediaQuery from '../common/MediaQuery';
import MyText from "../MyText";
import {getDistance, findNearest} from "geolib";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass } from '@fortawesome/free-solid-svg-icons';
import { useUserLocContext } from '../../context/UserLocContext';


function QuestList({quests, selectQuest}) {

    const isMobile = MediaQuery()
    const {coords} = useUserLocContext()
    
    
    //all, mine (sandbox is badge), ended, prize -> filter could be seperate component for alot of other components
    const [filter, setFilter] = useState("ALL");
    const [filteredQuests, setFilteredQuests] = useState(quests)
    const [search, setSearch] = useState("")
    
    useEffect(()=>{
        filterQuests()
    }, [filter, search])


    function testGetLocation(){
        return new Promise((resolve, reject)=>{
            navigator.geolocation.getCurrentPosition(
                (position)=> resolve(console.log("blah blah", position)),
                (error) => reject(console.log("blah blah error", error)) 
            )
        })
    }

    function testGetLocation2(){
            return new Promise((resolve, reject)=> {
                navigator.geolocation.getCurrentPosition(resolve, reject)
            })
        }
    

    useEffect(()=>{
        testGetLocation().then(()=>console.log("then called"))
    }, [])

    useEffect(()=>{
        testGetLocation2().then((position)=>console.log("blah blah blah x4", position))
    }, [])

    //Sandbox should not appear for non-creators. 
    function filterQuests(){

        const searchFiltered = !search.trim() ? quests : quests?.filter(quest => quest?.quest_name?.toLowerCase()?.indexOf(search.toLowerCase()) != -1 )

        switch(filter){

            case "ACTIVE":
            //return 
            setFilteredQuests(searchFiltered?.filter(quest => quest?.quest_status == "ACT"))
            break;

            case "ENDED": 
            //return 
            setFilteredQuests(searchFiltered?.filter(quest => new Date(quest?.quest_end) < new Date()))
            break;

            case "PRIZE":
            //return 
            setFilteredQuests(searchFiltered?.filter(quest => quest?.goal?.[0]?.entries > 0))
            break;

            case "PLAY":
            //return 
            setFilteredQuests(searchFiltered?.filter(quest => quest?.collected_count > 0 ))
            break;

            default:
            //return 
            setFilteredQuests(searchFiltered)
        }   
    }


    function FilterList(){

        const style = {
            display:"flex", 
            width:"100%",
            justifyContent:"flex-start", 
            padding:10
        }

        return(
            <div style={style}>
                <FilterButton title="all" type="ALL"/>
                <FilterButton title="active" type="ACTIVE"/>
                <FilterButton title="playing" type="PLAY"/>
                <FilterButton title="ended" type="ENDED"/>
            </div>
        )
    }


    function FilterButton({title, type}){

        const style = { 
            display:"flex",
            justifyContent:"center",
            borderRadius: 10,  
            padding:6,
            marginRight:10, 
            backgroundColor: filter === type ? "deeppink" : "white", 
            fontSize:14, 
            fontWeight: "bold", 
            minWidth:"20%"
        }

        return(
            <div style={style} onClick={()=> setFilter(type)}>
                {title}
            </div>
        )
    }

    const searchBarStyle = {
        borderRadius:10, 
        display:"flex",
        width:"60%", 
        borderStyle:"solid", 
        borderWidth:1, 
        borderColor:"grey", 
        marginLeft:10,
        paddingLeft:10, 
        paddingRight:10,
        paddingTop:4, 
        paddingBottom:4
    }

    const filterContainerStyle = {
        display:"flex", 
        flexDirection:"column",
        width:"100%", 
        padding:15,
    }


    function getTokenDistArray(quest){
        //TODO this could be dont for uncollected token at somepoint
        //TODO make a getNearestToken function for seperate use as a Util. 

        if(!coords) return null
        const myLoc = {latitude:coords?.latitude, longitude: coords?.longitude}
        const modifiedTokens = quest?.tokens?.map(token => { return{latitude: token?.token_lat, longitude: token?.token_long}})
        const nearest = findNearest(myLoc, modifiedTokens);
        const distance = Math.floor(getDistance(myLoc, nearest)/1000)
        return distance
    }

    function DistanceChip({quest}){

        const dist = getTokenDistArray(quest)
      
        return(
            <div style={{height:20,  borderRadius:13, backgroundColor:"", display:"flex", justifyContent:"space-evenly", alignItems:"center", margin:5}}>
                <MyText fontSize={13}>{`${dist} km`}</MyText>
                <FontAwesomeIcon icon={faCompass} style={{fontSize:18, color:"gold"}}/>
            </div>
        )
    }

    function RaffleChip({numEntries}){

        return(
            <div style={{height:20, width:55, borderRadius:13, display:"flex", justifyContent:"space-evenly", alignItems:"center", margin:5}}>
                <MyText fontSize={13}>{numEntries ? numEntries : 0}</MyText>
                <LocalActivityIcon style={{fontSize:18, color:"#6600FF"}}/>
            </div>
        )
    }

    function MarkerChip({numCollected, numTotal}){
        console.log("MarkerChip rendered")


        return(
            <div style={{height:20, width:55, borderRadius:13, display:"flex", justifyContent:"space-evenly", alignItems:"center", margin:5}}>
                <MyText fontSize={13}>{`${numCollected}/${numTotal}`}</MyText>
                <RoomRoundedIcon style={{fontSize:18, color:"deeppink"}}/>
            </div>
        )
    }

    function TestModeChip({quest}){
        console.log("TestModeChip rendered")

        return(
            quest?.quest_status === "TEST" ? 
            <div style={testModeChip}>
                <ScienceRoundedIcon style={{fontSize:12}}/>
                <MyText color="grey" fontSize={8}>Test Mode</MyText>
            </div>
            : null
        )
    }

    function EndedChip({quest}){
        console.log("EndChip rendered")

        const now = new Date();
        const ends = new Date(quest?.quest_end)
        const isEnded = (ends - now) < 0 && quest?.quest_status != "TEST"; 
        console.log("isEnded", now, ends)

        return(
            isEnded ? 
            <div style={endedChip}>
                <AlarmRoundedIcon style={{fontSize:10 }}/>
                <MyText color="grey" fontSize={8}>Quest Ended</MyText>
            </div>
            : null
        )
    }


    function QuestListItem({quest}) {
        //TODO move to own function or decouple and move outside main render. 

        return(
            <div 
                style={questCard}
                onClick={()=> selectQuest(quest)}
            >

                <div style={{display:"flex", height:75, width:75, backgroundColor:"#EEEEEE", borderRadius:40, margin:7, justifyContent:"center", alignItems:"center"}}>
                    <img
                        style={{objectFit:"contain", height:"85%", width:"85%", borderRadius:1000 }}
                        src={quest?.quest_image}
                    />
                </div>

                <div style={{display:"flex", height:"100%", width:"75%", flexDirection:"column", justifyContent:"center", padding:4, alignSelf:"flex-start"}}>
                    {quest?.quest_name}
                    <div style={{fontSize:12, color:"grey"}}>{`Quest Ends: ${new Date(quest?.quest_end)?.toLocaleString()}`}</div>

                    <div style={{display:"flex", paddingTop:7}}>
                        <RaffleChip numEntries = {quest?.goal?.[0]?.entries}/>
                        <MarkerChip numCollected={quest?.collected_count} numTotal={quest?.tokens?.length}/>
                        <DistanceChip quest={quest}/>
                    </div>
                        
                    <TestModeChip quest={quest}/>
                    <EndedChip quest={quest}/>
                </div>

            </div>
        )
    }

    return (
        <div style={{display:"flex", flexDirection:"column", width:"100%", alignItems:"center", backgroundColor:"#F9F9F9", borderRadius:30}}>

            <div style={filterContainerStyle}>
                <div style={{display:"flex", width:"100%", justifyContent:"flex-start", alignItems:"center", padding:10}}>
                    Search: <input style={searchBarStyle} type="search" onChange={(e) => setSearch(e.target.value)} value={search}/>
                </div>
                <FilterList/>
            </div>


            <div style={{height:40, width:"100%", display:"flex", alignItems:"center", padding:10, fontWeight:"bold", borderTopLeftRadius: isMobile ? 27 : 0, borderTopRightRadius:27}}>
                <MyText>Nearby Quests</MyText>
            </div>

            <div></div>
            {filteredQuests?.sort((a,b) => getTokenDistArray(a) - getTokenDistArray(b))?.map( quest => (<QuestListItem key={quest?.id} quest={quest}/>))}
        </div>
    );
}

export default QuestList = React.memo(QuestList);

const questCard = {
    height:110, 
    position: "relative",
    width:"90%", 
    borderRadius:20, 
    backgroundColor:"#FDFDFD", 
    margin:5,
    boxShadow:"1px 2px 5px #EEEEEE", 
    display:"flex", 
    alignItems:"center",
    borderStyle:"solid", 
    borderColor:"#EEEEEE"
}

const testModeChip = {
    height:20, 
    padding:5,
    borderRadius:15, 
    backgroundColor:"#EEEEEE", 
    position:"absolute", 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center",
    right:4,
    top:3, 
}

const endedChip = {
    height:20, 
    padding:5, 
    borderRadius:15, 
    backgroundColor:"#EEEEEE", 
    position:"absolute", 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center",
    right:4,
    top:3, 
}