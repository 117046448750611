import axios from "axios";
import { BASE_URL} from "./GeneralVars";

//TODO Delete after testing create flow
//const BASE_URL_PRODUCTION = "https://sidequestapiv4-beta.herokuapp.com"
//const BASE_URL_LOCAL = "https://bab98b44a3b5.ngrok.io"

const authToken = localStorage.getItem("auth_token");

const client = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Authorization": authToken
        },
    timeout: 10000
});

client.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers.Authorization = token;
            console.log("Auth intercepted", token)
        } else {
            
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

export default client; 