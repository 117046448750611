import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Input, DateTimePicker, Textarea, Card, TableWithBrowserPagination, Column, Button, Picklist, Option, ButtonIcon, MenuItem, ButtonGroupPicker, ButtonOption} from 'react-rainbow-components';
import PrizePicker from '../common/PrizePicker';
import LocalActivityRoundedIcon from '@mui/icons-material/LocalActivityRounded';
import AddIcon from '@mui/icons-material/Add';
import _, { set, update } from "lodash";
import  Joi from 'joi-browser';
import httpService from '../../API/Client';
import URL_ROOT from '../URL_ROOT';
import RaffleTimer from '../common/RaffleTimer';
import PrizeTable from '../PrizeTable';
import WinnerTable from '../WinnerTable';

/*
Need a switch statement to render a different component depending on the prize type selected 
or will it...."goal" will always be "goal"
Status will change

NOTES
1. Can pass in a prop to tell component if create mode or review mode for what is rendered.
2. Have awaded etc. as seperate columns if in dshboard...turn off in create mode 
3. Add section for details (not create) i.e. status (not awarded, awardng), awarded, claimed
4. Add info popup to each header item
5. Hide the column headers for awarded etc if create mode.
6. Disable the next button if less than 1 token is selected
*/



const StyledText = styled.div`
color:grey;
font-size:20px;
font-weight:bold;
`;

const Row = styled.div`
display:flex;
flex-direction:row;
align-items: center;
margin:20px;
`;

const ColumnTitle = styled.div`
display:flex;
flex-direction:column;
align-items: flex-start;
flex:1
`;

const ColumnData = styled.div`
display:flex;
flex-direction:column;
align-items: flex-start;
flex:5
`;

const StyledCardTitle = styled.div`
font-size:30px;
font-weight:bold;
`;

const addButton = {
    position: "absolute",
    bottom: "5%", 
    right:"10%"
}

const goalObj = [
    {
        id:"",
        goal_award_style:"RAFF",
        goal_goal_amount:1,
        goal_raffle_date:"", //needs to come from the questDetails data
        goal_complete: false,
        goal_instant_odds:"",
        prize:[]
    }
]

function QuestPrizeLayout({ prizeExpire, setPrizeExpire, questEnd, quest, refreshQuestData, questCreate=false, markers, goal, setGoal, setError}) {
        
    //Set the min prize expire time.  questEnd(create) && quest.quest_end()
    let minDate = new Date(questEnd || quest?.quest_end);
    minDate.setDate(minDate.getDate() + 1)

    const prizeSchema = Joi.array().min(1).items({
        id: Joi.number().allow(""),
        prize_name: Joi.string().min(5).max(30),
        prize_description: Joi.string().allow("").max(50),
        prize_quantity: Joi.number().min(1),
        prize_identifier: Joi.number().allow(""),
        prize_redem_loc: Joi.string().required(),
        prize_redem_style: Joi.string().allow(""),
        prize_expires: Joi.date().required().greater(minDate).label("Prize Expiry"),
        awarded: Joi.number().allow(""),
        claimed: Joi.number().allow(""),
        status: Joi.string().allow(""),
    });
    
    const [prizeWinners, setPrizeWinners]  = useState([]);
    const [showTable, setShowTable] = useState("prizes");
    //const [prizeExpire, setPrizeExpire] = useState(questEnd);

    const [isEditable, setIsEditable] = useState(questCreate ? true : false); //Set editabilty of the prize data on the card. 
    const [origData, setOrigData] = useState([]); //Copy of the original data if save cancelled
    const [isErrors, setIsErrors] = useState([]); //tracks errors for "next" or "save"

    console.log("goal data", goal)

    const getPrizeWinners = async () => {
        console.log("getPrizeWinner")
        
        const response = await httpService("GET",`${URL_ROOT}/api/get_prize_winners/${goal.id}/`, {})
        setPrizeWinners(response.data)
    }

    useEffect(()=>{

        if(questCreate) return //Return if create mode

        if (!goal?.id) return //Return if goal not yet loaded

        if(goal?.goal_complete === true){
            getPrizeWinners()}

    }, [goal?.goal_complete])

    async function handleSave(){
        console.log("handlesave has been called top")

        const goalData = {}
        goalData["id"] = goal?.id
        goalData["goal_award_style"] = goal?.goal_award_style 
        goalData["goal_goal_amount"] = goal?.goal_goal_amount
        //goalData["goal_raffle_date"] = "" This should be updated if the quest_end is updated. 

        const newPrizes = goal?.prize?.filter(prize => prize.id < 1)

        const deletedPrizes = _.difference(origData?.prize?.map(prize => [prize.id]).flat(2), goal?.prize?.map(prize=>[prize.id]).flat(2))        

        const notNew = goal?.prize?.filter(prize => prize.id > 1)
        console.log("notNew", notNew)
        console.log("notNew", origData)

        const updatedPrizes = notNew?.filter(prize => {
            const origIndex = origData?.prize?.findIndex(item => item.id === prize.id)
            const origPrize = origData?.prize?.[origIndex]
            return !_.isEqual(prize, origPrize)
        })

        const data = {
            goal: goalData, 
            newPrizes: newPrizes, 
            deletedPrizes: deletedPrizes, 
            updatedPrizes: updatedPrizes
        }
        const response = await httpService("POST", `${URL_ROOT}/api/update_quest_prizes/`, {data} )
        console.log("prize Reponse", response)
        if(response === "error") return 

        setGoal(response.data)
        setIsEditable(false)
        getPrizeWinners()
    }

    useEffect(()=>{
        if(questCreate){ //Wont get passed in a goal object if in quest create mode. 
          return
        }
        setGoal(quest.goal?.[0])
    }, [quest])

function handleAddPrize(){

    const prizeObj = {
        id: parseFloat(Math.random()),
        prize_name:"", 
        prize_description:"",
        prize_quantity:"",
        prize_identifier:"",
        prize_redem_loc: "",
        prize_redem_style:"",
        prize_expires:prizeExpire,
        status:"" 
    };
    const newData = _.cloneDeep(goal)
    newData?.prize?.unshift(prizeObj)
    console.log("addPrize", newData)
    setGoal(newData)
}

console.log("newGoal", goal)

function handleDeletePrize(event, row){
    const newData = _.cloneDeep(goal);
    const index = _.indexOf(goal?.prize, row)
    newData.prize.splice(index,1)
    setGoal(newData)
}

function handleEditPrize(col,{value, row}){
    console.log("questPrizeLayout", col, value, row)
    const index = _.indexOf(goal.prize, row)
    const newData = _.cloneDeep(goal);
    newData.prize[index][col] = value
    setGoal(newData)
}

const makeOptions = (token, index) => {
        return(
            <Option 
                name={index + 1} 
                label={`${index + 1} Locations per entry`} 
                value={index + 1} 
                icon={<LocalActivityRoundedIcon 
                    style={{color:"grey", marginLeft:10 ,padding:2}}/>}/>
        )
    }

function handleGoalAmountChange({value}){
    const newData = _.cloneDeep(goal)
    newData.goal_goal_amount = value;
    console.log("switch newData", newData)
    setGoal(newData)
}

console.log("goal_goal_amount", goal?.goal_goal_amount)

const stuff = {
    name: goal?.goal_goal_amount ?? 0,
    label:`${goal?.goal_goal_amount ?? 0} Locations`,
    icon:<LocalActivityRoundedIcon style={{color:"grey", marginLeft:10 ,padding:2}}/>}

const GoalPickList = () => {
        
    return(
        <Picklist
            id="numGoal"
            style={{}}
            placeholder="Choose Goal"
            disabled={!isEditable}
            onChange={value => handleGoalAmountChange(value)}
            value={stuff}
            label="Locations to Visit"
            hideLabel
            //enableSearch
        >
            {
                questCreate 
                    ? 
                markers?.map((token, index)=> makeOptions(token, index))
                    :
                quest?.tokens?.map((token, index)=> makeOptions(token, index))
            }
        </Picklist>
    )
}

//Resets the expiry date for all the prizes if prizeExpire input is changed. 
useEffect(()=>{
    const newData = {...goal};
    newData?.prize?.map(prize => prize.prize_expires = prizeExpire)
},[prizeExpire])

function handleIsEditable(){
    const copyData = _.cloneDeep(goal);
    setOrigData(copyData)
    setIsEditable(true)
}

function handleCancelEdit() {
    setGoal(origData)
    setIsEditable(false)
}

const EditButton = () => {
    return(
         !isEditable ?
        <Button 
            label={isEditable === false ? "edit" : "save"}
            variant="outline-brand" 
            onClick={handleIsEditable}
            disabled={goal?.goal_complete}
        />
        :
        <>
        <Button 
            label={"save"}
            disabled={isErrors}
            variant="brand" 
            onClick={()=>handleSave()}
            style={{marginRight:10}}
        />
    
        <Button 
            label={"Cancel"}
            variant="outline-brand" 
            onClick={handleCancelEdit}
        />
        </>
    ) 
}


const RaffleDate = (
    questCreate && goal?.goal_award_style === "RAFF" &&
    <Row>
        <ColumnTitle><StyledText>Winners Drawn:</StyledText></ColumnTitle>
        <ColumnData>
            <DateTimePicker
                type="datetime-local"
                value={(goal?.goal_raffle_date)}
                disabled={true}
                style={{width:300}}
            />
            <div style={{color:"grey", margin:10}}>Winners drawn at the end of the quest</div>
        </ColumnData>
    </Row>
);

const PrizeExpiry = (
    <Row>
        <ColumnTitle><StyledText>Prizes Expire*:</StyledText></ColumnTitle>
        <ColumnData>
            <DateTimePicker
                type="datetime-local"
                value={(prizeExpire)}
                disabled={!isEditable}
                minDate={minDate}
                onChange={(date)=>{setPrizeExpire(date)}}
                style={{width:300}}
            />
        </ColumnData>
    </Row>
);


useEffect(()=>{
    const valOptions ={abortEarly:false};
    const validate = Joi.validate(goal?.prize , prizeSchema, valOptions)
    console.log("goal errors", validate)

    if(questCreate && validate?.error?.details){setError("quest_goal", true)}
    else if(questCreate){setError("quest_goal", false)}

    setIsErrors(validate?.error?.details);
    
}, [goal, prizeExpire])

const prizeHint = () => {
    console.log("newGoal", goal)
    console.log("Goals award style", goal?.goal_award_style)
    switch (goal?.goal_award_style){
        
        case "RAFF":
            return `Pariticpants receive 1 raffle entry for every ${goal?.goal_goal_amount} locations they visit`
        break;
    }
}

function handleTableChange(value){
    console.log("handleTableChange", value)
    setShowTable(value)
}

    return (
        <Card
            title={<StyledCardTitle>Prizes</StyledCardTitle>}
            style={{backgroundColor:"white", width:"70vw", margin:20}}
            actions={!questCreate && <EditButton/>}
            footer={ 
                <div>
                    <Row>
                        <ColumnTitle><StyledText>Prize Type*:</StyledText></ColumnTitle>
                        <ColumnData><PrizePicker goal={goal?.goal_award_style} questCreate={questCreate}/></ColumnData>
                    </Row>

                    <Row>
                        <ColumnTitle><StyledText>Goal*:</StyledText></ColumnTitle>

                        <ColumnData>
                           <GoalPickList quest={quest}/>
                           <div style={{color:"grey", margin:10}}>{prizeHint()}</div>
                        </ColumnData>
                    </Row>

                    { !questCreate &&  
                    <Row>
                        <ColumnTitle><StyledText>Status:</StyledText></ColumnTitle>

                        <ColumnData>
                            <RaffleTimer 
                                endDate={goal?.goal_raffle_date} 
                                goal_complete = {goal?.goal_complete}
                                endCallback={refreshQuestData}
                            />
                        </ColumnData>
                    </Row>
                    }

                    
                    <br></br>

                    {PrizeExpiry}

                    <br/>

                    {RaffleDate}

                    { !questCreate &&
                    <Row>
                        <StyledText>
                            <ButtonGroupPicker
                                //label="Select view type"
                                value={showTable}
                                onChange={(value)=>handleTableChange(value)}
                                name="filter"
                                size="medium"
                                //bottomHelpText="Select one option"
                            >
                                <ButtonOption
                                    label={"Prizes"}
                                    name="prizes"
                                />
                                 <ButtonOption
                                    label={"Winners"}
                                    name="winners"
                                    disabled={!goal?.goal_complete}
                                />
                            </ButtonGroupPicker>
                        </StyledText>
                    </Row>
                    }   

                    { showTable === "prizes" 
                        ? 
                        <PrizeTable goal={goal} isEditable={isEditable} handleEditPrize={handleEditPrize} handleDeletePrize={handleDeletePrize}/>
                        :
                        <WinnerTable prizeWinners={prizeWinners}/>
                    }

                    <br/><br></br><br/>
                    { showTable === "prizes" &&
                    <div style={addButton}>
                        <ButtonIcon 
                            label="addPrize"
                            variant='brand'
                            icon={<AddIcon/>}
                            onClick={handleAddPrize}
                            disabled={!isEditable}
                            />
                    </div>
                    }
                </div>}
        >
        </Card>
    );
}

export default QuestPrizeLayout;