import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function NotAuthChip(props) {

    return (
        <div style={containerStyle}>
            <FontAwesomeIcon icon={faCog} style={{color:"dodgerblue"}}/>
            You need to signup/signin to open a prize.
        </div>
    );
}

export default NotAuthChip;

const containerStyle = {
    display:"flex", 
    alignItems:"center",
    borderRadius: 20, 
    fontSize:12,
}