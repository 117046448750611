import React from 'react';
import { NavLink } from 'react-router-dom';

function NotAuthedLink(props) {

    return (
        <>
            <span style={{textAlign:"center"}}>You neeed to login or signup to access this page</span>
            <NavLink to={"/authenticate"}>Login/Sign-up</NavLink>
        </>
    );
}

export default NotAuthedLink;