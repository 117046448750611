import React, {useState, useRef} from 'react';
import ReactMapGL, {GeolocateControl, Marker, Layer, Source} from "react-map-gl";
import MAPBOX_TOKEN from "../../../components/MAPBOX_TOKEN";
import { tabBarHeight } from '../utils/stdVariables'; //TODO move to a better location once map working
import { useUserLocContext } from '../../../context/UserLocContext';
import useIsMobile from '../utils/useIsMobile';

const SHOW_LABEL_ZOOM = 9.75;

function ReactMap({children}) {

    const mapRef = useRef();
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [mapStyle, setMapStyle] = useState("mapbox://styles/mapbox/bright-v8");//State for the layer filter 
    const [showLabel, setShowLabel] = useState(false);

    const {locationPermission} = useUserLocContext() //Prevents location permission request until granted

    const [viewport, setViewport] = useState({
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 2
      });

    function getZoomLevel(){
        const zoom = mapRef?.current?.getMap()?.getZoom()
        //console.log("getZoomLevel", zoom)
        return zoom
    }

    function handleZoomChange(){

        const zoomLevel = getZoomLevel();
        //console.log("handleZoomChange", zoomLevel)

        if(zoomLevel > SHOW_LABEL_ZOOM && showLabel == false){
            //console.log("showLable >", true)
            setShowLabel(true);
        }
        else if (zoomLevel < SHOW_LABEL_ZOOM && showLabel == true){
            //console.log("showLabel <", false)
            setShowLabel(false);
        }
    }

    return (
        <>
            <ReactMapGL
                width="100vw"
                //height={`calc(100vh - ${tabBarHeight}px)`}
                height={CSS.supports('height: 100svh') ? `calc(100svh - ${tabBarHeight}px)` : `calc(${window?.innerHeight}px - ${tabBarHeight}px)`} //Changed vh to svh to accomodate the URLTODO: Add fallback vh
                mapStyle={mapStyle}                    
                ref={mapRef}
                {...viewport}
                onZoomEnd={handleZoomChange()}
                onViewportChange={nextViewport => setViewport(nextViewport)}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                attributionControl={false} //removed bottom watermark
                //onClick={deselectQuest} //Changed to close button
            >
                {locationPermission === "granted" && 
                    <GeolocateControl 
                        //positionOptions={userPositionStyle}
                        style={userPositionStyle}
                        auto={true}
                        showAccuracyCircle={true}
                        showUserHeading={true}
                        showUserLocation={true}
                        trackUserLocation={true}
                        fitBoundsOptions={{maxZoom:5}}
                        onGeolocate={(position) => {
                            console.log("user position", position.coords.latitude)
                            //setNewLocation([position.coords.latitude, position.coords.longitude]);
                        }}
                    />
                }

                {children}
            </ReactMapGL>
        </>
    );
}

const userPositionStyle = {
    position:"absolute", 
    bottom:-70, //Hide this 
    padding:25
}

export default ReactMap;