import axios from "axios";
import { BASE_URL, ANONYMOUS_TOKEN } from "./GeneralVars";
//This is the base client for pages that allow an annonymous user

//const BASE_URL_PRODUCTION = "https://sidequestapiv4-beta.herokuapp.com"
//const BASE_URL_LOCAL = "https://bab98b44a3b5.ngrok.io"
//const ANONYMOUS_TOKEN = "token 447b4f5766d4fe4ddce9c70a0d452d2db2460d4b" //Local
//const ANONYMOUS_TOKEN = "token 8b4643f9ae6b46e4363cc8f7b870ec388ad1405c" //Production

const authToken = localStorage.getItem("auth_token") ?? ANONYMOUS_TOKEN;

const anonClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Authorization": authToken
        },
    timeout: 10000
});

anonClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers.Authorization = token;
            console.log("Auth intercepted", token)
        } else {
            config.headers.Authorization = ANONYMOUS_TOKEN
      }
      return config;
    },
    (error) => Promise.reject(error),
  );


export default anonClient; 