import React, {createContext, useContext, useEffect, useState, useCallback} from 'react';
import getRandomPrizes from '../../../API/getRandomPrizes'
import { useUserLocContext } from '../../../context/UserLocContext';
import {cloneDeep} from "lodash"
import * as turf from '@turf/turf'
import { setRef } from '@mui/material';


//TODO - Consider moving randomPrize Context to the random prize folder
//TODO - Make sure to useCallback() for context function to prevent unecessary rerenders. 

export const RandomPrizeContext = createContext()

export function useRandomPrizeContext(){
    return useContext(RandomPrizeContext)
}

export function RandomPrizeContextProvider({children}){

    
    const [randomPrizes, setRandomPrizes] = useState(null);
    const [refresh, setRefresh] = useState(false)

    const {userLocation, locationPermission, initialLocation} = useUserLocContext()

    //Calls the API to refresh the data. 
    const refreshRandomPrizes = ()=> setRefresh(!refresh)

    //Deletes the random prize from the data set. 
    const deleteRandomPrize = useCallback(async(prizeID) => {
        
        if(!randomPrizes) return

        const copy = cloneDeep(randomPrizes)
        const index = copy.findIndex(randPrize => randPrize.id == prizeID)
        copy.splice(index,1)
        
        setRandomPrizes(copy)

    }, [randomPrizes])

    /*
    const [selectedQuest, setSelectedQuest] = useState(null) //ID of the selected quest
    const [refreshQuests, setRefreshQuests] = useState(false) //Function call to refresh the quest...not good practice...consider removing 
    const [isUserInside, setIsUserInside] = useState(false) //Check if user is inside one of the quest polygon
    let selectedQuestObj; //Raw quest data for selected quest...can be deleted once unraveled. 
    const [questObj, setQuestObj] = useState({})//Raw data for selected quest...This should be renamed. 
    const [selectedQuestRaw, setSelectedQuestRaw] = useState(null)
    */

    //const {userLocation} = useUserLocContext()

    //useEffect(()=>{
    //    setSelectedQuestRaw(quests?.filter(quest=> quest?.id == selectedQuest?.id)?.[0])
    //}, [selectedQuest])

    //useEffect(()=>{
    //    if(!userLocation)return
    //    if(!userLocation?.isAccurate)return
    //    if(!selectedQuest)return
    //    setIsUserInside(isWithinPolygon([userLocation?.coordinates?.longitude, userLocation?.coordinates?.latitude]))
    //}, [userLocation, selectedQuest])

    /*
    function isWithinPolygon(userLocation){ 
        //Add checks to determine if userLocation and polycoords are valid
        const point = turf.point(userLocation)
        const polyCoords = quests?.filter((quest)=> quest?.id == selectedQuest?.id)?.[0]?.quest_area?.features?.[0]
        const pointsInPolygon = polyCoords && turf.pointsWithinPolygon(point, polyCoords)
        const isUserWithin = pointsInPolygon?.features?.length > 0
        return isUserWithin
    }
    */

    useEffect(()=>{
        if(locationPermission != "granted") return
        if(!initialLocation) return 
        handleGetRandomPrizes() 
    }, [refresh, initialLocation, locationPermission])
    
    async function handleGetRandomPrizes(){
        const result = await getRandomPrizes(userLocation?.simple)
        setRandomPrizes(result?.data)
    }   

    //useEffect(()=>{
    //    selectedQuestObj = quests?.filter(quest=> quest?.id == selectedQuest?.id)
    //}, [selectedQuest, quests])

    //function selectQuest(questID){
    //    setSelectedQuest({id:questID})
    //}


    return(
        <RandomPrizeContext.Provider 
            value={{randomPrizes, refreshRandomPrizes, deleteRandomPrize}}
        >
            {children}
        </RandomPrizeContext.Provider>
    )

} 





