import React from 'react';
import styled from 'styled-components';
import '../App.css'


/*
NOTES:
1. Can use svg tags or a styled component (CSS) to build the wave pattern. 
The <svg> is a far cleaner and better looking option. 
*/

function WaveDivBottom({color}) {

/*
    const Wave = styled.div`
        position: relative;
        height: 70px;
        width: 600px;
        background: grey;
        &:after {
            content: "";
            display: block;
            position: absolute;
            border-radius: 100% 50%;
            width: 300px;
            height: 70px;
            background-color: grey;
            left: 0;
            top: 27px;
          }
        &:before {
            content: "";
            display: block;
            position: absolute;
            border-radius: 100% 50%;
            width: 340px;
            height: 80px;
            background-color: white;
            right: -5px;
            top: 40px;
        }
    
    `;
*/

    return (
        <div style={{height:150, overflow: "hidden",  marginTop:-5 /*Pevents line at interface*/}}>
            <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: "100%", width: "100%"}}>
                <path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z" style={{stroke:"none",fill: color ? color : "#FDE66D", fillRule:"evenodd"}}></path>
            </svg>
      </div>
    );
}

export default WaveDivBottom;