import React, {useState} from 'react';
import '../../App.css'
import styled from 'styled-components';
import PhoneIcon from '../PhoneIcon';
import WaveDivBottom from '../WaveDiv';
import WaveDivTop from '../WaveDivTop';
import Lottie from "../Lottie";
import RadarPink2 from "../../Images/RadarPink2.json";
import CostCard from '../CostCard';
import HowItWorks from '../HowItWorks';
import QuestPicker from '../QuestPicker';
import InspirationCarousel from '../InspirationCarousel';
import MediaQuery from '../common/MediaQuery';
import { Button } from 'react-rainbow-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faMapMarkerAlt, faBomb, faFire, faBolt, faTrafficLight, faArrowRight, faClipboardCheck, faStar, faGlobe, faRoute, faGift } from '@fortawesome/free-solid-svg-icons';
import MAPBOX_TOKEN from '../MAPBOX_TOKEN';
import ReactMapGL, {GeolocateControl, Marker} from "react-map-gl";
import useIsTablet from '../common/useIsTablet';
import { isTablet } from 'react-device-detect';


//This will be the main page of the app.  will look the same as the square space app...bunch of simple components. 
const StyledButton = styled(Button)`
    background: deeppink;
    border-width: 0px;
    color: white;
`;    

function HomeLayoutV2(props) {

    const isMobile = MediaQuery();
    const isTablet = useIsTablet()
    const navigate = useNavigate()

    const [questType, setQuestType] = useState("promotion")

    const Logo = styled.img`
    position: absolute;
    width: 30px;
    margin: 15px;
    transition: all .2s ease-in-out
    &:hover {
        transform: scale(0.8);
    }
    `;

    const styles = {

        mainContainer:{
            display:"flex",
            //height:"100vh",
            width:"100vw",
            overflow:"hidden",
            backgroundColor:"white", 
            flexDirection:"column"
        },

        subContainer:{
            display:"flex",
            width:"100%",
            //height:"65vh",
            backgroundColor:"#FDE66D",
            flexDirection: isMobile ? "column" : "row",
            justifyContent:"center",
            alignItems:"center"
        }, 

        itemContainer:{
            width: isMobile|| isTablet ? "100%" : "35vw",
            justifyContent:"center",
            //margin:60,
            //top:"30vh",
            //left:"10vw",
            color:"#F401D7", 
            display:"flex",
            flexDirection:"column",
            //fontFamily:"Fredoka One", 
        },

        imageContainer:{
            height:"50vh",  
            position:"relative",
            margin:50
        }, 

        test:{
            display:"flex",
            width:"100%",
            //height:"65vh",
            backgroundColor:"black",
            flexDirection: "column",
            justifyContent:"center",
            alignItems:"center",
            padding:10
        },

        makeQuestContainer:{
            display:"flex",
            width:"100%",
            //height:"65vh",
            backgroundColor:"black",
            flexDirection: isMobile ? "column" : "row-reverse",
            justifyContent:"center",
            alignItems:"center", 
        }, 

        details:{
            fontFamily: "Fredoka One"
        }, 

        heading:{
            fontFamily:"Fredoka One", 
            fontSize:40, 
            color:"lightgrey", 
            margin:10, 
            textAlign:"center"
        }, 

        costContainer:{
            display:"flex",
            width:"100%",
            //height:"65vh",
            backgroundColor:"#53D4FF",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",
            paddingBottom:20
        }, 

        headingDark:{
            fontFamily:"Fredoka One", 
            fontWeight:"bolder",
            fontSize:40, 
            color:"black", 
            textAlign:"center", 
            marginBottom:20
        }
    }

    return (
        <div style={styles.mainContainer}>
            <div style={styles.subContainer}>

                <div style={styles.itemContainer}>
                    <h2 style={{textAlign:"center", fontSize:40, margin:10, fontFamily:"Fredoka One"}}>Real world mini-games with real world prizes</h2>
                    <br/><br/>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around", width:"100%"}}>
                        
                        <StyledButton 
                            variant="success"
                            onClick={()=> navigate("/play")}
                            style={{backroundColor:"deeppink"}}
                        >
                            Play
                        </StyledButton>

                        {/*<StyledButton 
                            variant="success"
                            onClick={()=> navigate("/create")}
                        >
                            Create
                        </StyledButton>*/}

                    </div>
                </div>

                <div style={styles.imageContainer}>
                    <PhoneIcon/>
                </div>

            </div>

            <div style={{backgroundColor:"#53D4FF"}}><WaveDivTop fillColor="#FDE66D"/></div>

            <div style={styles.costContainer}>
                <div style={styles.headingDark}> How it Works</div><br/><br/>
                <div style={{display:"flex", flexDirection: isMobile ? "column" : "row", justifyContent:"space-evenly", width:"100%", alignItems: isMobile ? "center" : "flex-start"}}>
                    
                    <div style={{display:"flex", flexDirection:"column", width: isMobile ? "60vw" : "20vw", alignItems:"center", textAlign:"center"}}>
                        <FontAwesomeIcon icon={faRoute} style={{fontSize:60, color:"deeppink"}}/>
                        <div style={{fontWeight:"bolder", fontSize:20}}>Find a nearby quest</div>
                        <br/>
                        <div>Quests are real world mini-games, so you need to be nearby to participate.</div>
                        <br/>
                        <div>Visit the sideQuest map to look for nearby quests.</div>
                    </div>
                    
                    <br/>

                    <div style={{display:"flex", flexDirection:"column", width: isMobile ? "60vw" : "20vw", alignItems:"center", textAlign:"center"}}>
                        <FontAwesomeIcon icon={faMapMarkerAlt} style={{fontSize:60, color:"deeppink"}}/>
                        <div style={{fontWeight:"bolder", fontSize:20}}>Visit the Quest Location(s)</div>
                        <br/>
                        <div>Go to the physical quest locations with sideQuest to participate! </div>
                    </div>

                    <br/>

                    <div style={{display:"flex", flexDirection:"column", width: isMobile ? "60vw" : "20vw", alignItems:"center", textAlign:"center"}}>
                        <FontAwesomeIcon icon={faGift} style={{fontSize:60, color:"deeppink"}}/>
                        <div style={{fontWeight:"bolder", fontSize:20}}>Win prizes</div>
                        <br/>
                        <div>Complete the quest for a chance to win real world prizes from raffles or instant wins. </div>
                    </div>
 
                </div>
            </div>

            <div style={{backgroundColor:"white"}}><WaveDivBottom color={"#53D4FF"}/></div>
                    
            <div style={{display:"flex", width:"100%"}}>

                <PlayBox navigate={navigate} isMobile={isMobile}/>

            </div>


            <div style={{backgroundColor:"black"}}><WaveDivTop fillColor="white"/></div>
            <div style={styles.test}>
            <div style={{color:"deeppink", fontSize:40, fontWeight:"bolder", fontFamily:"Fredoka One",  textAlign: "center"}}>Create your own Quest</div>
            {/*<StyledButton onClick={()=> navigate("/create")}>Create a Quest</StyledButton> <br/>*/}
            <div style={styles.makeQuestContainer}>
                    
                <div style={styles.itemContainer}> 
                    {/*<div style={styles.heading}> Create your own location based mini games to bring in foot traffic and engagement</div>*/}
                    
                    <div style={{display:"flex", alignItems:"center", backgroundColor:"", flexDirection:"row"}}>
                        <div style={{display:"flex", minWidth:"20%", justifyContent:"center"}}><FontAwesomeIcon icon={faStar} style={{fontSize:70, padding:5}}/></div>
                        <div style={{display:"flex", justifyContent:"center", flexDirection:"column", fontSize:20}}>
                            High Engagement
                            <div style={{fontSize:14, fontWeight:"normal", color:"white"}}>
                                Interactive mini-games drive more engagement and loyalty than traditional marketing methods.  
                            </div>
                        </div>
                    </div>

                    <br/>

                    <div style={{display:"flex", alignItems:"center", backgroundColor:"", flexDirection:"row"}}>
                        <div style={{display:"flex", minWidth:"20%", justifyContent:"center"}}><FontAwesomeIcon icon={faClipboardCheck} style={{fontSize:80, padding:5}}/></div>
                        <div style={{display:"flex", justifyContent:"center", flexDirection:"column", fontSize:20}}>
                            We take care of the boring admin stuff
                            <div style={{fontSize:14, fontWeight:"normal", color:"white"}}>
                                We track the participants, select the winners, send them the QR codes for the prizes,  etc.... 
                            </div>
                        </div>
                    </div>

                    <br/>

                    <div style={{display:"flex", alignItems:"center", flexDirection:"row"}}>
                        <div style={{display:"flex", minWidth:"20%", justifyContent:"center"}}><FontAwesomeIcon icon={faGlobe} style={{fontSize:80, padding:5}}/></div>
                        <div style={{display:"flex", justifyContent:"center", flexDirection:"column", fontSize:20}}>
                            No Downloads
                            <div style={{fontSize:14, fontWeight:"normal", color:"white"}}>
                                We're a web application, so now downloads necessary.  Participants just need to visit www.sidequestapp.ca/play to participate.
                            </div>
                        </div>
                    </div>
                
                </div>

                <div style={styles.itemContainer}>
                    <Lottie animation={RadarPink2} divID="radar"/>
                </div>
            </div> 
            </div>

            <div>
                {/*<HowItWorks/>*/}
                <CreateCard questType={questType} setQuestType={setQuestType} isMobile={isMobile}/>
            </div>

            
            {/*<QuestPicker/> //Hidden until the other quests are added*/}
        
            <div style={{display:"flex", width:"100%"}}>

                {/*<PlayBox navigate={navigate}/>*/}

            </div>

            <div style={{background:"#53D4FF"}}><WaveDivTop fillColor="black"/></div>

            <div>

                {/*<InspirationCarousel/>*/}

            </div>
                    
                {/*This is the "Make your own Quest Section...Still not sure what sideQuest does try out sandbox?.......This should have the prices and test mode... - I Like the Pickers to select select something to expand on" ...What it is...how it works....create a quest..examples(switch for quest type)
                May this should be the "need some ideas" page ........This is the "Play some nearby quests Section"........Could also have a section for what quest types we offer..
                transition background to grey for the "create section....This should ideally be a caroussel */}
                
        </div>
    );
}

export default HomeLayoutV2;


function CreateCard({questType, setQuestType, isMobile}){

    const container = {
        display:"flex", 
        width:"100%",
        backgroundColor:"black",
        padding:10
    }

    const box = {
        display:"flex",
        flexDirection: isMobile || isTablet ? "column" : "row",
        width:"100%", 
        justifyContent:"flex-start",
        alignItems: "center", 
        borderRadius: 10,
        padding:15
    }

    const leftColumn = {
        display:"flex", 
        justifyContent:"space-around",
        flexWrap: "wrap",
        width:isMobile || isTablet ? "90%" : "20%", 
    }

    const rightColumn = {
        display: "flex", 
        flexDirection:"column",
        justifyContent: "flex-start", 
        padding:20,
        width: isMobile || isTablet ? "100%" : "60%", 
    }

    const questBox = {
        display: "flex",
        flexDirection:"column",
        justifyContent:"center", 
        alignItems:"center",
        fontSize:10,
        borderRadius: 5, 
        margin:10,
        minWidth: isMobile || isTablet ? "20vw" : "8vw",
        height:"10vh",
        backgroundColor:"white"
    }

    const headingText = {
        fontSize: 24, 
        fontWeight:"bolder", 
        color:"white"
    }

    const descText = {
        fontSize: 18, 
        color:"white"
    }

    const exampleText = {
        fontSize: 18, 
        color: "white"
    }

    const bulletPoint = {

    }


    function ExampleArrow({text}){

        return(
            <div style={{color:"white", paddingBottom:10}}>
                <FontAwesomeIcon icon={faArrowRight} color={"deeppink"}/>
                {text}
            </div>
        )
    }

    let text = {};
    switch(questType){

        case "multi-location":
            text.heading = "Trail Quest" 
            text.description = "Drive engagment for your event by providing prizes for participant that visit the various event locations."
            text.examples = [
                " Various artists are displaying their creations downtown during art week.   Visit 10 of the 20 exhibits to be entered in a raffle for $100 gift card.", 
            ]
            break;
        
        case "promotion":
            text.heading = "Promotion Quest" 
            text.description = "Kickstart your sale or grand opening by entering anyone that visits your shop in to a raffle for company swag."
            text.examples = [
                " Visit Sports Coop during our spring sale to be entered to win an awesome hiking backpack. ", 
                " Everyone that visits Cold Creek Icecream during the opening week will be entered to win a $100 giftcard.",
                " July is customer appreciation month at Star Moola Coffee! Everyone that visits the shop in July will be entered to win one of 100 free coffees."
            ]
            break;

        case "recharge":
            text.heading = "Recharge Quest" 
            text.description = "Coming Soon!"
            text.examples = []
            break;

        case "flash":
            text.heading = "Flash Quest" 
            text.description = "Coming Soon!"
            text.examples = []
            break;

        case "trigger":
            text.heading = "Trigger Quest" 
            text.description = "Coming Soon!"
            text.examples = []
            break;

        case "traffic":
            text.heading = "Traffic Quest" 
            text.description = "Coming Soon!"
            text.examples = []
            break;
    }

    return(
        <div style={container}>
            <div style={box}>
                
                <div style={leftColumn}>
                    <div style={questBox} onClick={()=> setQuestType("multi-location")}>
                        <FontAwesomeIcon icon={faMapMarkedAlt} size={"3x"} color={questType == "multi-location" && "deeppink"}/>
                        Multi-Location
                    </div>

                    <div style={{...questBox}} onClick={()=> setQuestType("promotion")}>
                        <FontAwesomeIcon icon={faMapMarkerAlt} size={"3x"} color={questType == "promotion" && "deeppink"}/>
                        Promotion
                    </div>

                    <div style={{...questBox, backgroundColor:"#DDDDDD"}} onClick={()=> setQuestType("recharge")}> 
                        <FontAwesomeIcon icon={faBolt} size={"3x"}/>
                        Recharge
                    </div>

                    <div style={{...questBox, backgroundColor:"#DDDDDD"}} onClick={()=> setQuestType("flash")}>
                        <FontAwesomeIcon icon={faFire} size={"3x"}/>
                        Flash
                    </div>

                    <div style={{...questBox, backgroundColor:"#DDDDDD"}} onClick={()=> setQuestType("trigger")}>
                        <FontAwesomeIcon icon={faBomb} size={"3x"}/>
                        Trigger
                    </div>

                    <div style={{...questBox, backgroundColor:"#DDDDDD"}} onClick={()=> setQuestType("traffic")}>
                        <FontAwesomeIcon icon={faTrafficLight} size={"3x"}/>
                        Traffic
                    </div>

                </div>

                <div style={rightColumn}>

                    <div>
                        <div style={headingText}>{text.heading}</div>

                        <div style={descText}>{text.description}</div>
                    </div>

                    <br/><br/>

                    {text?.examples?.map(example => <ExampleArrow text={example}/>)}
                </div>

            </div>
        </div>
    )
}


function PlayBox({navigate, isMobile}){

    const mainContainer = {
        display:"flex", 
        borderRadius: 20,
        width:"100%", 
        flexDirection: isMobile ? "column" : "row",
        alignItems:"center", 
        justifyContent:"center",
        //height:"30vh",
        //fontFamily:"Fredoka One", 
        //backgroundColor:"purple", 
        padding:40
    }

    const writeupContainer = {
        display:"flex",
        padding:10,
        flexDirection:"column",
        justifyContent:"center",
        width:isMobile ? "100%" : "40%", 
        //backgroundColor:"grey"
    }

    const mapContainer = {
        display:"flex", 
        width: isMobile ? "100%" : "60%", 
        //backgroundColor:"red"
    }

    const headerText = {
        fontSize:30, 
        fontWeight:"bolder"
    }

    const descriptionText = {
        fontSize:20, 
        fontWeight:"normal", 
        color:"grey"
    }

    return(

        <div style={mainContainer}>
            
            <div style={writeupContainer}>
                <div style={headerText}>Play sideQuest!</div>
                <div style={descriptionText}>No download necessary.  Just visit the live map to see what quests are in your area!</div>
                <br/>
                <div style={{color:"deeppink"}} onClick={()=> navigate('/play')}>
                    Visit Map 
                    <FontAwesomeIcon icon={faArrowRight} style={{fontSize:15, marginLeft:10, color:"deeppink"}}/>
                </div>
            </div>

            <div style={mapContainer}>
                <ReactMapGL
                    width="100%"
                    height='40vh' //TODO: Accomodat navbar...set fixed navbar height
                    mapStyle={"mapbox://styles/mapbox/bright-v8"}                    
                    //ref={mapRef}
                    //{...viewport}
                    //onZoomEnd={handleZoomChange()}
                    //onViewportChange={nextViewport => setViewport(nextViewport)}
                    mapboxApiAccessToken={MAPBOX_TOKEN}
                    attributionControl={false} //removed bottom watermark
                    //onClick={deselectQuest} //Changed to close button
                >
                
                </ReactMapGL>
            </div>


        </div>
    )
}