import BaseClient from "./BaseClient";

const URL = "/api/sign_in/"

async function signinUser(authData) {

    let obj = {};

    try{
        
        const response = await BaseClient.post(URL, authData)

        if(response.data){
            obj.status="ok"
            obj.data = response.data
        }

        return obj
    }

    catch(error){
        
        if(error.response){ 
            obj.status="Error"
            obj.data="invalid email or password"
        }

        else if(error.request){ 
            obj.status="Error"
            obj.data="There is a problem with the server.  Please try again later"
        }

        return obj
    }
}

export default signinUser;