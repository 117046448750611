import React, {createContext, useContext, useEffect, useState} from 'react';

export const AuthContext = createContext()

export function useAuthContext(){
    return useContext(AuthContext)
}

//This should house all of the auth methods so that they can be called from context
function AuthContextProvider({children}) {
    
    const emptyAuthObj = {
        authenticated: false,
        userName:"",
        authToken:"",
        isStaff:"", 
        group:""
    }

    const [authObj, setAuthObj] = useState(emptyAuthObj);
    const [isAuthed, setIsAuthed] = useState(false)
    //Shoud have a more specific name for the sideQuest auth. 
    //Add group for creater/player and for player 

    useEffect(()=>{
        if(authObj?.authenticated == false){setIsAuthed(false);return}
        if(!authObj) {setIsAuthed(false);return}
        setIsAuthed(true)
    }, [authObj])

    useEffect(()=>{
        refreshAuth()
    }, [])


    function refreshAuth(){

        const authToken = localStorage.getItem("auth_token");
        const userName = localStorage.getItem("user_name");
        const isStaff = localStorage.getItem("is_staff")

        console.log("authContext", authToken, userName, isStaff)

        if(authToken){
            setAuthObj({...authObj, authToken:authToken, authenticated:true, userName:userName, isStaff:isStaff})
            return
        }

        else{
            setAuthObj({...authObj, authenticated:false}) 
        }
    }

    function setAuthenticated(){
        const newData = {...authObj, authenticated:true};
        setAuthObj(newData)
    }

    function logOut(){
        localStorage.removeItem("auth_token")
        localStorage.removeItem("is_staff")
        localStorage.removeItem("user_name")
        setAuthObj(emptyAuthObj)
    }

    return (
        <AuthContext.Provider value={{authObj, setAuthenticated, refreshAuth, logOut, isAuthed}}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;