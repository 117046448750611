import React from 'react';
import {Marker} from "react-map-gl";
import MapDefault from "../Images/MapDefault.png";
import {ReactComponent as MapMarkerSVG2} from "../Images/MapMarkerSVG2.svg";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import styled from "styled-components"; 


const CollectBadge = styled.div`
    position: absolute; 
    height:  10px; 
    width: 10px; 
    border-radius:10px;
    background: deeppink; 
    top: -19px; 
    right: -16px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    transform: scale(1.0);
    transition: all 0.4s ease;
`;

const imageContainer = {
    height: 10, 
    width: 10, 
    borderRadius: 15, 
    backgroundColor:"white",
    position:"absolute", 
    top:-10.5, 
    left: -2,
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center",
}

const imageStyle = {
    height: 12, 
    width: 12,  
    borderRadius:10,
    objectFit:"contain",
    top:-10,
}


function PlayMapMarkerV3NotSelected({ tokens, handleTap}) {
    
    console.log("marker rerendered")
    const SIZE = 35; 

    return (

        tokens?.map( token => {

            const tokenData = {
                token_id: token?.token_id, 
                quest_id: token?.quest_id,
                token_radius: token?.token_radius,
                token_lat: token?.token_lat,
                token_long: token?.token_long, 
                token_collected_count: token?.token_collected_count
            }

            return (
                
                <Marker
                    latitude={token.token_lat}
                    longitude={token.token_long}
                    key={token.token_id}
                    onClick={() => handleTap(tokenData)}
                >
                    <div style={{height:0, width:0}}>
                        
                        <MapMarkerSVG2 viewBox='0 0 80 80' height={SIZE} style={{cursor:"pointer", transform: `translate(${-SIZE / 2}px,${-SIZE}px)`, position:"relative", top:15, left:6 }}>
                        </MapMarkerSVG2>

                            <div style={imageContainer}>
                                    <img
                                        style={imageStyle}
                                        src={token?.quest_image ? token?.quest_image : MapDefault}
                                    />
                            </div>

                            {token?.token_collected_count > 0 &&
                            <CollectBadge>
                                <CheckRoundedIcon style={{ fontSize:7, color:"white"}}/>
                            </CollectBadge>}
                        
                    </div>
                        
                </Marker>
            )
        })
        
    );
}

export default React.memo(PlayMapMarkerV3NotSelected);

