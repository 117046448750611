import React, {useState} from 'react';
import AreaQuestPrizeList from '../AreaQuestPrizeList';
import { QuestContext, useQuestContext } from '../../../../context/questContext';
import GeneralHintText from '../hints/GeneralHintText';
import GeneralScratcher from '../scratcher/GeneralScratcher';
import openGeneralPrize from '../../../../api/openGeneralPrize';
import ErrorComponent from '../scratcher/ErrorComponent';
import GeneralRules from '../rules/GeneralRules';
import { useEffect } from 'react';
import { useUserLocContext } from '../../../../../../context/UserLocContext';
import { useWonPrizeContext } from '../../../../context/wonPrizeContext';
import { useAuthContext } from '../../../../../../context/AuthContext';


function GeneralQuestModal({showRules, isLoading, setIsLoading, apiErrorCode, setApiErrorCode}) {

    const {updateQuest, selectedQuestRaw, selectedQuestModified, isUserInside} = useQuestContext() //Update this.
    const isAlreadyWon = selectedQuestModified?.winningEntrees?.length > 0//Maybe this doesnt need to be here 
    const {userLocation} = useUserLocContext() //Check accuracy before allowing api call
    
    //TODO: This should be centralized somewhere
    const { refreshWonPrizes } = useWonPrizeContext() //Update the prizes list after opening a prize. 
    const {locationPermission} = useUserLocContext()
    const {isAuthed} = useAuthContext()
    
    /*const isEnable = () => {
        if(locationPermission != "granted") return false
        else if(!isAuthed) return false
        else if(isUserInside) return true
        else return false
    }*/

    const isEnabled = 
        locationPermission != "granted" ? false : 
        !isAuthed ? false : 
        isUserInside ? true : false

    async function handleOpenPrize(){
        setIsLoading(true)
        const response = await openGeneralPrize(selectedQuestModified?.id, userLocation?.simple)
        console.log("handleOpenPrize", response)

        if(response.status != 200 || !response?.status) {
            setIsLoading(false) 
            if(response.status == 406) setApiErrorCode(response?.data?.code)
            else setApiErrorCode(99) //Render generic error component
            return
        } 
        
        if(response?.data?.prize) refreshWonPrizes()
        const copy = {...selectedQuestRaw}
        copy.goal[0].entrants.push(response.data)
        updateQuest(copy)
        setIsLoading(false)
    }

    return ( 
        //TODO Change true back to showRules
        showRules ? <GeneralRules/> :
        <>
            <div style={{marginBottom:45}}></div>

            <div style={titleStyle}>{selectedQuestModified?.name}</div>

            <div style={descriptionStyle}>{selectedQuestModified?.description}</div>
            
            <AreaQuestPrizeList prizes={selectedQuestModified?.prizes}/>
            
            <div style={{marginBottom:15}}></div>

            {apiErrorCode ? 
            <ErrorComponent apiErrorCode={apiErrorCode}/> : 
            <GeneralScratcher 
                apiCall={handleOpenPrize} 
                isLoading={isLoading} 
                isAlreadyWon={isAlreadyWon} 
                isEnabled={isEnabled}
            />
            }
            <GeneralHintText isAlreadyWon={isAlreadyWon} isUserInside={isUserInside}/>
        </>
    );
}

const descriptionStyle = {
    display:"flex", 
    justifyContent:"center", 
    width:"90%", 
    textAlign:"center", 
    fontSize:12, 
}

const titleStyle = {
    fontSize:16, 
    fontWeight: "bold"
}

export default GeneralQuestModal;


function InfoModal(){

    return(
        <div>
            This is some placeholder info
        </div>
    )
}