import React from 'react';

function GeneralHintText({isUserInside, isAlreadyWon}) {

    const textStyle = {fontSize:10}
    
    if (isAlreadyWon) return <span style={textStyle}>You can only win once</span>
    else if(!isUserInside) return <span style={textStyle}>Get inside one of the quest area(s)!</span>
    else return <></>
}

export default GeneralHintText;