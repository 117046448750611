//********LOCAL
//export const BASE_URL = "https://bbd25114d17f.ngrok.app"//Local
//export const ANONYMOUS_TOKEN = "token 1cf0972c6db5c99a890031a74bd42b4fbd30cfc6" //Local
//export const HOME_PAGE = "https://b1d38dbe9649.ngrok.io/play"


//**********SANDBOX
//export const BASE_URL = "https://sidequestapi-sandbox.herokuapp.com" //Production
//export const ANONYMOUS_TOKEN = "token 1cf0972c6db5c99a890031a74bd42b4fbd30cfc6" //Production
//export const HOME_PAGE = "https://sidequest-webapp-sandbox.herokuapp.com/create"

//*********PRODUCTION
export const BASE_URL = "https://sidequestapiv4-beta.herokuapp.com" //Production
export const ANONYMOUS_TOKEN = "token 8378c8849cc7e26494427b31a5f779f0b3759110" //Production
export const HOME_PAGE = "https://sidequestapp.ca/create"
//***********/
