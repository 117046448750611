import React, {useState} from 'react';
import styled from "styled-components";
import CloseButton from '../common/CloseButton';

//This is hacky solution to translate 200% to get the details modal off the screen.  Fix later. 
//Try dismounting this component once closed...but still use memo to memoroize the componmemts
//    height: ${props => props.maxHeight || "100%"};



const Drawer = styled.div`
    height: ${props => props.maxHeight || "100%"}
    width: 100%;
    background: #f9f9f9;
    display:flex;
    flex-direction:column;
    border-radius: 20px;
    position: absolute;
    bottom: 165px;
    transform: ${ props => !props.isOpen ? ("translateY(180%)") : ( props.isFullHeight ? ("translateY(100%)", "translateY(165px)") : ("translateY(93%)") )};
    transition: transform 0.7s ease;
    z-index: ${ props => props.zInd};
`;

function MyQuestDetailsDrawer({isOpen, maxHeight, shrink, close, body, header, zIndex}) {

    console.log("new BottomSheet rendered")
    const [isFullHeight, setIsFullHeight] = useState(false)

    function handleClose(){
        setIsFullHeight(false)
        close()
    }

    return (
            <Drawer isOpen={isOpen} isFullHeight={isFullHeight} maxHeight={maxHeight} shrink={shrink} zInd={zIndex}>
                <CloseButton close={handleClose}/>
                <div style={headerStyle} onClick={()=>setIsFullHeight(!isFullHeight)}>
                    {
                        shrink && <div style={testButton}></div>
                    }
                </div>
                <div style={bodyStyle}>
                    <div onClick={()=>setIsFullHeight(!isFullHeight)}>
                        {header}
                    </div>
                    {body}
                </div>
            </Drawer>
    );
}

export default React.memo(MyQuestDetailsDrawer);

const testButton = {
    height: 5, 
    width: "25%",
    justifySelf:"center",
    borderRadius:30,
    backgroundColor:"#CCCCCC", 
    margin:15,
    zIndex:5
}

const headerStyle = {
    display:"flex",
    height:20,
    justifyContent:"center",
    alignItems:"center",
    borderRadius:20,
    width:"100%", 
    position:"sticky", 
    top:0
}

const bodyStyle = {
    height: `calc(100%)`,
    width:"100%", 
    position:"relative",
    backgroundColor:"#f9f9f9", 
    overflowY:"scroll", 
    overflowX:"hidden"
}