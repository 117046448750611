import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import styled, {keyframes} from "styled-components";
import MediaQuery from '../../common/MediaQuery';
import MyText from '../../MyText';
import CloseButton from '../common/CloseButton';
import RedeemListItemV3 from './RedeemListItemV3';
import getWonPrizesApi from '../../../API/GetWonPrizesApi';
import TestPrize from './TestPrize';


const scaleAnimation = keyframes`
        
    0%{transform: scale(0.1); opacity:0.1}
    100%{transform: scale(1.0); opacity:1.0}
`;

const MobileModal = styled.div`
    height: 96vh; 
    width: 96vw; 
    background: #f9f9f9;
    border-radius:20px; 
    display: flex;
    flex-direction: column;
    position: fixed; 
    overflow: hidden;
    z-index:1000;
    animation: ${scaleAnimation} ease;
    animation-duration: 0.4s ;
    animation-iteration-count: 1;
    overflow-y: scroll; 
    &::-webkit-scrollbar {
        width: 0px;
        border: 0px white;
    }
`;

function PrizeModal({show, close}) {

    const [prizeArray, setPrizeArray] = useState(null)

    useEffect(()=>{
        if(show === false) return
       getData()
    },[show])

    async function getData(){
        const response = await getWonPrizesApi();

        if(response === "Error") return
        console.log("getData", response)
        setPrizeArray(response)
    }

    console.log("PrizeModal Rendered")
    const isMobile = MediaQuery()

    const ScreenModal = styled.div`
        height: 80vh; 
        width: 30vw; 
        border-radius:20px; 
        display: flex;
        flex-direction: column;
        position: fixed; 
        top:50%; 
        left:50%; 
        transform: translate(-50%, -50%); 
        z-index:1000;
        overflow-y: scroll; 
        padding:10px;
        &::-webkit-scrollbar {
            width: 0px;
            border: 0px white;
        }
    `;    

    function RedeemLayout(){
        return(
            <div style={{height:"100%", width:"100%", position:"relative"}}>
                <CloseButton close={close}/>

                <div style={header}>
                    <MyText>Redeem Your Prizes</MyText>
                </div>

                <div style={body}>
                    {prizeArray?.map(prize => <RedeemListItemV3 prize={prize}/>)}
                </div>

            </div>
        )
    }

    return (
       show ? ReactDOM.createPortal(
            <>
                <div style={modalWrapper}>
                    {//isMobile 
                        //? 
                        //<MobileModal show={show}>
                            //{/*<div style={{position:"relative"}}>*/}
                                //{/*RedeemLayout()*/}
                                <TestPrize prizeArray={prizeArray} close={close}/>
                                //</div>{/*children*/}
                            //{/*</div>*/}
                        //</MobileModal>
                        /*:
                        <ScreenModal>
                            <div>
                                {console.log("ScreenModal")}
                                <div style={{position:"relative"}}>
                                    <RedeemListItemV3/>
                                </div>
                            </div>
                        </ScreenModal>*/
                    }
                </div>
            </>, 
            //document.getElementById("portal")
            //document.getElementById("root")
            document.body
          ) : null
    );
}

export default React.memo(PrizeModal);


const modalWrapper = {
    position: "fixed", 
    top:0, 
    bottom:0, 
    left:0, 
    right:0, 
    backgroundColor:"rgb(50,50,50,0.5)",
    zIndex:1000, 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center"
}

const header = {
    height:"9%", 
    width:"100%", 
    display:"flex", 
    alignItems:"center", 
    zIndex:1000, 
    padding:20
}

const body = {
    paddingBottom:20
}



