import React, {memo} from 'react';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import styled from "styled-components";
import MyText from '../../MyText';

const Container = styled.div`
    height:50px; 
    width:50px; 
    border-radius:30px; 
    box-shadow: 2px 3px 5px grey;
    background: #d8d8d8; 
    display:flex; 
    justify-content: center; 
    align-items: center; 
    border-width:5px; 
    border-color: white; 
    border-style: solid; 
    position: absolute; 
    top:20%; 
    right:5%;
    transition-duration: .2s;
    &:active{
        background:deeppink;
        transform: scale(0.6)
    }
`;

function HamburgerButton({click}) {

    return (
        <Container onClick={click}>
            <MenuRoundedIcon style={{color:"black"}}/>
            <div style={titleStyle}>
                <MyText fontSize={10} color="black" fontWeight="bold">Quests</MyText>
            </div>
        </Container>
    );
}

export default memo(HamburgerButton);

const titleStyle = {
    display:"flex", 
    borderRadius:100, 
    backgroundColor:"white", 
    position:"absolute",
    padding:3,
    boxShadow: "2px 3px 5px grey",
    top:35, 
}
