import React from 'react';
import AllPrizesGivenOut from './AllPrizesGivenOut';
import NotRecharged from './NotRecharged';
import PrizeAPIError from './PrizeAPIError';
import UserHasAlreadyWon from './UserHasAlreadyWon';

function ErrorComponent({apiErrorCode}) {

    if(apiErrorCode == 1) return <UserHasAlreadyWon/>
    else if(apiErrorCode == 2) return <AllPrizesGivenOut/>
    else if(apiErrorCode == 3) return <NotRecharged/>
    else return <PrizeAPIError/> //Set code to 4 to trigger this component
}

export default ErrorComponent;