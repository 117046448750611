import React, {useState} from 'react';
import styled from "styled-components";
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import MapDefault from "../../Images/MapDefault.png";
import RaffleTimer from '../common/RaffleTimer';
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import { useQuestContext } from '../../context/QuestContext';
import MediaQuery from '../common/MediaQuery';
import MyText from '../MyText';
import GoalDetailsLayout from './common/GoalDetailsLayout';
import PrizeListItem from './common/PrizeListItem';
import LottieWinAnimationV2 from './LottieWinAnimationV2';
import DetailsCard from './common/DetailsCard';
import ScienceRoundedIcon from '@mui/icons-material/ScienceRounded';

const PrizeContainer = styled.div`
    display: flex; 
    width: 100%;
    height: 115px;
    background: #F9F9F9;
    overflow-x: auto;
    margin-top:20
    ::-webkit-scrollbar {
        display: none;
      }
`;

function QuestDetailsLayout({navigateTo}){

    const {selectedQuest, quests, tokens} = useQuestContext()//Subscribe to tokens so location card updates state with token collect
    const [showGoalDet, setShowGoalDet] = useState(false);
    const [isCheckWinOpen, setIsCheckWinOpen] = useState(false);
    const {deselectQuest} = useQuestContext();
    const image = selectedQuest?.quest_image ? selectedQuest?.quest_image : MapDefault;
    const isMobile = MediaQuery() //change some of the layout based on mobile vs desktop


    console.log("QuestDetails Rendered", selectedQuest)

    //COMPONENT SWITCH STATE AND METHODS: 
    const [comp, setComp] = useState("main");

    switch(comp){

        case "main":
        break;

        case "goal":
        break;

        case "locations":
        break;

        case "prizes":
        break;
    }
    
    const timer = <RaffleTimer endDate={selectedQuest?.quest_end} subTitle={false}/>

    const isRedeemable = selectedQuest?.goal?.[0]?.entries > 0 && selectedQuest?.goal?.[0]?.goal_complete

    function setFill(){
        const collectedToken = selectedQuest?.collected_count
        const totalTokens = selectedQuest?.tokens?.length
        const required = selectedQuest?.goal?.[0]?.goal_goal_amount
        const percentage = (collectedToken % required)*100
        return percentage
    } 

    const guageFill = {
        display:"flex", 
        height:10, 
        width: `${setFill()}%`, 
        backgroundColor:"deeppink", 
        borderRadius:10, 
    }

    function globeFill() {
        const collectedTokens = selectedQuest?.collected_count;
        const required = selectedQuest?.goal?.[0]?.goal_goal_amount;
        const percentage = (collectedTokens % required)*100;

        if(collectedTokens > 0 && percentage == 100 || percentage == 0 ){
            return "deeppink"
        } else return "#EEEEEE"
    }

    const headerStyle = {
        display:"flex",
        height:"15%",
        width:"100%",
        marginBottom:-0.5,
        display:"flex",
        alignItems:"center",
        padding:15,
        borderTopLeftRadius: isMobile ? 0 : 0,
        borderTopRightRadius:27,
    }

    const checkPrizeButton = {
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",  
        position:"relative", 
        right:0, 
        top:0, 
        borderRadius:10, 
        padding:3, 
        marginTop:15, 
        width:"40%", 
        display:"flex", 
        backgroundColor: isRedeemable ? "gold" : "#EEEEEE"
    }

    function TestModeMessage({quest}){

        console.log("questStatus", quest?.quest_status)

        return( 
            quest?.quest_status == "TEST" 
                ? 
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", padding:5}}>
                <ScienceRoundedIcon style={{fontSize:14, color:"dodgerblue"}}/>
                <MyText color="dodgerblue" fontSize={12}>
                    This quest is in test mode. Only you can see it.
                </MyText>
            </div>
                :
            null
        )
    }

    return (

        <div style={{display:"flex", flexDirection:"column", width:"100%", backgroundColor:"#F9F9F9", height:"100%"}}>
            {/*<div style={headerStyle}>
                <div style={{ height:80, width:80, borderRadius:100, backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    
                    { selectedQuest && <img
                        style={{objectFit:"contain", height:"90%", width:"90%"}}
                        src={image}
                    />}    

                </div>
                <div style={{padding:10}}>
                    <MyText fontSize={14} fontWeight={"bolder"} align={"flex-start"}>{selectedQuest.quest_name}</MyText>
                    <MyText fontSize={11} color={"grey"} align={"flex-start"}>{selectedQuest.quest_description}</MyText>
                </div>
            </div> */}

        {showGoalDet ? 
        <GoalDetailsLayout selectedQuest={selectedQuest}>
            {timer}
        </GoalDetailsLayout>
        :
        <div>

            <TestModeMessage quest={selectedQuest}/>

            <DetailsCard
                tokenClick={() => navigateTo("tokenList")}
                prizeClick={()=> setIsCheckWinOpen(!isCheckWinOpen)}
                instructionsClick={() => navigateTo("instructions")}
            />

            <div style={descriptionBox}>
                {selectedQuest?.quest_description && <MyText>Details</MyText>}
                <MyText wrap="wrap" align="left" color="grey" fontSize={12}>{selectedQuest?.quest_description}</MyText>
            </div><br></br>
            <div style={{paddingLeft:10}}>
                <MyText fontSize={14} align={"center"} color="black"></MyText>
            </div>
            {/*<div style={heading}>
                <MyText fontSize={14} align={"flex-start"} fontWeight={"bolder"}>Quest Goal</MyText>
                <div style={{position:"absolute", right:0, top:0, paddingRight:5}} onClick={() => navigateTo("goalDetails")}><ArrowRightAltRoundedIcon/></div>
                <ListDivider marginBottom={0} marginTop={0}/>
                <MyText fontSize={11} align={"flex-start"} color={"grey"}>{`Receive a raffle entry for ever ${selectedQuest?.goal?.[0]?.goal_goal_amount ?? 1} locations visited`}</MyText>
            </div> 
            <div style={goalContainer}>
                <div style={goalCard}>
                    <div style={guageContainer}>
                        <div style={{height:30, width:30, borderRadius:15, backgroundColor:globeFill(), position:"absolute", right:0, display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <div style={{height:20, width:20, borderRadius:10, backgroundColor:"#6600FF", position:"absolute", right:-5, top:-7, borderColor:"white", borderWidth:2, borderStyle:"solid"}}>
                                <div style={{fontSize:11, color:"white", fontWeight:"bolder", display:"flex", justifyContent:"center", alignItems:"center"}}>{selectedQuest?.goal?.[0]?.entries}</div>
                            </div>
                            <LocalActivityIcon style={{color:"white", fontSize:25}}/>
                        </div>
                        <div style={guageFill}/>
                    </div>
                        <div style={checkPrizeButton}>
                            <div 
                                style={{fontWeight:"bold"}}
                                onClick={ isRedeemable ? ()=> setIsCheckWinOpen(!isCheckWinOpen) : null}
                            >
                                Open Prize Box
                            </div>
                        </div>
                </div>
        </div><br></br>

            <div style={heading}>
                <MyText fontSize={14} align={"flex-start"} fontWeight={"bolder"}>Locations</MyText>
                <div style={{position:"absolute", right:0, top:0, paddingRight:5}} onClick={() => navigateTo("tokenList")}><ArrowRightAltRoundedIcon/></div>
                <ListDivider marginBottom={0} marginTop={0}/>
                <MyText fontSize={11} align={"flex-start"} color={"grey"}>Visit these locations</MyText>
            </div>

            <div style={subHeading}>
                <MyText fontSize={11} align={"flex-start"} color={"grey"}></MyText>
            </div>

            <div style={locationCard}>
                <RoomRoundedIcon style={{fontSize:30, color:"deeppink"}}/>
                <MyText fontSize={25} align={"flex-start"} >
                    {selectedQuest ? `${selectedQuest?.collected_count}/${selectedQuest?.tokens?.length}` : ""}     
        </MyText>*/}

            <br></br>
            <div style={heading}>
                <MyText color="black" align={"flex-start"}>Prizes</MyText>
                <div style={{position:"absolute", right:0, top:0, paddingRight:5}} onClick={() => navigateTo("prizeList")}><ArrowRightAltRoundedIcon/></div>
            </div>

            <PrizeContainer>
                <ScrollMenu>
                    {
                        selectedQuest?.goal?.[0]?.prize?.map(prize => { return <PrizeListItem key={prize?.prize_name} name={prize?.prize_name} quantity={prize.prize_quantity}/>})
                    }
                </ScrollMenu>
            </PrizeContainer>
            
        </div>}
        {isCheckWinOpen && <LottieWinAnimationV2 isOpen={isCheckWinOpen} closeModal={()=> setIsCheckWinOpen(false)}/>}
    </div>
    );
}

export default QuestDetailsLayout = React.memo(QuestDetailsLayout);



const goalContainer = {
    display:"flex", 
    width: "100%",
    backgroundColor:"#F9F9F9",
    justifyContent:"center", 
}

const goalCard = {
    height:100, 
    width:"90%", 
    backgroundColor:"white", 
    display:"flex", 
    borderRadius:10, 
    boxShadow: "3px 5px 3px #CCCCCC", 
    display: "flex", 
    flexDirection:"column", 
    justifyContent:"center", 
    alignItems:"center", 
    margin:20,
    borderWidth:1,
    borderStyle:"solid",
    borderColor:"#EEEEEE"
}

const guageContainer = {
    display:"flex", 
    height:10, 
    width:"80%", 
    backgroundColor:"#EEEEEE", 
    borderRadius:10, 
    alignItems:"center",
    position:"relative", 
}

const heading = { 
    //fontWeight:"bold", 
    color:"black",
    //fontSize:18, 
    paddingLeft:10, 
    position:"relative", 
    backgroundColor:"#F9F9F9"
}

const subHeading = {
    paddingLeft:10,
    color:"#aaaaaa", 
}

const locationCard = {
    height:70, 
    width:"40%", 
    backgroundColor:"white", 
    borderRadius:10, 
    margin:10, 
    marginBottom:20, 
    boxShadow: "3px 5px 3px #CCCCCC", 
    display:"flex", 
    alignItems:"center", 
    justifyContent:"center",
    fontSize:30, fontWeight:"bolder"
}

const descriptionBox = {
    //height:100,
    padding:10, 
    width:"100%", 
    display:"flex", 
    flexDirection:"column",
    alignItems:"flex-start", 
    justifyContent:"center",
    textAlign:"left",
    //borderWidth:1, 
    //borderStyle:"solid", 
    //borderColor:"#EEEEEE", 
    color:"grey"
}