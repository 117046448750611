import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLock, faGift} from '@fortawesome/free-solid-svg-icons';
import shadows from '@mui/material/styles/shadows';


function Scratcher({onClick, isEnabled}) {
    
    const style = { 
        borderRadius:5,  
        height:"100%", 
        width:"100%", 
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        flexDirection:"column", 
        position:"relative", 
        color:"grey", 
        fontWeight:"normal", 
    }

    const giftIconStyle = {
        fontSize:40, 
        color: isEnabled ? "dodgerblue" : "grey",
        //filter: "drop-shadow(2px 2px 2px grey)"
    }

    const lockedIcon = {
        position: "absolute",
        display: "flex", 
        top:"50%", 
        left:"50%",
        transform: "translate(-50%, -50%)",
        boxShadow: "2px 3px 5px #EEEEEE",
        fontSize:20, 
        color:"black", 
        stroke:"white", 
        strokeWidth:40
    }

    return(
        <div style={style} onClick={isEnabled ? onClick : null}>
            <FontAwesomeIcon icon={faGift} style={giftIconStyle}/>
            {!isEnabled && <FontAwesomeIcon icon={faLock} style={lockedIcon}/>}
            Open
        </div>
    )
}

export default Scratcher;