import React from 'react';
import {Marker} from "react-map-gl";
import MapDefault from "../Images/MapDefault.png";
import {ReactComponent as MapMarkerSVG2} from "../Images/MapMarkerSVG2.svg";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import styled, {keyframes} from "styled-components"; 
import HeadingText from './HeadingText';

const MarkerPopup = styled.div`
    padding:2px;
    text-align:center; 
    scale: 0;
    width: ${props => `${props.width}px`};
    background : white; 
    border-radius: ${props => `${props.lines*5}px`};
    position: absolute; 
    top: ${props => props.lines === 1 ? "-70px" : "-90px"};
    right:${props => `${-props.width/2}px`}; 
    box-shadow:2px 3px 5px grey;
    transform: scale(1.0);
    transition: all 0.4s ease;
`;

const CollectBadge = styled.div`
    position: absolute; 
    height: ${props => props.isSelected ? "15px" : "10px"}; 
    width: ${props => props.isSelected ? "15px" : "10px"}; 
    border-radius:10px;
    background: deeppink; 
    top: ${props => props.isSelected ? "-42px" : "-19px"}; 
    right: ${props => props.isSelected ? "-22px" : "-16px"}; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    transform: scale(1.0);
    transition: all 0.4s ease;
`;


function PlayMapMarker({token_lat, token_name, token_long, token_id, token_radius, collectCount, questID, selectToken, isSelected, handleTap, questImage}) {
    
    console.log("marker rerendered")

    const SIZE = isSelected ? 60 : 35;
    const isCollected = collectCount > 0; 
    const image = questImage ? questImage : MapDefault

    //NOTE: This code is not being used at the moment....have costly animations that are trying to be removed. \

    /*const scaleAnimation = keyframes `
        0%{transform: scale(0.1); opacity:0.1}
        100%{transform: scale(1.0); opacity:1.0}
    `;

    const CollectBadge = styled.div`
        position: absolute; 
        height: ${props => props.isSelected ? "15px" : "10px"}; 
        width: ${props => props.isSelected ? "15px" : "10px"}; 
        border-radius:10px;
        background: deeppink; 
        top: ${props => props.isSelected ? "-42px" : "-19px"}; 
        right: ${props => props.isSelected ? "-22px" : "-16px"}; 
        display: flex; 
        justify-content: center; 
        align-items: center;
        animation-name: ${scaleAnimation};
        animation-duration: 0.3s;
        animation-iteration-count: 1;
    `;

    const MarkerPopup = styled.div`
        padding:2px;
        text-align:center; 
        width: ${props => `${props.width}px`};
        background : white; 
        border-radius: ${props => `${props.lines*5}px`};
        position: absolute; 
        top: ${props => props.lines === 1 ? "-70px" : "-90px"};
        right:${props => `${-props.width/2}px`}; 
        box-shadow:2px 3px 5px grey;
        animation-name: ${scaleAnimation};
        animation-duration: 0.3s;
        animation-iteration-count: 1
`   ;*/

    const tokenData = {
        token_id: token_id, 
        quest_id: questID,
        token_radius: token_radius,
        token_lat: token_lat,
        token_long: token_long, 
        token_collected_count: collectCount
    }

    const imageContainer = {
        height: isSelected ? 20 : 10, 
        width: isSelected ? 20 : 10, 
        borderRadius:15, 
        backgroundColor:"white",
        position:"absolute", 
        top: isSelected ? -30 : -10.5, 
        left: isSelected ? -8.5 : -2, 
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
    }

    const imageStyle = {
        height:isSelected ? 20 : 12, 
        width: isSelected ? 20 : 12,  
        borderRadius:10,
        objectFit:"contain",
        top:-10,
    }


    function MarkerPopUp(){

        const leng = token_name.length;
        let width;
        let lines;

        if(leng <= 25){width = leng * 7.2; lines = 1}
        else{width = 150; lines = 2 }

        return(
            <MarkerPopup width={width} lines={lines}>
                <HeadingText maxLines={2} fontSize={12} color="#4f4f4f">{token_name}</HeadingText>
            </MarkerPopup>
        )
    }

    return (
        <Marker
            latitude={token_lat}
            longitude={token_long}
            key={token_id}
            onClick={ isSelected ? () => handleTap(tokenData) : ()=> selectToken(tokenData)}
        >
            <div style={{height:0, width:0}}>
                
                <MapMarkerSVG2 viewBox='0 0 80 80' height={SIZE} style={{cursor:"pointer", transform: `translate(${-SIZE / 2}px,${-SIZE}px) ${isSelected ? "scale(1)" : "scale(0.6)"}`, position:"relative", top:15, left:6 }}>

                </MapMarkerSVG2>

                    <div style={imageContainer}>
                            <img
                                style={imageStyle}
                                src={image}
                            />
                    </div>

                    {isCollected &&
                    <CollectBadge isSelected={isSelected}>
                        <CheckRoundedIcon style={{ fontSize:7, color:"white"}}/>
                    </CollectBadge>}

                    {isSelected && <MarkerPopUp/>}
                  
            </div>
                
        </Marker>
    );
}

export default React.memo(PlayMapMarker);

