import React from 'react';
import styled, {keyframes} from "styled-components"; 



function StyledAnimTest({children}) {
    
    const breatheAnimation = keyframes`
        0% { height: 100px; width: 100px; }
        30% { height: 400px; width: 400px; opacity: 1 }
        40% { height: 405px; width: 405px; opacity: 0.3; }
        100% { height: 100px; width: 100px; opacity: 0.6; }
    `;

    const scaleAnimation = keyframes `
        
        0%{transform: scale(0.1); opacity:0.1}
        100%{transform: scale(1.0); opacity:1.0}
    `;

    const Circle = styled.div`
        height: 200px;
        width: 300px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        animation-name: ${scaleAnimation};
        animation-duration: 0.6s;
        animation-iteration-count: 1;
    `;

    return (
        <div style={{display:"flex", height:"100%", width:"100%", zIndex:1000, position:"absolute", justifyContent:"center", alignItems:"center"}}>
            <Circle>
                {children}
            </Circle>
        </div>
    );
}

export default StyledAnimTest;