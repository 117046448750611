import React from 'react';

function MyText(props) {

    console.log("MyText rendered")

    const textStyle = {
        display:"flex",
        fontWeight: props.fontWeight ? props.fontWeight : "normal",
        fontFamily:"Shippori Antique B1", 
        fontSize:props.fontSize, 
        color: props.color ? props.color : "black", 
        textAlign: props.align ? props.align : "center", 
        display:'-webkit-box', 
        WebkitBoxOrient: 'vertical',
        flexWrap:"wrap", 
        wordBreak:"break-word", 
        WebkitLineClamp: props.maxLines, 
        overflow: 'hidden',
        whiteSpace: props.wrap ? props.wrap : "nowrap" //Keeps text on single line
    }
    

    return (
        <div style={textStyle}>
            {props.children}
        </div>
    );
}

export default React.memo(MyText);