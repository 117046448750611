import React, {useState, useRef} from 'react';
import {Marker} from 'react-map-gl';
import ReactDOM from 'react-dom'
import { useEffect } from 'react';
import getRandomPrizes from '../../../API/getRandomPrizes';
import openRandomPrize from '../../../API/openRandomPrize';
import { BASE_URL } from '../../../API/GeneralVars';
import RaffleTimer from '../../common/RaffleTimer';
import {isPointWithinRadius} from "geolib"
import getLocation from '../../../location/GetUserLocation';
import { useUserLocContext } from '../../../context/UserLocContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faGlobe, faFrown ,faSmile, faCompass, faBomb, faClock } from '@fortawesome/free-solid-svg-icons';
//import { useUserLocationContext } from '../../../context/UserLocationContext';
import getLocationState from '../../../location/GetUserLocationState';
import SQSquareLogo from '../../../Images/SQSquareLogo.png'
import MediaQuery from "../../../components/common/MediaQuery";
import useIsTablet from '../../../utils/useIsTablet';
import LoadingIcon from '../../common/LoadingIcon';



/*
TODOS 
1. Move prize ref to the main parent component so that it doesnt disappear if the modal get prematurely closes

*/



/* API ENDPOINTS
    - Get the list
    - Change the location
    - Collect the prize
*/

/*Steps:
    1. Make the map componet....should look like a prize box. 
    2. Link it to a fake API call to get all of the random tokens
    3. Backend creates new locations if empty or the timestamp has passed. 
    4. User needs to be within the radius to open the box
    5. Create the modal for opening the prize box....make sure to give distance hint in the modal 
    5. Check if the user is logged in before allowing them to check the box for a win
    6. Create the prize in the backend if the user is a winner. 
    - Create an API endpoint for the tokens. 
        - Dont pass from backend if already collected or ended. 
    - Create a react-map radius layer for the boxes
*/


function RandomMarker({prize, refreshData, deleteRandomPrize}) {

    const [isOpen, setIsOpen] = useState(false)
    const [isInsideRadius, setIsInsideRadius] = useState(false)
    const [isPrizeOpen, setIsPrizeOpen] = useState(false)

    const location = prize?.locations[0]
    //const created = location?.created
    
    const prizeResultRef = useRef(null) //Stores the prize object.

    const {coords, isAccurateRef, isAccurate,  getLocation, isError, userLocation:locationContext, isUserInside} = useUserLocContext()
    console.log("isAccurateRef", isAccurateRef.current)

    const hasPrizeImage = prize?.image?.trim() ? true : false
    
    //Could also use setInterval to check the time every so often i.e. 10 seconds
    useEffect(()=>{
        
        const timeOut = getTimetoRefresh() + 1500

        if(timeOut <= 0) {console.log("escaped"); return}

        const timer = setTimeout(refreshData, timeOut)

        return ()=> {clearTimeout(timer); console.log("timer cleared")}

    }, [prize])


    async function isUserInsideRadius(){

        console.log("isInside yes")
        const user_loc = await getLocation()
        //const user_loc = await getUserLoc()
        
        if(!user_loc) return false

        const isInside =  isPointWithinRadius(
            {latitude: user_loc?.coords?.latitude, longitude: user_loc?.coords?.longitude},
            prize?.locations?.[0],
            prize?.collect_radius
        )

        console.log("isInside", isInside)
        setIsInsideRadius(isInside)
        return 
    }

    const isInside = isUserInside(prize?.locations[0], prize?.collect_radius)


    //Sync the radius check with the user location object
    //useEffect(()=>{
        //console.log("goodStuff", prize)
        //isUserInsideRadius() 
        //isUserInside(prize?.locations[0], prize?.collect_radius)
    //}, [coords])


    function getTimetoRefresh(){
     
        const now = new Date()
        const createdObj = new Date(prize?.locations?.[0]?.created)
        const expires = new Date(createdObj.setMinutes(createdObj.getMinutes() + prize?.ttl))
        const timeOut = expires - now 
        return timeOut
    }

    const markerStyle = {
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
        padding:3,
        height:25, 
        width:25, 
        borderRadius: "20%", 
        backgroundColor:"white", 
        boxShadow:"2px 3px 5px grey", 
        position:"absolute", 
        top:-12.5, 
        right:-12.5
    }

    return (
        <Marker longitude={location?.longitude} latitude={location?.latitude} anchor="center">

            {console.log("randomLoc rendered")}

            <div style={markerStyle} onClick={()=>setIsOpen(!isOpen)}>

                <img
                    src={hasPrizeImage ? prize?.image : SQSquareLogo}
                    style={{objectFit:"cover", height:"100%", width:"100%", borderRadius:"10%"}}
                /> 

            </div>

            {isOpen && 
                <RandomPrizeModal 
                    setIsOpen={setIsOpen} 
                    prize={prize} 
                    hasPrizeImage={hasPrizeImage} 
                    refreshData={refreshData}
                    isInsideRadius={isInside}
                    isPrizeOpen={isPrizeOpen}
                    setIsPrizeOpen={setIsPrizeOpen}
                    deleteRandomPrize={deleteRandomPrize}
                    isAccurateRef={isAccurateRef}
                    isAccurate={isAccurate}
                    locationContext={locationContext}
                    prizeResultRef={prizeResultRef}
                    isOpen={isOpen}
                />
            }
        </Marker>
    );
}

export default React.memo(RandomMarker);


function RandomPrizeModal({setIsOpen, prize, hasPrizeImage, refreshData, isInsideRadius, isPrizeOpen, setIsPrizeOpen, deleteRandomPrize, isAccurateRef, isAccurate, prizeResultRef, isOpen}){

    const {userLocation:locationContext} = useUserLocContext()

    const isMobile = MediaQuery()
    const isTablet = useIsTablet()

    const modalWrapper = {
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
        position: "fixed", 
        top:0, 
        bottom:0, 
        left:0, 
        right:0, 
        zIndex:1000, 
    }

    const modalStyle={
        display:"flex", 
        flexDirection:"column",
        justifyContent:"space-around",
        alignItems:"center",
        position:"relative",
        padding:"5%",
        textAlign:"center",
        minHeight: isMobile ? "40vh" : isTablet ? "5vh" : "20vh", 
        width: isMobile ? "70vw" : isTablet ? "40vw" : "25vw" , 
        borderRadius: 10, 
        backgroundColor:"white",
        boxShadow:"4px 6px 10px grey"
    }

    const headingText = {
        fontWeight:"bolder", 
        fontSize:18
    }

    const descriptionText = {
        fontSize:"12", 
        color:"grey"
    }

    const imageContainer = {
        height:"25%", 
        width:"35%", 
        borderRadius: "10%",
        objectFit:"contain", 
        //backgroundColor:"grey",
    }


    return(

        ReactDOM.createPortal(

                <div style={modalWrapper} >

                    <div style={modalStyle}>

                        {
                        isPrizeOpen 
                        ?
                        <OpenResult prize={prize} setIsPrizeOpen={setIsPrizeOpen} deleteRandomPrize={deleteRandomPrize} refreshData={refreshData} prizeResultRef={prizeResultRef} setIsOpen={setIsOpen}/>
                        :
                        <>
                        <div style={imageContainer}>

                            <CloseButton onClick={()=> setIsOpen(false)}/>

                                <img
                                    src={ hasPrizeImage ? `${prize?.image}` : SQSquareLogo}
                                    style={{objectFit:"contain", height:"100%", width:"100%", borderRadius:"5%"}}
                                />
                            
                        </div>

                        <div style={headingText}>{prize?.name}</div>

                        <CountDowntoMoveV2 created={prize?.locations[0]?.created} ttl={prize?.ttl} refreshData={refreshData}/>
                        
                        <div style={descriptionText}>{prize?.description}</div>
                        
                        {isPrizeOpen && <OpenResult/>}
                        
                        <ClaimButton prize={prize} isInsideRadius={isInsideRadius} onClick={setIsPrizeOpen} isPrizeOpen={isPrizeOpen} isAccurate={isAccurate}/>
                        
                        <TooFarAway isInsideRadius={isInsideRadius}/>
                        
                        {
                        !locationContext?.isAccurate && 
                            <div style={{fontSize:12, color:"red"}}>
                                <FontAwesomeIcon icon={faCompass} style={{fontSize:12, color:"red"}}/>
                                GPS accuracy too low
                            </div>
                        }

                        {
                        locationContext?.isError && 
                            <div style={{fontSize:12, color:"red"}}>
                                <FontAwesomeIcon icon={faBomb} style={{fontSize:12, color:"red"}}/>
                                GPS not working
                            </div>
                        }
                    </>
                    }
                    </div>
                </div>
                , document.body
            )
    )
}


function ClaimButton({prize, isInsideRadius, onClick, isPrizeOpen}){

    const {userLocation:locationContext} = useUserLocContext()
    const disable = !isInsideRadius || !locationContext.isAccurate || locationContext.isError
    console.log("Randommarker claimbutton")


    const containerStyle = {
        display:"flex", 
        justifyContent:"center", 
        alignItems:"flex-end",
        paddingRight:15,
        paddingLeft:15,   
        borderRadius: 10, 
        paddingTop:2.5, 
        paddingBottom:2.5,
        backgroundColor: disable  ? "#DDDDDD" : "deeppink",
        color:"white",
        margin:"5%", 
        boxShadow: disable ? "" : "2px 3px 5px grey", 
    }

    return(

        <div style={containerStyle} onClick={disable ? null : ()=> onClick(!isPrizeOpen)} >
            <div style={{fontWeight:"bolder", fontSize:14}}>
                {`1:${prize?.odds_to_win}\u00A0`}
            </div>
            <div style={{fontSize:12}}>
                odds of winning
            </div>
        </div>
    )
}



function getTimeout(){
    
    const now = new Date()
    const created = new Date(2023,0,14,16,38)
    const ttl = 1500
    const expires = new Date(created.setSeconds(created.getSeconds() + ttl))
    const timeOut = (expires - now)

    console.log("timeout now", now.toLocaleString())
    console.log("timeout created", created.toLocaleString())    
    console.log("timeout", timeOut)

    return(timeOut)

}

function CloseButton({onClick}){

    const container = {
        height:30, 
        width:30, 
        position: "absolute",
        top:10, 
        right:10,
        display:"flex", 
        justifyContent:"center",
        alignItems:"center", 
        borderRadius:25, 
        backgroundColor:"#EEEEEE", 
        fontSize:18, 
        color:"white", 
        fontWeight:"bolder"
    }
   
    return(
        <div style={container} onClick={onClick}>
            X
        </div>
    )
}


function CountDowntoMove({created, ttl, refreshData}){
    //Count down until the token moves

    function getTimetoRefresh(){
     
        const createdObj = new Date(created)
        const expires = new Date(createdObj?.setMinutes(createdObj?.getMinutes() + ttl))
        const dateString = expires.toString()
        return dateString
    }

    const containerStyle = {
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
        flexDirection:"column",
        width:"100%",
        margin:"2%", 
        padding:"2%",
        marginTop:"4%", 
        marginBottom:"4%",
        borderRadius:10, 
        borderWidth:1, 
        borderStyle: "solid", 
        borderColor:"#EEEEEE", 
        fontSize:10
    }

    
    return(
        <div style={containerStyle}>
            <div>location will change in: </div>
            <RaffleTimer 
                endDate={getTimetoRefresh()} 
                subTitle={false} 
                scale={0.65} 
                raffleStatus={false} 
                endCallback={refreshData}
                style="chip"
                color={"black"}
            />
        </div>
    )
}


function CountDowntoMoveV2({created, ttl, refreshData}){

    function getTimetoRefresh(){
     
        const createdObj = new Date(created)
        const expires = new Date(createdObj?.setMinutes(createdObj?.getMinutes() + ttl))
        const dateString = expires.toString()
        return dateString
    }

    const containerStyle = {
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
        margin:"2%", 
        paddingLeft:"3%",
        paddingRight:"3%",
        paddingTop:"1%", 
        paddingBottom:"1%",
        marginBottom:"4%",
        borderRadius:10, 
        borderWidth:1, 
        borderStyle: "solid", 
        borderColor:"#EEEEEE", 
        fontSize:10
    }

    return(
        <div style={containerStyle}>
            <FontAwesomeIcon icon={faClock} style={{fontSize:16, color:"deeppink", marginRight:"5%"}}/>
            <RaffleTimer 
                endDate={getTimetoRefresh()} 
                subTitle={false} 
                scale={0.8} 
                raffleStatus={false} 
                endCallback={refreshData}
                style="chip"
                colour="white"
                fontWeight="normal"
            />
        </div>
    )
}


function TooFarAway({isInsideRadius}){

    console.log("Randommarker tooFarAway")


    const style = {
        color:"red", 
        fontSize:12
    }

    return(
        !isInsideRadius && 
        <div style={style}>
            <FontAwesomeIcon icon={faGlobe} style={{fontSize:12, paddingRight:"1%"}}/>
            Get closer to open
        </div>
    )
}


function OpenResult({prize, setIsPrizeOpen, deleteRandomPrize, refreshData, prizeResultRef, setIsOpen }){

    const [isLoading, setIsLoading] = useState(true)
    //const {location} = useUserLocContext()

    async function getResults(){
        const result = await openRandomPrize(prize)
        prizeResultRef.current = result
        console.log("OpenResult Called", result) 
        setIsLoading(false)
        return result
    }


    useEffect(()=>{
        getResults()
    }, [])

    function close(){

        if(prizeResultRef?.current?.status == "ok"){
            setIsPrizeOpen(false)
            prizeResultRef.current = null
            refreshData()
            setIsOpen(false)
            deleteRandomPrize(prize?.id)
        }

        else{
            setIsPrizeOpen(false)
            prizeResultRef.current = null
        }
    }

    useEffect(()=>{  //This should be a catch all incase the "OK" button doesnt work. Have seperate function for each. 
        
        return ()=> {close()}

    }, [])

    const mainContainer = {
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
        height:"100%", 
        width:"100%", 
    }

    const subContainer = {
        display:"flex", 
        flexDirection:"column", 
        justifyContent:"center", 
        alignItems:"center", 
        textAlign:"center"
    }

    const buttonStyle = {
        display:"flex", 
        justifyContent:"center", 
        algnItems:"ceneter",
        borderRadius:30, 
        padding:"2%", 
        minWidth:"20%",
        backgroundColor:"deeppink",
        color:"white"
    }

    return(
        isLoading 
        ?
        <div style={mainContainer}>
            <LoadingIcon text={false} fullScreen={false} />
        </div>
        :
        <div>

            {prizeResultRef?.current?.status == "error" &&

                <div style={subContainer}>
                    There was a problem opening the prize. Please try again later.
                    <br/> <br/>
                    <div style={buttonStyle} onClick={close}>OK</div>   
                </div>
            }

            {prizeResultRef?.current?.status == 'ok' && prizeResultRef.current?.data?.is_winner == true && 
                <div style={subContainer}>
                    <FontAwesomeIcon icon={faStar} style={{color:"deeppink", fontSize:40}}/>  
                    <div style={{fontWeight:"bolder"}}>Congrats! You've won </div>
                    <div style={{fontSize:12}}>We've add the prize the you wallet.</div>
                    <br/><br/>
                    <div style={{fontSize:10, color:"grey"}}>Make sure to collect it before it expires.</div> 
                    <br/>
                    <div style={buttonStyle} onClick={close}>OK</div>   
                </div>          
            }
            {prizeResultRef?.current?.status == 'ok' && prizeResultRef.current?.data?.is_winner == false && 
                <div style={subContainer}>
                    <FontAwesomeIcon icon={faFrown} style={{color:"deeppink", fontSize:40}}/>
                    <div style={{fontWeight:"bolder"}}>Sorry, you didn't win this one.</div>
                    <br/><br/>
                    <div style={{fontSize:10, color:"grey"}}>Better luck next time!.</div>  
                    <div style={buttonStyle} onClick={close}>OK</div>   
                </div>
            }
        </div>
    )
}