import BaseClient from "./BaseClient";

const URL = "/api/user_registration/"

async function registerUser(authData) {

    console.log("user_registration", authData)
    let obj = {};

    try{
        
        const response = await BaseClient.post(URL, authData)

        if(response.data){
            obj.status="ok"
            obj.data = response.data
        }

        return obj
    }

    catch(error){

        if(error.response){ 
            if(error?.response?.status == 406){
                obj.status="Error" 
                obj.data ="There are multiple accounts registered to this device"
            }
            else if(error?.response?.status==403){
                obj.status="Error"
                obj.data= "Email already in use"
            }
            else {
                obj.status="Error"
                obj.data= "Theres a problmen with you input"
            }
        }
        
        else if(error.request){ 
            obj.status="Error"
            obj.data="There is an error with the server.  Please try again later"
        }

        return obj
    }
}

export default registerUser;