import React, {useState, useMemo, useRef} from 'react';
import QuestDetailsLayoutV3 from '../QuestDetailsLayoutV3';
import { useQuestContext } from '../../../context/QuestContext';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import TokenListItem from '../common/TokenListItem';
import PrizeListItem from '../common/PrizeListItem';
import RaffleTimer from '../../common/RaffleTimer';
import HelpLayout from '../common/HelpLayout';


function QuestDetailsNavigator({closeDrawer}) {

    const {deselectQuest, selectedQuest: {tokens}, selectedQuest:{goal}, selectedQuest:{quest_end}, selectedQuest} = useQuestContext();
    const prizes = goal?.[0]?.prize;

    const timer = useMemo(() => (<RaffleTimer endDate={quest_end} subTitle={false}/>),[])

    const [component, setComponent] = useState("main")
    console.log("QuestDetailsLayout tokens", tokens)


    function navigateTo(navTo){
        setComponent(navTo)
    }

    function navBack(){
        setComponent("main");
    }

   
    function BackButton({navBack}){
        return (
            <div 
                style={backButton}
                onClick={navBack}
            >
                <ArrowBackRoundedIcon/>
            </div>
        )
    }

    function TokenListLayout(){
        return(
            <div style={{height:"100%", width:"100%", display:"flex", justifyContent:"center"}}>
                <div style={{display:"flex", flexDirection:"column", backgroundColor:"white", width:"94%", borderRadius:20, overflow:"hidden"}}>
                    {tokens?.map(token => {
                        return (<TokenListItem token={token}/>)
                    })}
                </div>
            </div>
        )
    }

    function PrizeListLayout(){

        return(
            <div style={{height:"100%", width:"100%", display:"flex", alignItems:"center", flexDirection:"column"}}>
                    {prizes?.map(prize => {
                        return (<PrizeListItem name={prize?.prize_name} quantity={prize?.prize_quantity} listType="vertical"/>)
                    })}
            </div>
        )
    }
    
    const header = {
        display:"flex", width:"100%", height: component == "main" ? 0 : 40
    }

    function rendered() {
        
            switch(component){

            case "main":
                return <QuestDetailsLayoutV3 navigateTo={navigateTo}/>
            
            case "prizeList": 
                return <PrizeListLayout/>
            
            case "instructions":
                return <HelpLayout/>

            case "tokenList":
                return <TokenListLayout/>
        }
    }

    return (
        selectedQuest && <> {/*Dont render details if no selectedQuest*/}
            <div style={header}>
                {component !== "main" && <BackButton navBack={navBack}/>}
            </div>
            {rendered()}
        </>
    );
}

export default QuestDetailsNavigator;

const backButton = {
    position:"fixed", 
    left:10, 
    transform: "translate(0%, 0%)" // Use fixed position relative to a div. 
}
