import React from 'react';
import * as turf from "@turf/turf";
import {Source,Layer } from "react-map-gl";


function RandomPrizeRadius({randomPrize}) {
   
    
    const location = randomPrize?.locations?.[0]
    const radius = randomPrize?.collect_radius
    const id = randomPrize?.id
    console.log("randomprizeRadius", location, radius, id)
    
    const options = {steps: 45, units: "meters"};

    function buildLayer(){

        console.log("radii tokens inside render function")
        const data = turf?.circle([location?.longitude, location?.latitude], radius, options)

        return(
            console.log("radii token deeper inside"),
            <Source key={`token-${id}`} type="geojson" data={data}>
                <Layer 
                    type={'fill'}
                    key={`fill-${id}`}
                    layout={{visibility:"visible"}}
                    id={`fill-${id}`} //Needed to "de-select" a token marker so doesnt persist on map
                    paint={{
                        //'fill-color': token?.token_collected_count > 0 ? "#DDDDDD" : '#FF69B4',
                        'fill-color':'deeppink',
                        'fill-opacity':0.4, 
                    }}
                />
                <Layer 
                    key={`line-${id}`}
                    id={`line-${id}`}
                    type={'line'}
                    layout={{visibility:"visible"}}
                    //id={`${token.token_id}-line`} //Needed to "de-select" a token marker so doesnt persist on map
                    paint={{
                        'line-width':3,
                        'line-color': "deeppink", 
                        //'line-color': token?.token_collected_count > 0 ? "grey" : "deeppink", 
                    }}
                />    
            </Source>
        )
    }


    //let layers = [];
    //layers = tokens?.map(token => buildLayer(token));
    //console.log("layer4", layers)
    //return(layers)

    return(buildLayer())

/*return(     
            <Source key={`quest 17`} type="geojson" data={data[0]}>
                <Layer 
                    type={'fill'}
                    key={`fill`}
                    //key={`fill-${token.quest_id}`}
                    //layout={{visibility:"none"}}
                    id={`fill`} //Needed to "de-select" a token marker so doesnt persist on map
                    paint={{
                        //'fill-color': token.collectCount > 0 ? "#DDDDDD" : '#FF69B4',
                        'fill-color':'#FF69B4',
                        'fill-opacity':0.7, 
                        'fill-outline-color':"black",
                    }}
                />
                 <Layer 
                    //key={`line-${token.token_id}`}
                    key={`line`}
                    id={`line`}
                    type={'line'}
                    //id={`${token.token_id}-line`} //Needed to "de-select" a token marker so doesnt persist on map
                    paint={{
                        'line-width':3,
                        'line-color': "deeppink", 
                    }}
                />    
            </Source>
    );*/
}

export default React.memo(RandomPrizeRadius);