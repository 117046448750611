import React, {useState, useEffect} from 'react';
import styled, {keyframes} from "styled-components";
import MyText from '../../MyText';
import CloseButton from '../common/CloseButton';
import isEmpty from "lodash/isEmpty";
import WorkspacesSharpIcon from '@mui/icons-material/WorkspacesSharp';
import LocalFireDepartment from '@mui/icons-material/LocalFireDepartment';
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';
import { CheckRounded } from '@mui/icons-material';
import MediaQuery from "../../common/MediaQuery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { fontSize } from '@mui/system';



const PrizeListContrainer = styled.div`
    height:100%;
    width:100%;
    background: #f9f9f9;
    border-radius:20px;
    display: flex
    flex-direction: column;
    transition: all 0.5s ease-in-out;
    transform: ${props => props.isOpen ? 'translateY(160px)' : 'translateY(0px)'};
    overflow-y:hidden;
    border-width:1;
    border-color: #CCCCCC;
    border-style: solid;
`;

const scaleAnimation = keyframes`
    0%{transform: scale(0.1)}
    40%{transform: scale(1.05)}
    80%{transform: scale(0.95)}
    100%{transform: scale(1.0)}
`;

const Wrapper = styled.div`
    height:70vh;
    width:${ props => props.isMobile ? "90vw" : "35%"};
    background: black;
    border-radius:20px;
    overflow:hidden;
    animation-name: ${scaleAnimation};
    animation-duration: 0.6s;
`;

const List= styled.div`
    height: ${props => props.isOpen ? "54%" : "84%"};
    transition: height 0.2s 0.2s;
    width:90%;
    background: #f9f9f9;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: auto; 
    margin-right: auto;
    ::-webkit-scrollbar {display:none;}
`;

function TestPrize({prizeArray, close, click}) {
    
    const headerHeight = "56px";

    console.log("prizeArray", prizeArray)
    const isMobile = MediaQuery();

    const [selectedPrize, setSelectedPrize] = useState({});
    const [filter, setFilter] = useState("all")
    const [filteredPrizes, setFilteredPrizes] = useState({
        all:[], 
        redeemable:[], 
        claimed:[]
    })
    const isModalOpen = isEmpty(selectedPrize)
    
    const prizeExpires = new Date(selectedPrize?.entrant_prize?.prize_expires).toLocaleString();
    const redemLoc = selectedPrize?.entrant_prize?.prize_redem_loc;
    const redemType = selectedPrize?.entrant_prize?.prize_redem_type;
    const questImage = selectedPrize?.goal?.quest?.quest_image;
    const qrCode = selectedPrize?.entrant_qrcode;

    function handlePrizeSelect(prize){
        setSelectedPrize(prize)
    }

    function handlefilter(filter){
        setFilter(filter)
    }
    
    useEffect(()=>{
        
        const data = {}; 
        data.all = prizeArray || [];
        data.claimed = prizeArray?.filter?.(prize => prize?.entrant_collected == true) || []
        
        data.expired = prizeArray?.
            filter?.(prize => data?.claimed.every(claimedPrize => claimedPrize?.id !== prize?.id))?.
            filter?.(prize => (new Date()  - new Date(prize?.entrant_prize?.prize_expires)) > 0)
            || []

        data.redeemable = prizeArray?.
            filter(prize => data?.claimed?.every(prizeClaimed => prize?.id !== prizeClaimed?.id))?.
            filter(prize => data?.expired?.every(prizeExp => prize?.id !== prizeExp?.id)) || []

        console.log("prizeList", data?.redeemable, data?.expired, data?.claimed)

        setFilteredPrizes(data)

    }, [prizeArray]) 

    const header = {
        height:"9%", 
        width:"100%", 
        backgroundColor:"#f9f9f9",
        position:"sticky", 
        display: "flex", 
        alignItems:"center",
        justifyContent:"flexStart", 
    }


    function ExpiredChip(){

        const expiredChipStyle = {
            position: "absolute", 
            top:0, 
            right:10, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            margin:5
        }

        const circle = {
            height:15, 
            width:15, 
            borderRadius: 10,
            backgroundColor:"deeppink",
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center"
        }

        return(
            <div style={expiredChipStyle}>
                <div style={circle}>
                    <EventBusyRoundedIcon style={{fontSize:11, color:"white"}}/>
                </div>
                <MyText fontSize={12} color="grey">Expired</MyText>
            </div>
        )
    }

    function ClaimedChip(){

        const expiredChipStyle = {
            position: "absolute", 
            top:0, 
            right:10, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            margin:5
        }

        const circle = {
            height:15, 
            width:15, 
            borderRadius: 10,
            backgroundColor:"deeppink",
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center"
        }

        return(
            <div style={expiredChipStyle}>
                <div style={circle}>
                    <CheckRounded style={{fontSize:11, color:"white"}}/>
                </div>
                <MyText fontSize={12} color="grey">Claimed</MyText>
            </div>
        )
    }


    function isClaimed(prize){
       return prize?.entrant_collected == true
    }

    function isExpired(prize){
        return (new Date()  - new Date(prize?.entrant_prize?.prize_expires)) > 0
    }

    function FilterChip({children, filter}) {

        return(
        <div style={filterChipStyle} onClick={()=> handlefilter(filter)}>
            {children}
        </div>
        )
    }

  function RedemptionCard({children}){
      return(
          <div style={redemptionCardStyle} onClick={click}>
              <div style={{width: isMobile ? "60%" : "25%", height:160, display:"flex", justifyContent:"flex-start", flexDirection:"column", paddingLeft:20}}>
                <MyText align="left" color="white" fontWeight="bolder">{selectedPrize?.entrant_prize?.prize_name}</MyText>
                  
                <div style={{height:60, width:60, backgroundColor:"deeppink", borderRadius:10,  margin:10, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    {redemType == "EMAIL" ?
                        <FontAwesomeIcon icon={faEnvelope} style={{fontSize:35, color:"white"}}/>:
                        <img 
                            style={{height:"90%", width:"90%", objectFit:"contain", borderRadius:10}}
                            src={qrCode}
                        />
                    }
                </div>

                    <MyText color="white" align="left" fontSize={11}>
                        {redemType == "EMAIL" ?
                        `Your prize will be emailed to you` :
                        `Redeem at: ${redemLoc}`}
                    </MyText>

                    <MyText color="white" align="left" fontSize={11}>
                        {`expires: ${prizeExpires}`}
                    </MyText>
                    
              </div>

              <div style={{height:160, display:"flex", alignItems:"center", justifyContent:"center"}}>
                <div style={{height:100, width:100, borderRadius: 100, backgroundColor:"#EEEEEE", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <img 
                        style={{height:"90%", width:"90%", objectFit:"contain", borderRadius:100}}
                        src={questImage}
                        />
                </div>
              </div>
          </div>
      )
  }


  function PrizeCard({prize}){

    const prizeExpiresLocal = new Date(prize?.entrant_prize?.prize_expires).toLocaleString();
    const isSelected = selectedPrize?.entrant_prize?.id == prize?.entrant_prize?.id;
    const questImageLoc = prize?.goal?.quest?.quest_image || prize?.goal?.random_prize?.image

    console.log("prize Image", prize)

    return(
        <div style={{...tempCard, borderWidth: isSelected ? 2 : 0}} onClick={()=>handlePrizeSelect(prize)}>

            <div style={{height:50, width:50, borderRadius:100, backgroundColor:"#EEEEEE", marginLeft:10, marginRight:15, display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img 
                    style={{height:"90%", width:"90%", objectFit:"contain", borderRadius:"50%"}}
                    src={questImageLoc}
                />
            </div>

            {isClaimed(prize) && ClaimedChip()}
            {isExpired(prize) && !isClaimed(prize) && ExpiredChip()}

            <div style={{display:"flex", flexDirection:"column"}}>
                <MyText fontSize={12} fontWeight="bolder" align="left">{prize?.entrant_prize?.prize_name}</MyText>
                <MyText color="grey" fontSize={10} align="left">{`expires: ${prizeExpiresLocal}`}</MyText>
            </div>

        </div>
    )
  }

    return (

            <Wrapper isMobile={isMobile}>

                {<RedemptionCard/>}
                <PrizeListContrainer isOpen={!isModalOpen}>

                    <div style={{ marginLeft:15, height:"7%"}}>
                        <MyText color="black" align="left" fontSize={20} fontWeight="bold">My Prizes</MyText>
                        <CloseButton close={close} details={true}/>
                    </div>

                    <div style={header}>
                        <FilterChip filter={"all"}>
                            <WorkspacesSharpIcon style={{fontSize:20, color: filter === "all" ? "deeppink" : "#EEEEEE"}}/>
                            <MyText fontSize={14} fontWeight="bold">All</MyText>
                        </FilterChip>
                        <FilterChip filter={"redeemable"}><LocalFireDepartment style={{fontSize:20, color: filter === "redeemable" ? "deeppink" : "#EEEEEE"}}/><MyText fontSize={14} fontWeight="bold">Redeemable</MyText></FilterChip>
                        {/*<FilterChip filter={"claimed"}><CheckCircleSharpIcon style={{fontSize:20, color: filter === "claimed" ? "deeppink" : "#EEEEEE"}}/><MyText fontSize={14} fontWeight="bold">Claimed</MyText></FilterChip>*/}
                    </div>
                
                    <List isOpen={!isModalOpen}>

                        { prizeArray ? 
                        
                            filteredPrizes?.[filter]?.map((prize, index) => {

                                return(
                                    <PrizeCard prize={prize} key={index}/>
                                )
                            }) 
                        : 
                            <MyText>You don't have any prizes</MyText>}

                    </List>
                </PrizeListContrainer>
            </Wrapper>
    );
}

export default TestPrize;

const tempCard = {
    height:60,
    position: "relative",
    borderRadius:100, 
    display:"flex", 
    alignItems:"center",
    width:"98%", 
    marginTop:15,
    marginBottom:15,
    backgroundColor:"white",
    boxShadow: "2px 2px 3px #CCCCCC", 
    borderColor:"rgb(255,20,147,0.5)", 
    borderStyle:"solid" 
}

const filterChipStyle = {
    height:"50%", 
    display:"flex", 
    padding:3,
    minWidth:"20%",
    justifyContent:"center", 
    alignItems:"center",  
    borderRadius:50, 
    backgroundColor:"white", 
    boxShadow: "2px 3px 3px #CCCCCC", 
    margin:15
}

const redemptionCardStyle = {
    heigth:"100%", 
    width:"100%",
    display:"flex",
    position:"absolute",
    padding:10,
}
