import React from 'react';
import {Link} from "react-router-dom"
import { Navbar, NavDropdown, Nav, Container, Row, Col} from "react-bootstrap"
import {MenuItem, AvatarMenu} from "react-rainbow-components"
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuthContext } from "../context/AuthContext";
import {handleLogout} from "../auth/Handlers";


function NavBarRB() {

  const {authObj, refreshAuth, logOut} = useAuthContext()

  const Avatar = (
    <AvatarMenu
      menuSize='x-small'
      avatarBackgroundColor='deeppink'      
      avatarSize='medium'
      icon={<AccountCircleIcon/>}
    >
      <MenuItem
        label={authObj.userName}
        iconPosition='left'
      />
      
      <MenuItem
        label="Logout"
        iconPosition='left'
        icon={<PowerSettingsNewIcon/>}
        onClick={logOut}
      />
      
    </AvatarMenu>
  )

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
        <Navbar.Brand as={Link} to="/home"><div style={{color:"deeppink"}}>sideQuest</div></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/create">Create</Nav.Link>
            <Nav.Link as={Link} to="/play">Play</Nav.Link>
            {/*<Nav.Link as={Link} to="/test3">Play</Nav.Link>
            {/*<NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown>*/}
          </Nav>
          <Nav>
            { !authObj.authenticated 
              ? 
              <Nav.Link as={Link} to="/auth">Login/Signup</Nav.Link>
              : 
              <div>
                <Row>
                  <Col><div style={{color:"white"}}>{Avatar}</div> </Col>  
                </Row>
              </div>
              }
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}

export default NavBarRB;