import React, {useState, useEffect} from 'react';
import QuestPickerv2 from '../common/QuestPickerv2';
import styled from 'styled-components';
import { Input, Textarea, Card, FileSelector, Button, DateTimePicker } from 'react-rainbow-components';
import httpService from '../../API/Client';
import URL_ROOT from "../URL_ROOT";
import  Joi from 'joi-browser';
import _ from 'lodash';

const StyledText = styled.div`
    color:grey;
    font-size:20px;
    font-weight:bold;
`;

const Row = styled.div`
    display:flex;
    flex-direction:row;
    align-items: center;
    margin:20px;
`;

const ColumnTitle = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    flex:1
`;

const ColumnData = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    flex:5
`;

const StyledCardTitle = styled.div`
    font-size:30px;
    font-weight:bold;
`;

const ImageContainer = styled.div`
    display: flex;
    border-radius: 7.5px;
    border-color: lightgrey;
    border-style: solid; 
    margin-bottom: 10px;
    margin-top: 20px;
`;

const StyledImg = styled.img`
    height:100px;
    width:100px;
    border-radius:7.5px;
    object-fit: contain;
`;

const MEDIA_ROOT = "/media/"; //Used to check if image is from database or not 

//Change schema for quest review.....user cant make start date earlier than what was submitted but doesnt need to provide 24hrs. 
//May need a time stamp for when it was submitted for approval in the backend. 
//Add custom messages for each of the errors when time permits. 



function QuestDetailsLayout({ questStatus, quest, doChange, doCancelChange, doDateChange, doImageChange, questCreate, refreshQuestData, setErrorState, setStripeModal}) {
    
    let minDate = new Date();
    minDate.setDate(minDate.getDate() + 1)

    const schema = {
        id: Joi.allow(""),
        quest_name: Joi.string().required().min(5).max(30).label(" Quest name"),
        quest_description: Joi.string().allow("").max(150).label("Quest Description"),
        quest_start: Joi.date().required().greater((Date.now() + 24 * 60 * 60 * 1000)).label("Start date"), //give 24 hours for approval 
        quest_end: Joi.date().required().greater(Joi.ref('quest_start')).label("End date"), 
        quest_image: Joi.allow("")
    }
    
    const errorsObj = {
        quest_name:[], 
        quest_description:[],
        quest_start:[],
        quest_end:[]
    }

    const [edit, setEdit] = useState(questCreate ? true : false); //State that unlocks editing of the items. 
    const [originalData, setOriginalData] = useState({}); //Copy of quest data for reverting if changes cancelled
    const [errors, setErrors] = useState({errorsObj})
    
    console.log("questobject", quest)
    console.log("errors", errors)

    const handleEdit = () => {
        setOriginalData({...quest})
        setEdit(!edit)
    };

    function handleCancel(){
        doCancelChange(originalData)
        setErrors(errorsObj)
        setEdit(false)
    }

    console.log("errors", errors)

    function handleValidate() {
        const options ={abortEarly:false};
        const validate = Joi.validate(quest, schema, options)
        let newErrors = errorsObj; 

        if(validate?.error?.details?.length > 0){
            validate?.error?.details.map((error) => newErrors[error?.path]?.push(error?.message))
        }
        setErrors(newErrors)
        }

    const isEmpty = Object.values(quest).every(x => x === null || x === ''); //Prevents validation before object initialized. Should be done seperately for each error type. 

    function someErrors(){
        return Object.values(errors).some(x => x.length > 0)
    }

    useEffect(()=>{
        if(isEmpty) return
        if(!edit) return
        handleValidate()
    }, [quest, edit])

    useEffect(()=>{
        if(isEmpty) return
        setErrorState?.("quest_details", someErrors())
    },[errors])
    
    async function handleSave(){
    
        const form = new FormData;
        form.set("id", quest.id)
        quest.quest_name && quest.quest_name !== originalData.quest_name && form.set("quest_name", quest.quest_name)
        quest.quest_start && quest.quest_start !== originalData.quest_start && form.set("quest_start", quest.quest_start)
        quest.quest_end && quest.quest_end !== originalData.quest_end && form.set("quest_end", quest.quest_end)
        quest.quest_description && quest.quest_description !== originalData.quest_description && form.set("quest_description", quest.quest_description)
        quest.quest_image && quest.quest_image !== originalData.quest_image && form.set("quest_image", quest.quest_image)
        
        const response = await httpService("PUT",`${URL_ROOT}/api/update_quest_details/`, form)

        if(response === "error") return

        setEdit(false)
        refreshQuestData()
    }

const EditButton = () => {
    return(
         !edit ?
        <Button 
            label={edit === false ? "edit" : "save"}
            variant="outline-brand" 
            onClick={handleEdit}
        />
        :
        <>
        <Button 
            label={"save"}
            variant="brand" 
            onClick={handleSave}
            style={{margin:10}}
            disabled={ 
                errors.quest_name?.length > 0 ||
                errors.quest_description?.length > 0 ||
                errors.quest_start?.length > 0 ||
                errors.quest_end?.length > 0 ? true : false
            }
        />
    
        <Button 
            label={"Cancel"}
            variant="outline-brand" 
            onClick={() => handleCancel()}
        />
        </>
    ) 
}

console.log("new quest", quest)

const QuestStatus = () => {

    if(!questStatus) return
    let text;

    switch(questStatus){

        case("TEST"):
            text = "Quest is in sandbox mode"
        break;

        case("ACT"):
            text = "Your Quest is Approved"
        break;

        case("PEND"):
            text = "Your quest is pending approval"
        break;
    }

    return(
    <Row>
        <ColumnTitle><StyledText>Quest Status:</StyledText></ColumnTitle>

        <ColumnData>
            <div style={{fontSize:16, fontWeight:"bold", color:"black", display:"flex", flexDirection:"row", justifyContents:"center", alignItems:"center"}}>
                {text}

                {questStatus === "TEST" && <div style={{marginLeft:10}}><Button onClick={()=>setStripeModal(true)}>Push Live</Button></div>}
        </div>
        </ColumnData>

    </Row>
    );
};

//Need to add a default empty symbol to this
const img = 
    quest?.quest_image?.["name"] ? URL.createObjectURL(quest.quest_image) :
    ((quest?.quest_image) ? (`${quest.quest_image}`) :`${originalData.quest_image}`);

    return (
        <Card
            title={<StyledCardTitle>Quest</StyledCardTitle>}
            style={{backgroundColor:"white", width:"70vw", margin:20}}
            actions={!questCreate ? <EditButton/> : null}
            footer={ 
                <div>
                    <Row>
                        <ColumnTitle><StyledText>Quest Type*:</StyledText></ColumnTitle>
                        <ColumnData><QuestPickerv2 questCreate={questCreate}/></ColumnData>

                    </Row>

                    {QuestStatus()}

                    <Row>
                        <ColumnTitle><StyledText>Quest Name*:</StyledText></ColumnTitle>

                        <ColumnData>
                            <Input
                                placeholder="Quest name"
                                type="text"
                                name='quest_name'
                                value={quest?.quest_name}
                                onChange={(event) => doChange(event)}
                                className="rainbow-p-around_medium"
                                disabled={!edit}
                                error={errors?.quest_name?.length > 0 ? errors.quest_name[0] : null}
                                style={{marginTop:20, width:350}}
                                />
                        </ColumnData>
                    </Row>

                    <Row>
                        <ColumnTitle><StyledText>Description</StyledText></ColumnTitle>

                        <ColumnData>
                            <Textarea
                                id="example-textarea-1"
                                rows={5}
                                name="quest_description"
                                disabled={!edit}
                                value={quest?.quest_description}
                                placeholder="Description"
                                onChange={(event) => doChange(event)}
                                error={errors?.quest_description?.length>0 ? errors.quest_description[0] : null}
                                style={{width:"60%", marginTop:20}}
                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                            />
                        </ColumnData>
                    </Row>

                    <Row>
                        <ColumnTitle><StyledText>Start*</StyledText></ColumnTitle>

                        <ColumnData>
                            <DateTimePicker
                                placeholder="Start Date and Time"
                                type="datetime-local"
                                disabled={!edit}
                                minDate={minDate}
                                value={quest?.quest_start}
                                onChange={(event) => doDateChange(event,"quest_start")}
                                error={quest.quest_start && (errors?.quest_start?.length > 0 ? errors.quest_start[0] : null)}
                                className="rainbow-p-around_medium"
                                style={{marginTop:20, width:350}}
                            />
                        </ColumnData>
                    </Row>

                    <Row>
                        <ColumnTitle><StyledText>End*</StyledText></ColumnTitle>

                        <ColumnData>
                            <DateTimePicker
                                placeholder="End Date and Time"
                                disabled={!edit}
                                name="quest_end"
                                value={quest?.quest_end}
                                minDate={minDate}
                                onChange={(event) => doDateChange(event,"quest_end")}
                                error={quest.quest_end && (errors?.quest_end?.length > 0 ? errors.quest_end[0] : null)}
                                type="datetime-local"
                                className="rainbow-p-around_medium"
                                style={{marginTop:20, width:350}}
                            />
                        </ColumnData>
                    </Row>

                    <Row>
                        <ColumnTitle><StyledText>Image</StyledText></ColumnTitle>



                        <ColumnData>
                            <ImageContainer>
                                {quest.quest_image && <StyledImg src={img}/>}
                            </ImageContainer>

                            <FileSelector
                                //variant="multiline"
                                //uploadIcon={<InsertPhotoRoundedIcon/>}
                                //bottomHelpText="Select only one file"
                                disabled={!edit}
                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                style={{width:160}}
                                placeholder={`Drag & Drop or Click to Browse`}
                                onChange={(value) => doImageChange(value)}
                            />
                        </ColumnData>
                    </Row>
                </div>}
        >
        </Card>
    );
}

export default QuestDetailsLayout;