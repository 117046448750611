import React from 'react';
import MapDefault from '../../../../../Images/MapDefault.png';


function DetailsImageContainer({image}) {

    return (
        <div style={imageContainerStyle}>
            <img src={image ? image : MapDefault} style={imageStyle}>
            </img>
        </div> 
    );
}

export default DetailsImageContainer;

const imageContainerStyle = {
    height:65, 
    width:65,
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center", 
}

const imageStyle = {
    height:"90%", 
    width:"90%", 
    objectFit:"contain"
}