import React from 'react';
import {Source,Layer } from "react-map-gl";


function PolygonLayer({data}) {

    const token = {
        id: 23, 
        token_collected_count: 0
    }

    return(
        <Source key={`token-${token.id}`} type="geojson" data={data?.quest_area?.features?.[0]}>
            <Layer 
                type={'fill'}
                key={`fill-${token.id}`}
                //layout={{visibility:"none"}}
                id={`fill-${token.id}`} //Needed to "de-select" a token marker so doesnt persist on map
                paint={{
                    'fill-color': "deeppink",
                    'fill-opacity':0.4, 
                }}
            />
            <Layer 
                key={`line-${token.id}`}
                id={`line-${token.id}`}
                type={'line'}
                //layout={{visibility:"none"}}
                paint={{
                    'line-width':3,
                    'line-color': token?.token_collected_count > 0 ? "grey" : "deeppink", 
                }}
            />    
        </Source>
    )
}

export default React.memo(PolygonLayer);