import React, {useEffect, useState} from 'react';
import getWonPrizes from '../../api/getWonPrizes'
import { tabBarHeight } from '../../utils/stdVariables';
import PrizeListItem from './PrizeListItem';
import LoadingIcon from '../../../../components/common/LoadingIcon';
import { useWonPrizeContext } from '../../context/wonPrizeContext';
import { useAuthContext } from '../../../../context/AuthContext';
import NotAuthedLink from './NotAuthedLink';

function WalletPage(props) {

    const { wonPrizes, isLoading, refreshWonPrizes }= useWonPrizeContext()
    const {isAuthed} = useAuthContext()

    useEffect(()=>{
        if(!isAuthed)return
        refreshWonPrizes()
    }, [])

    return (
        <div style={container}>

            <div style={headingPlaceHolder}>My Wallet</div><br/>

            {!isLoading && isAuthed && wonPrizes?.length == 0 && <span style={noPrizesStyle}>You dont have any prizes</span>}

            {isLoading && 
                <div style={loadingIconContainer}> 
                    <LoadingIcon fullScreen={false} text={false}/> 
                </div>  
            }

            {!isLoading && isAuthed && wonPrizes?.map((prize)=><PrizeListItem prize={prize}/>)}

            {!isAuthed && !isLoading && <NotAuthedLink/>}

        </div>
    );
}

export default WalletPage;

const container = {
    height:`calc( 100vh - ${tabBarHeight}px)`,
    width:"100%",
    display:"flex", 
    flexDirection:"column", 
    alignItems:"center",
    overflowY: "auto", 
    padding:"3.5%", 
    position:"relative", 
}

const headingPlaceHolder = {
    display:"flex", 
    width:"100%", 
    fontSize:24,
    fontWeight:"bold"
}

const loadingIconContainer = {
    position: "absolute", 
    top:"50%", 
    Left:"50%"
}

const noPrizesStyle = {
    width:"100%", 
    color:"grey",
    fonteight:"bold",
    textAlign:"center"
}
