import React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
//import QRCode from "react-qr-code"
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NuitBlanche from '../../../../Images/SQSquareLogo.png'
import {QRCodeSVG} from 'qrcode.react';
import CountDownTimer from '../../../common/components/CountDownTimer';
import { tabBarHeight } from '../../utils/stdVariables';
import { BASE_URL } from '../../../../API/GeneralVars';
import { getPrizeStatus } from './getPrizeStatus';


function PrizeDetails(props) {

    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    const prize = location?.state?.prize
    const status = getPrizeStatus(prize?.claimed, prize?.prize?.expires)
    const QRCodeURL = `${BASE_URL}/api/areaquest/redeem/${prize?.uuid}/`

    console.log("Prize obj", prize?.prize)


    return (
        <div style={container}>

            <div style={headerContainer}>
                <div style={backButtonStyle} onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                </div>
            </div>

            <div style={subContainer}>

                <div style={{display:"flex", flexDirection:"column", width:"100%", alignItems:"center"}}>
                    <span style={prizeNameStyle}>{prize?.prize?.name}</span>
                    <div>
                        {<span style={leadText}>{status}</span>}
                        <span style={{fontSize:10}}>{prize?.claimed}</span>
                    </div>
                </div><br/><br/>

               

                <div style={qrCodeContainer}>
                    {/*<QRCode 
                        value="https://www.sidequestapp.ca" 
                        imageSettings={{src:NuitBlanche}}
                        style={qrCodeStyle} 
                        size={175}
                    />*/}
                    <QRCodeSVG 
                        value={QRCodeURL}
                        style={qrCodeStyle}
                        size={175}
                        imageSettings={{src:NuitBlanche, excavate:true, height:30, width:30}}
                    />

                    <span style={{fontWeight:"bolder", fontSize:20}}></span>
                    <span style={{color:"grey", fontSize:12}}>{`Show this QRcode at:`}</span>
                    <span style={hintText}>{prize?.prize?.redemption_location || ""}</span>

                </div><br/><br/>

                    <span style={{fontSize:12, color:"grey"}}>Expires</span>
                    <CountDownTimer scale={0.85} raffleStatus={false} endDate={prize?.prize?.expires}/>                 
                    <br/><br/>   
            </div>

        </div>
    );
}

export default PrizeDetails;


const container = {
    display:"flex",
    flexDirection:"column",
    alignItems:"center", 
    justifyContent:"space-around",
    height:`calc(100vh - ${tabBarHeight}px)`,
    overflowY: "auto",
    width: "100vw", 
    position: "relative", 
}

const subContainer = {
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center", 
    height:"100%", 
    width:"100%", 
}

const backButtonStyle = {
    //position:"absolute",
    display:"flex", 
    padding:"2%",
    top:25, 
    left:25, 
}

const qrCodeStyle = {
    display:"flex",
    padding:"2.5%", 
    borderRadius:10,
    borderStyle: "solid", 
    borderWidth: 3,
    borderColor:"#EEEEEE"
}

const qrCodeContainer = {
    display:"flex", 
    flexDirection:"column",
    width:"100%", 
    alignItems:"center",
    justifyContent:"center", 
}

const prizeNameStyle = {
    fontSize:28, 
    fontWeight:"bold", 
}

const headerContainer = {
    width: "100%", 
    display:"flex", 
    padding:"2.5%"
}

const leadText = {
    color:"dodgerblue",
    fontWeight:"bold",  
    fontSize:16, 
}

const instructionContainer = {
    display:"flex",
    width:"100%", 
    flexDirection:"column",
    justifyContent:"center", 
    alignItems:"center"
}

const hintText = {
    color:"deeppink", 
    fontSize:12
}