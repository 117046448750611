import React from 'react';
import SQSquareIcon from "../../Images/SQSquareLogo.png"
import styled, {keyframes} from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Rotate = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
  padding: 2rem 1rem;
  font-size: 1.2rem;
`;


function LoadingIcon({fullScreen=true, text=true, scale=1}) {

  const container = {
    display:"flex",
    height: fullScreen ? "100vh" : "", 
    width: fullScreen ? "100%" : "", 
    justifyContent:"center", 
    alignItems:"center", 
    flexDirection:"row"
  }

  const iconStyle = {
    height:40 * scale, 
    width:40 * scale, 
  }

    return (
        <div style={container}>
            <Rotate>
                <img
                    style={iconStyle}
                    src={SQSquareIcon}
                />
            </Rotate>
            { text && <div style={{fontWeight:"bolder"}}>Loading...</div>}
        </div>
    );
}



export default LoadingIcon;