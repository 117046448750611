import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGift, faLock} from '@fortawesome/free-solid-svg-icons';
import LoadingIcon from '../../../../../../components/common/LoadingIcon';
import { useQuestContext } from '../../../../context/questContext';
import PrizeWin from './PrizeWin';
import PrizeLose from './PrizeLose';
import PrizeAPIError from './PrizeAPIError';


function RechargeScratcher({ isEnabled, apiCall, isLoading, isRecharged }) {

    const {selectedQuestModified} = useQuestContext();

    function PickComponent(){ //Need to add a state for an error when redeeming. 
        if(selectedQuestModified?.winningEntrees?.length > 0) return <PrizeWin winningEntrees={selectedQuestModified?.winningEntrees?.[0]}/>
        else if(selectedQuestModified?.winningEntrees?.length <= 0 && isRecharged)return <PrizeScratcher onClick={isEnabled ? apiCall : apiCall} isEnabled={isEnabled}/>
        else if(selectedQuestModified?.losingEntrees?.length > 0) return <PrizeLose/>
        else return <PrizeAPIError/> 
    }

    const scratcher = {
        position: "relative",
        display: "flex", 
        justifyContent:"center", 
        alignItems:"center", 
        overflow: "hidden",
        minHeight:75, 
        minWidth:75, 
        borderRadius:5,
        marginBottom:10
    }

    const loadingContainer = {
        position:"absolute", 
    }

    return(
        <div style={scratcher}>
            <PickComponent/> 
            {isLoading && <div style={loadingContainer}><LoadingIcon fullScreen={false} text={false}/></div>}
        </div>
    )
}

export default React.memo(RechargeScratcher);


function PrizeScratcher({onClick, isEnabled}){

    const style = {
        borderRadius:5,  
        height:"100%", 
        width:"100%", 
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        flexDirection:"column", 
        color: "grey", 
        fontWeight:"bold"
    }

    const giftIconStyle = {
        fontSize:40, 
        color: isEnabled ? "dodgerblue" : "grey"
    }

    const lockedIcon = {
        position: "absolute",
        display: "flex", 
        top:3, 
        left:3,
        boxShadow: "2px 3px 5px grey",
        fontSize:20, 
        color:"black", 
        stroke:"white", 
        strokeWidth:40
    }

    return(
        <div style={style} onClick={onClick}>
            <FontAwesomeIcon icon={faGift} style={giftIconStyle}/>
            {!isEnabled && <FontAwesomeIcon icon={faLock} style={lockedIcon}/>}
            Open
        </div>
    )
}

