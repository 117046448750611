import client from "./BaseClient";


async function redeemPrize(uuid, user) {

    const URL = `/api/areaquest/redeem/${uuid}`

    try{
        const response = await client.get(URL)

        if(response.data && response.status == 200){
            return response
        }
    }
    catch(error){

        if(error.response){  //Could also use status >=400 
            if(error.response.status == 406){return error.response}
            else return error.response
        } 
        else if(error.request){ //could also use status >= 500
            return error
        }
        else return error 
    }
}

export default redeemPrize ;