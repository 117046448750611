import BaseClient from "./BaseClient";

const URL = "/api/check_win/"

async function CheckWinApi(goalID) {

    console.log("Check_win", goalID)
    let obj = {};

    try{
        
        const response = await BaseClient.post(URL, {
            goal:goalID})

        console.log("API response", response)

        if(response.data){
            obj.status="ok"
            obj.data = response.data
        }

        return obj
    }

    catch(error){
        
        if(error.response){ obj.status="Error"}

        else if(error.request){ obj.status="Error"}

        return obj
    }
}

export default CheckWinApi;