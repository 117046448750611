import zIndex from '@mui/material/styles/zIndex';
import React,{useState, useCallback} from 'react';
import {Marker} from "react-map-gl";
import MapDefault from "../../Images/MapDefault.png"
import {ReactComponent as MapMarkerSVG3} from "../../Images/MapMarkerSVG3.svg";
import styled from 'styled-components';


//Styled to bring selected token to the top of the layer. 
const StyledMarker = styled(Marker)` 
    z-Index: ${props => props.isSelected && 10};
`;

function CreateMapMarker({ index, token_name, token_lat, token_long, selectMarker, isSelected, questImage, isEditableLocs, onDragUpdate, isError}) {
    
    console.log("isError token", isError)
    const SIZE = 40;

    const styles = {
        imageContainer:{
            height:20, 
            width:20, 
            borderRadius:15, 
            backgroundColor:"white",
            position:"absolute", 
            top:-33, 
            left:-10.5, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
        }, 

        markerTitle:{
            padding:5,
            paddingleft:5, 
            textAlign:"center",
            paddingRight:5, 
            fontWeight:"bold", 
            color: isSelected ? "white" : "black",
            fontSize:10, 
            position:"absolute",
            //opacity:"50%", 
            top:isSelected ? -85 : -55, 
            width:72, 
            right:7, 
            background:isSelected ? "deeppink" : "white", 
            borderRadius:10
        }, 

        markerOverlay:{
            height:40, 
            width:40, 
            backgroundColor:"", 
            opacity:"1%", 
            position:"absolute", 
            top:-40, 
            left:-20
        }, 
    }

    const [events, logEvents] = useState({});//Necessary for dragging the markers 
    
    const onDragStart = useCallback((event, index) => {
        console.log("onDragStart")
        logEvents(_events => ({..._events, onDragStart: event.lngLat}));
        selectMarker(index)
      }, [isSelected, index, token_lat, token_long]);

    const onDrag = useCallback((event, index) => {
        logEvents(_events => ({..._events, onDrag: event.lngLat}));
    }, [isSelected, index, token_lat, token_long]);

    const onDragEnd = useCallback((event,index) => {
        logEvents(_events => ({..._events, onDragEnd: event.lngLat}));
        onDragUpdate(event, index)
    }, [isSelected, index, token_lat, token_long]);


    //Required styled component to modify zIndex for selected Marker. 
  
    function IsErrorBubble(){

        const style = {
            position:"absolute", 
            top:-25, 
            right:25, 
            height:12,
            width:12, 
            zIndex:10,
            borderWidth:2,
            borderStyle:"solid", 
            borderColor:"white",
            backgroundColor:"red",
            borderRadius:10
        }

        return <div style={style}></div>
        
    }

    return (
        
        <StyledMarker 
            draggable={isEditableLocs}
            latitude={token_lat} 
            longitude={token_long} 
            key={`token-${token_name}-${token_lat}-${token_long}`} 
            onDragStart={(event)=>onDragStart(event, index)} 
            onDragEnd={(event)=>onDragEnd(event, index)} 
            onDrag={()=>onDrag(index)}
            onClick={()=>selectMarker(index)}
            isSelected={isSelected}
        >
    
            {token_name?.length > 1 && !isSelected &&
            <div style={styles.markerTitle}>
                
                {isSelected 
                    ? (token_name?.length > 15 && token_name ? `${token_name.substr(0,14)}...` : `${token_name}`) 
                    : (token_name?.length > 10 ? `${token_name.substr(0,8)}...` : `${token_name.substr(0,8)}`)
                }
            
            </div>}

            {isError && !isSelected && <IsErrorBubble/>}

            <div>
                {console.log("token Render Check")}
                
                <MapMarkerSVG3 
                    viewBox='0 0 80 80' 
                    height={SIZE} 
                    fill={isSelected ? "deeppink" : "grey"} 
                    style={{
                        cursor:"pointer", 
                        transform: `translate(${-SIZE / 2}px,${-SIZE}px) ${isSelected ? "scale(1.5)" : "scale(1.0)"}`, 
                        position:"relative", top:15, left:6 
                        }}
                    >
                </MapMarkerSVG3>

                <div style={styles.markerOverlay}></div>

            </div>
        </StyledMarker>
    );
}

export const MemoCreateMapMarker = React.memo(CreateMapMarker);
