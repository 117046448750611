import React from 'react';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import MyText from '../MyText';
import MediaQuery from '../common/MediaQuery';


function ComingSoonLayout(props) {

    const isMobile = MediaQuery()

    return (
        <div style={container}>
            <div style={centerObject}>
                    <ViewInArIcon style={{fontSize:isMobile ? 60 : 80, color:"grey"}}/>
                    <MyText fontSize={isMobile ? 22 : 30} color="grey">Coming Soon</MyText>
            </div>
        </div>
    );
}

export default ComingSoonLayout;

const container = {
    height:`calc(100vh - ${56}px)`, 
    width:"100vw", 
}

const centerObject = {
    position: "absolute", 
    top:"50%", 
    left:"50%", 
    transform:"translate(-50%, -50%)",
    height:"40%", 
    width:"60%", 
    display:"flex",
    justifyContent:"center",
    alignItems: "center"
}