import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt, faKey} from '@fortawesome/free-solid-svg-icons';
import {ReactComponent as MapMarker} from '../../../../Images/Token Marker - No Drop Shadow.svg'

function ProgressBar({text=true, current, max=0, thickness=1}){

    console.log("ProgressBar", current, max)

    const ratio = Math.min(current/max, 1)|| 0;
    const currentMod = current > max ? max : current //Modify current text not to go above max

    console.log("Progressbar", current, max )

    const background = {
        position:"relative",
        height:20 * thickness, 
        borderRadius:10,
        borderWidth:1,
    }

    const prizeBulb = {
        height: 40, 
        width: 40, 
        borderRadius:20,
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
        position: "absolute",
        right:-30,
        top:-10,
        backgroundColor: ratio === 1 ? "deeppink" : "#EEEEEE"
    }

    const counter = {
        fontWeight:"normal", 
        position:"absolute", 
        top:"50%", 
        left:"50%",
        transform: "translate(-50%,-50%)",
        zIndex:1, 
        color:"black"
    }

    return(
        <>
            {/*<div style={{color:"deeppink", fontWeight:"normal", marginTop:15}}>
                {`${selectedQuest?.collected_count}/${selectedQuest?.goal?.[0]?.goal_goal_amount}`}
                <MapMarker height={18} fill={"deeppink"}/>                  
            </div>*/}
            
            <div style={{...background, backgroundColor:"#EEEEEE", width:"80%"}}>
                {text && 
                    <div style={counter}>
                        {`${currentMod}/${max}`}
                        <FontAwesomeIcon icon={faKey} style={{fontSize:14, marginLeft:5}}/>
                    </div>}

                {ratio != 0 && 
                    <div style={{...background, backgroundColor:"dodgerblue", width: `calc(100% * ${ratio})`}}></div>
                }

            {/*<div style={prizeBulb}>
                <FontAwesomeIcon icon={faTicketAlt} style={{color: ratio === 1 ? "white" : "deeppink"}}/>
            </div>*/}

            </div>
        </>
    )
}

export default React.memo(ProgressBar);