import styled, {keyframes} from "styled-components";

    //Z-index -> put drawer above the mapbox watermark

    const MyBottomDrawer = styled.div `
        height: 100%; 
        width: 100%;
        background: white;
        position: absolute;
        bottom: -20%;
        border-radius: 20px; 
        transform: ${props => props.isOpen ? `translateY(10%)` : `translateY(65%)` };
        transition: all ease 1s;
        display: flex;   
        overflow: hidden; 
        z-index:1000;
    `;

export default MyBottomDrawer ;