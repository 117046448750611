import React, {useState, useEffect} from 'react';
import getAreaQuests from './api/getAreaQuests';
import AreaMedallionContainer from './areaquest/components/map/AreaMedallionContainer';
import AreaQuestMedallion from './areaquest/components/map/AreaQuestMedallion';
import AreaQuestModal from './areaquest/components/details/modal/AreaQuestModal';
import ReactDOM from 'react-dom'
import PolygonLayer from './areaquest/components/map/PolygonLayer';
import ReactMap from './map/ReactMap';
import * as turf from '@turf/turf'
import { useUserLocContext } from '../../context/UserLocContext';
import AreaQuestTrafficModal from './areaquest/components/details/modal/AreaQuestTrafficModal';
import { useQuestContext } from './context/questContext';
import TrafficQuestMedallion from './areaquest/components/map/TrafficQuestMedallion';
import RechargeQuestMedallion from './areaquest/components/map/medallion/QuestMedallion';
import QuestMedallion from './areaquest/components/map/medallion/QuestMedallion';
import { useRandomPrizeContext } from './context/randomPrizeContext';
import RandomPrizeRadius from '../../components/play/common/RandomPrizeRadius';
import RandomMarker from '../../components/play/random_prize/RandomMarker';
import NoQuestsMapChip from './map/NoQuestsMapChip';
import LocationPermissionMapChip from './map/LocationPermissionMapChip'
import LocationPermissionModal from './map/LocationPermissionModal';
import useGetAreaQuests from './api/useGetAreaQuest';
import { Marker, Popup } from 'react-map-gl';
import polyfield2 from "../../Images/polyfield2.png"
import { PolyMarkerPopup } from './map/PolyMarkerPopup';


//Pass isAccurate to the quest modals
function PlayPage(props) {

    //const [areaQuests, setAreaQuest] = useState(null)
    //const [selectedAreaQuest, setSelectedAreaQuest] = useState(null)
    //const [selectedQuest, setSelectedQuest] = useState(null)
    //const [isUserInside, setIsUserInside] = useState(false)
    const [isAreaQuestModalOpen, setIsAreaQuestModalOpen] = useState(false)
    const [isLocPermisModalOpen, setIsLocPermisModalOpen] = useState(false)
    const [popupInfo, setPopupInfo] = useState(null); //State for the polygon popup


    const {userLocation, locationPermission, requestLocationPermission} = useUserLocContext()
    const {randomPrizes, refreshRandomPrizes, deleteRandomPrize} = useRandomPrizeContext()
    const {quests, selectQuest, selectedQuest, isUserInside} = useQuestContext()

    useEffect(()=>{ //closes the popup on iOS since permission doesnt cause
        setIsLocPermisModalOpen(false)
    }, [locationPermission])
    
    function handleSelectQuest(questID){
        selectQuest(questID)
        setIsAreaQuestModalOpen(true)
    }

    function handleTapPolyMarker(e, centroid){ //Displays the popup hint for the polygon field. 
        console.log("handleTapMarker", e, centroid)
        e.stopPropagation()
        setPopupInfo(centroid)
    }

    function PolyMarker({data}){
        //TODO: Check if user is inside and change the UI representation. 
        const filteredData = data?.quest_area?.features?.[0]?.geometry?.coordinates?.flat(1)
        const centroidArray = filteredData?.map((points=> turf.centerOfMass(turf.points(points))))

        return(
            centroidArray?.map(centroid => {
                return(
                    <Marker longitude={centroid?.geometry?.coordinates?.[0]} latitude={centroid?.geometry?.coordinates?.[1]} offsetTop={-35} offsetLeft={-17.5} style={{ objectFit:"contain" }} onClick={(e, centroid)=>handleTapPolyMarker(e, centroid)} >
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", padding:"4%", borderRadius:500, backgroundColor:"white", height:35, width:35, boxShadow: "2px 3px 5px grey"}}>
                            <img src={polyfield2} style={{objectFit:"container", height:"90%", width:"90%"}}/>
                        </div>
                    </Marker>
                )
            })
        )
    }

    return (
        <div>
            <ReactMap>
              
                {quests?.filter(quest => quest?.id == selectedQuest?.id)?.map((data)=>{
                        return (
                            <>
                                <PolygonLayer data={data}/>
                                <PolyMarker data={data} handleTap={handleTapPolyMarker}/>
                            </>
                        ) 
                    })
                }

                {quests?.length == 0 && <NoQuestsMapChip/>}

                {/*Fix this up so that it applies to all quest types*/}
                <AreaMedallionContainer>
                    {quests?.map((areaQuest)=>{
                            console.log("areaQuest", areaQuest?.id)
                            return ( <QuestMedallion quest={areaQuest} onClick={handleSelectQuest} isSelected={areaQuest?.id == selectedQuest?.id}/>
                                /*<TrafficQuestMedallion
                                    image={areaQuest?.image} 
                                    questID={areaQuest?.id}
                                    onClick={handleSelectQuest}
                                    isSelected={areaQuest?.id == selectedQuest?.id}
                                    questEnds={areaQuest?.end}
                                    currentNumEntrees={areaQuest?.goal?.[0]?.traffic_count}
                                    trafficGoal={areaQuest?.goal?.[0]?.traffic}
                                />*/
                            )
                        })
                    }
                </AreaMedallionContainer>

                {locationPermission != "granted" && 
                    <LocationPermissionMapChip setIsOpen={setIsLocPermisModalOpen}/>}

                {randomPrizes?.
                    map(randomPrize => <RandomPrizeRadius randomPrize={randomPrize}/>)}
                {randomPrizes?.
                    map(randomPrize => <RandomMarker prize={randomPrize} refreshData={refreshRandomPrizes} deleteRandomPrize={deleteRandomPrize}/>)}

                {popupInfo && <PolyMarkerPopup setPopupInfo={setPopupInfo} popupInfo={popupInfo}/>}

                {/*<TestMapMarker lat={-104} long={50}/>
                <TestMapPolygon data={coord}/>*/}

            </ReactMap>

            {/*<AreaQuestTrafficModal 
                isOpen={isAreaQuestModalOpen} 
                closeModal={()=>setIsAreaQuestModalOpen(false)}
                data={areaQuests?.filter(areaQuest=> areaQuest?.id == selectedAreaQuest?.id)?.[0]}
                isInside={isUserInside}
            />*/}

            <LocationPermissionModal 
                isOpen={isLocPermisModalOpen} 
                setIsOpen={setIsLocPermisModalOpen} 
                requestPermission={requestLocationPermission}
            />

            <AreaQuestModal
                isOpen={isAreaQuestModalOpen} 
                closeModal={()=>setIsAreaQuestModalOpen(false)}
                data={{}}
                //data={quests?.filter(areaQuest=> areaQuest?.id == selectedQuest?.id)?.[0]}
                isInside={isUserInside}
            />

        </div>
    );
}

export default PlayPage;

