import React, {useEffect, useRef} from 'react';
import lottie from "lottie-web";


function Lottie({animation, divID, loop=true, onComplete}) {

    const anim = useRef(null)

    useEffect(()=>{

        anim.current = lottie.loadAnimation({
            loop:loop,
            container: document.querySelector(`#${divID}`),
            animationData: animation
          });

        if(onComplete){
            anim?.current?.addEventListener("complete", onComplete)}

        return () => anim.current?.destroy();
          
        }, [])
        
    console.log("Animation", anim.current)
    //anim?.current?.onComplete( () => console.log("animation complete"))
    //anim.current?.addEventListener("complete", function(){console.log("animation complete")} )

    return (
        <div 
            id={divID}
        />
    );
}

export default Lottie;