import React from 'react';
import LoadingIcon from '../../../../../../components/common/LoadingIcon';
import { useQuestContext } from '../../../../context/questContext';
import UserHasAlreadyWon from './UserHasAlreadyWon';
import AllPrizesGivenOut from './AllPrizesGivenOut';
import PrizeWin from './PrizeWin';
import PrizeLose from './PrizeLose';
import PrizeAPIError from './PrizeAPIError';
import NotRecharged from './NotRecharged';
import Scratcher from './ScratcherComponent';
import NotAuthChip from './NeedAuthChip';
import { useAuthContext } from '../../../../../../context/AuthContext';
import { useUserLocContext } from '../../../../../../context/UserLocContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';


//TODO can move alot of this to a common functional component
//TODO consider moving error code to its own component. 
function GeneralScratcher({apiCall, isAlreadyWon, isLoading, isEnabled}) {

    const {selectedQuestModified} = useQuestContext(); 
    const {isAuthed} = useAuthContext(); //Check if the user is authenticated. 
    const {locationPermission} = useUserLocContext(); //Check if location is enabled. 

    function PickComponent(){ //Add seperate pick component for the lock when a user cant claim yet. 

        if(selectedQuestModified?.winningEntrees?.length > 0) return <PrizeWin winningEntrees={selectedQuestModified?.winningEntrees?.[0]}/> //<AlreadyClaimed/>
        else if(selectedQuestModified?.losingEntrees?.length > 0) return <PrizeLose/>
        /*else if(apiErrorCode){ 
            if(apiErrorCode == 1) return <UserHasAlreadyWon/>
            if(apiErrorCode == 2) return <AllPrizesGivenOut/>
            if(apiErrorCode == 3) return <NotRecharged/>
            else return <PrizeAPIError/>
        }
        */
        else if(!isAlreadyWon) return <Scratcher onClick={isEnabled ? apiCall : apiCall} isEnabled={isEnabled}/> 
        else if(!isAuthed) return <Scratcher onClick={isEnabled ? apiCall : apiCall} isEnabled={false}/> 
        else if(locationPermission != "granted") return <Scratcher onClick={isEnabled ? apiCall : apiCall} isEnabled={false}/> 
        else return <PrizeAPIError/>
    }

    return(
        <div style={scratcher}>
            <PickComponent/>
            {isLoading && <div style={loadingContainer}><LoadingIcon fullScreen={false} text={false}/></div>}
            <br/>
            {!isAuthed && <NotAuthChip/>}
            {locationPermission != "granted" && <LocationNotGrantedChip/>}
        </div>
    )
}

export default React.memo(GeneralScratcher);


function LocationNotGrantedChip(){

    const container = {
        fontSize: 12, 
        display: "flex", 
        alignItems: "center", 
    }

    return(
        <div style={container}>
            <FontAwesomeIcon icon={faMapMarkerAlt} style={{color:"deeppink"}}/>
            You need to grant location access to open prize
        </div>
    )
}

const scratcher = {
    position: "relative",
    display: "flex", 
    justifyContent:"center", 
    flexDirection:"column", 
    alignItems:"center", 
    overflow: "hidden",
    minHeight:75, 
    minWidth:75, 
    borderRadius:5,
    marginBottom:10
}

const loadingContainer = {
    position:"absolute", 
}
