import React from 'react';
import { useUserLocContext } from '../../../context/UserLocContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCompass} from '@fortawesome/free-solid-svg-icons';

//Render a modal when tapped. 

function LocationPermissionMapChip({setIsOpen}) {

    const {locationPermission} = useUserLocContext()

    const title = {
        pending:"Location Disabled", 
        denied: "Location Denied", 
        null: "Location Disabled", 
        not_supported: "Browser not Supported"
    }

    return (
        <div style={container} onClick={()=> setIsOpen(true)}>
            <div style={iconContainer}><FontAwesomeIcon icon={faCompass} style={iconStyle}/></div>
            <div style={textContainer}>{title[locationPermission]}</div>
        </div>
    );
}

export default LocationPermissionMapChip;


const container = {
    display: "flex", 
    position:"absolute",
    top:"5.5%", 
    left:"0%", 
    minWidth:"35%",
    flexDirection:"column", 
    alignItems:"center", 
    justifyContent: "center",
    borderRadius: 100, 
}

const iconContainer = {
    display:"flex",
    position: "absolute",
    top:"-130%",
    backgroundColor:"white", 
    padding:"2.5%",
    borderRadius:500
}

const textContainer = {
    display:"flex", 
    backgroundColor:"white", 
    borderRadius:100, 
    fontSize:12, 
    paddingLeft:"4%", 
    paddingRight:"4%", 
    paddingTop:"1%",
    paddingBottom:"1%",
    boxShadow: "2px 3px 5px grey", 
}

const iconStyle = {
    fontSize:24, 
    color:"#FF4000"
}
