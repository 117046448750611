import React, {useState, useEffect, useMemo} from 'react';
import { Card, ButtonGroupPicker, ButtonOption, ButtonIcon } from 'react-rainbow-components';
import MapBoxQuestCreatev2 from './MapBoxQuestCreatev2';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import styled from 'styled-components';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import _, { map } from 'lodash';
import  Joi from 'joi-browser';
import TokenTable from './TokenTable';

const markerSchema = Joi.array().min(1).items({
    id: Joi.number().allow(""),
    quest_id: Joi.number().allow(""),
    token_collected: Joi.boolean().allow(""),
    token_created: Joi.date().allow(""),
    token_updated: Joi.date().allow(""),
    count: Joi.number().allow(""),
    //id: Joi.number().allow(""),
    token_name: Joi.string().required().min(5),
    token_description: Joi.string().allow(""),
    token_radius: Joi.number().required().min(10).max(5000),
    token_lat: Joi.number().required().min(-90).max(90),
    token_long: Joi.number().required().min(-180).max(180)
});

const StyledCardTitle = styled.div`
    font-size:30px;
    font-weight:bold;
`;

const AddLocationContainer = styled.div`
    position: absolute;
    height:100px;
    width:100px;
    bottom: 4%;
    right: 5%;
`;

function QuestMarkerLayout({
    markers,
    isEditableLocs,
    questDetails,
    setMapView,
    mapView,
    addMarker,
    onDragUpdate,
    updateMarker,
    updateMarkerRadius,
    deleteMarker,
    selectMarker,
    selectedMarker,
    clearSelected,
    questImage, // Need to create a blob for this
    handleMarkerTableNameUpdate,
    handleMarkerTableDecripUpdate,
    handleMarkerTableRadiusUpdate,
    handleMarkerTableLatUpdate,
    handleMarkerTableLongUpdate,
    handleTableMarkerDelete,
    AddMarkerTable,
    setError
}) 
{
    console.log("questDetails", questDetails)
    console.log("validate inputs", markers)
    
    function validate(){
        const  valOptions ={abortEarly:false};
        const validate = Joi.validate(markers, markerSchema, valOptions );
        if(validate?.error?.details){
            setError("quest_markers", true)
            console.log("validate", validate?.error?.details)
            return
        }
        setError("quest_markers", false)
        console.log("validate", validate)
    }

    useEffect(()=>{
        validate()
    }, [markers])

    const [isList, setIsList] = useState("map");

    function handleIsListOnChange(value) {
        console.log("handleIsListOnChange")

        setIsList(value)
    }
    
    const MapListButton = () => {

        return(
            <ButtonGroupPicker
                value={isList}
                onChange={(value)=>handleIsListOnChange(value)}
                style={{marginRight:30}}
            >
                {console.log("button group picker")}
                <ButtonOption label={<FormatListBulletedRoundedIcon/>} name="list" />
                <ButtonOption label={<MapRoundedIcon/>} name="map"/>
            </ButtonGroupPicker>
        )
    }

    const mapListButton = useMemo(()=><MapListButton/>, [isList])  //not used...left in place as an example of use memo. 

    return (
        <Card
            style={{width:"70vw", height:"80vh", backgroundColor:"white", marginBottom:30}}
            title={<StyledCardTitle>Locations</StyledCardTitle>}
            actions={
                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                    <MapListButton/>
                    {/*<EditButton/>*/}
                </div>
            }
            footer={
                isList === "map" ?
                     <MapBoxQuestCreatev2 
                        width="68vw" 
                        markers={markers}
                        isEditableLocs={isEditableLocs}
                        setMapView={setMapView}
                        mapView={mapView}
                        addMarker={addMarker} 
                        onDragUpdate={onDragUpdate} 
                        updateMarker={updateMarker} 
                        updateMarkerRadius={updateMarkerRadius}
                        deleteMarker={deleteMarker} 
                        selectMarker={selectMarker}
                        selectedMarker={selectedMarker}
                        clearSelected={clearSelected}
                        questImage={questDetails?.quest_image}
                        />
                        
                : <div>
                    <TokenTable //Use reducer would be a good option for this
                        markers={markers}
                        isEditableLocs={isEditableLocs}
                        handleMarkerTableNameUpdate={handleMarkerTableNameUpdate}
                        handleMarkerTableDecripUpdate={handleMarkerTableDecripUpdate}
                        handleMarkerTableRadiusUpdate={handleMarkerTableRadiusUpdate}
                        handleMarkerTableLatUpdate={handleMarkerTableLatUpdate}
                        handleMarkerTableLongUpdate={handleMarkerTableLongUpdate}
                        handleTableMarkerDelete={handleTableMarkerDelete}
                        AddMarkerTable={AddMarkerTable}
                    />

                    <AddLocationContainer>
                        <ButtonIcon 
                            shaded variant="brand" 
                            size="large" 
                            icon={<AddLocationAltIcon/>} 
                            onClick={(event, data)=>AddMarkerTable(event, data)} 
                            disabled={!isEditableLocs}
                            />
                    </AddLocationContainer>
                </div>
                }
        >
        </Card>

    );
}

export default QuestMarkerLayout;