import React from 'react';

//Commented out the code to show the quantity column. 

function AreaQuestPrizeList({prizes}) {

    return(
        <div style={prizeContainerStyle}>
            <div style={prizeHeaderStyle}>
                <div style={{...prizeNameCol, fontWeight:"bold"}}>Prize(s)</div>
                {/*<div style={{...prizeQuantityCol, fontWeight:"bold"}}>Quantity</div>*/}
            </div>
            
                {prizes?.map((prize)=>{
                    return(
                        <div style={container}>
                            <div style={{...prizeNameCol}}>{prize?.name}</div>
                            {/*<div style={prizeQuantityCol}>{prize?.quantity}</div>*/}
                        </div>
                    )
                })
                }
        </div>
    )
}

const prizeContainerStyle = {
    display:"flex",
    flexDirection: "column", 
    width:"90%", 
    borderRadius:10, 
    borderWidth:1, 
    borderStyle: "solid", 
    borderColor:"#DDDDDD", 
    marginTop:15, 
    overflow: "hidden",
    marginBottom:0
}

const container = {
    display:"flex",
    justifyContent:"space-around",
    width:"100%", 
    padding:3, 
}

const prizeNameCol = {
    display:"flex",
    justifyContent:"center", 
    //width:"70%", 
    width:"100%", 
    fontSize:14
}

const prizeQuantityCol = {
    display:"flex",
    justifyContent:"center",  
    width:"30%",
    fontSize:14
}

const prizeHeaderStyle = {
    display:"flex", 
    width: "100%",
    backgroundColor:"#EEEEEE"    
}


export default AreaQuestPrizeList;