import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuthContext } from '../../../../context/AuthContext';
import { useQuestContext } from '../../context/questContext';
import { useWonPrizeContext } from '../../context/wonPrizeContext';
import { tabBarHeight } from '../../utils/stdVariables';

function SettingsPage(props) {

    const {authObj, logOut, isAuthed} = useAuthContext()
    const {clearWonPrizeContext} = useWonPrizeContext()
    const {clearQuestContext} = useQuestContext()

    function handleLogOut(){
        clearWonPrizeContext()
        clearQuestContext()
        logOut()
    }
    
    return (
        <div style={container}>

            <div style={headingPlaceHolder}>
                Settings
            </div><br/>


            {isAuthed ?
            <>
                <div style={inputContainer}>
                    <span style={headingText}>Username</span>
                    <span style={normalText}>{authObj?.userName}</span>
                </div>

                <div style={{...inputContainer, alignItems:"center"}} onClick={handleLogOut}>
                    <span style={logoutText}>Logout</span>
                </div>
            </>

            :

            <>
                <span style={{textAlign:"center"}}>You neeed to login or signup to access this page</span>
                <NavLink to={"/authenticate"}>Login/Sign-up</NavLink>
            </>

            }


        </div>
    );
}

export default SettingsPage;

const container = {
    height:`calc(100vh - ${tabBarHeight}px)`, 
    overflowY: "auto", 
    maxWidth:"100%",
    display:"flex", 
    flexDirection:"column", 
    alignItems:"center", 
    padding:"3.5%", 
}

const inputContainer = {
    display:"flex",
    flexDirection:"column",
    justifyContent: "space-around",
    padding:"3.5%", 
    //minWidth:"90%",
    width:'100%',
    marginTop:"2.5%", 
    marginBottom:"2.5%",
    backgroundColor:"white", 
    borderRadius:10, 
    borderWidth:1, 
    borderStyle:"solid", 
    borderColor:"#DDDDDD"
    //boxShadow: "2px 3px 5px grey", 
}

const headingText = {
    fontWeight: "bold",
    fontSize:13
}

const normalText = {
    fontSize:13
}

const logoutText = {
    fontWeight:"bold", 
    color:"grey"
}

const headingContainer = {
    display:"flex", 
    width:"100%", 
    justifyContent:"center", 
    padding:10,
    margin:"2.5%",
    borderBottomWidth:1, 
    borderBottomStyle:"solid", 
    borderBottomColor:"#EEEEEE",
    fontWeight:"bold", 
    color:"grey"   
}

const headingPlaceHolder = {
    display:"flex", 
    width:"100%", 
    fontSize:24,
    fontWeight:"bold"
}