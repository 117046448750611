import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faKey, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import LoadingIcon from '../../../../../../components/common/LoadingIcon';


function RemoveLock({handleRemoveKey, isEnabled=false}) {
    
    const container = {
        display:"flex", 
        flexDirection:"column", 
        justifyContent:"center", 
        alignItems:"center", 
        height: "100%", 
        width:"100%", 
        textAlign:"center", 
        fontSize:12, 
        borderRadius:5, 
        borderWidth:1, 
    }

    const keyIcon = {
        fontSize:35, 
        color: isEnabled ? "dodgerblue" : "grey",
        //filter: isEnabled && "drop-shadow(3px 3px 3px grey)"
    }

    return(
        <div style={container} onClick={ isEnabled ? handleRemoveKey : null}>
            <FontAwesomeIcon icon={faKey} style={keyIcon}/>
            <span style={{fontWeight:"normal", fontSize:12, color:"grey"}}>Remove</span>
            <span style={{fontWeight:"normal", fontSize:12, color:"grey"}}>lock</span>
        </div>

    )
}

export default React.memo(RemoveLock);