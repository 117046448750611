import React from "react"
import ReactDOM from 'react-dom'
import { useUserLocContext } from "../../../context/UserLocContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCompass } from "@fortawesome/free-solid-svg-icons"
import enable_location from './../../../Images/enable_location.png'
import enable_location_2 from './../../../Images/enable_location_2.png'


function LocationPermissionModal({isOpen, setIsOpen, requestPermission}){

    const {locationPermission} = useUserLocContext()

    return(

        isOpen && 
            ReactDOM.createPortal(
                <div style={wrapper}>
                    <div style={containerStyle}>

                        <div style={imageContainer}>
                            <FontAwesomeIcon icon={faCompass} style={imageStyle}/>
                        </div>

                        <div style={titleText}>{title[locationPermission]}</div>

                        <div style={detailsText}>
                            {description[locationPermission]}
                        </div>

                        <div style={closeButtonStyle} onClick={()=>setIsOpen(false)}>
                            X   
                        </div>

                        {/*TODO: Query the browser type and select image accordingly*/}
                        { locationPermission === "denied" && <img src={enable_location_2} style={{display:"flex", width:"80%"}}/>}

                        {(!locationPermission || locationPermission == "pending") && <div style={permissionButtonStyle} onClick={requestPermission}>
                            Enable Location
                        </div>}

                        <br/>
                        { locationPermission === "denied" && <div style={permissionButtonStyle} onClick={()=> setIsOpen(false)}>Ok</div>}
                  
                    </div>
                </div>
            ,document.body
            )
    );
}

export default LocationPermissionModal

const wrapper = {
    position: "fixed", 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center",
    top:0, 
    bottom:0, 
    right:0, 
    left:0,
}

const containerStyle = {
    display:"flex",
    position:"relative",
    flexDirection:"column",
    justifyContent:"space-evenly",
    padding:"8%",
    alignItems:"center",
    minHeight:"45%", 
    width:"80%", 
    backgroundColor:"white", 
    borderRadius:10,
}

const closeButtonStyle = {
    position: "absolute", 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center", 
    top:"2.5%", 
    right:"2.5%", 
    height:25, 
    width:25, 
    borderRadius:25, 
    borderColor:"#DDDDDD", 
    borderWidth:1, 
    borderStyle:"solid", 
    color:"#DDDDDD", 
    fontWeight:"bolder"
}

const imageContainer = {
    position: "absolute", 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center", 
    top:-40, 
    height:80, 
    width:80, 
    borderRadius:40, 
    backgroundColor:"white", 
    borderStyle:"solid", 
    borderColor:"black", 
    borderWidth:3
}

const imageStyle = {
    fontSize:"50", 
    color:"#FF4000"
}

const titleText = {
    fontSize: 24, 
    fontWeight:"bolder",
    textAlign: "center"
}

const detailsText = {
    color:"grey",
    textAlign:"center"
}

const permissionButtonStyle = {
    display:"flex", 
    color:"white",
    backgroundColor:"dodgerblue", 
    borderRadius:10, 
    paddingRight:"4%", 
    paddingLeft:"4%"
}

/*const title2 = (()=>{
    switch(status){
        case "not Supported":
            return "Browser not Supported"
        case "denied":
            return "Location Permission Denied"
        case "pending":
            return "Location "
    }
})()*/

const description = {
    not_supported: "Your browser is currently not supported.  Please try, chrome, Safari, edge or firefox",
    denied: "You've denied location permission.  Youll need to enable it if you want to participate in a quest",
    pending: "You'll need to grant sideQuest location access to participate in the quests", 
    null: "You'll need to grant sideQuest location access to participate in the quests"
}

const title = {
    not_supported: "Browser not Supported", 
    denied: "Location Permission Denied",
    pending: "Grant Location Permission", 
    null: "Location Permission Disabled", 
}