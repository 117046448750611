import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBan} from '@fortawesome/free-solid-svg-icons';


function UserHasAlreadyWon(props) {
    
    const container = {
        display:"flex", 
        flexDirection:"column", 
        justifyContent:"center", 
        alignItems:"center", 
        height: "100%", 
        width:"100%", 
        textAlign:"center", 
        fontSize:12, 
        borderRadius:5, 
        borderWidth:1, 
    }

    return(
        <div style={container}>
            <FontAwesomeIcon icon={faBan} style={{fontSize:40, color:"dodgerblue"}}/>
            <span style={{fontWeight:"bold", fontSize:16}}>You've already won</span>
            <span style={{fontWeight:"normal", fontSize:12, color:"grey"}}>You can only win once</span>
        </div>
    )
}

export default UserHasAlreadyWon;