import React, {useState, useRef, useEffect} from 'react';
import styled from "styled-components";
import  isEmpty from "lodash/isEmpty";
import {Input} from "react-rainbow-components"
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import Joi from "joi-browser";
import { handleSignin, handleSignup } from '../../../auth/Handlers';
import { useAuthContext } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import MyText from '../../MyText';
import MediaQuery from '../../common/MediaQuery';

const Container = styled.div`
    display:flex;
    justify-content: center;
    align-Items: center;
    height: calc(100vh - 56px);
    width:100vw;
    overflow: hidden
`;

const Wrapper = styled.div`
    display:flex;
    height: ${props => props.isMobile ? "100%" : "60%"};
    width: ${props => props.isMobile ? "100%" : "40%"};
    background:deeppink;
    flex-direction: column;
    border-radius: ${props => props.isMobile ? "0" : "50px"};
    overflow: hidden;
    box-shadow: ${props => props.isMobile ? null : "2px 3px 5px grey"};
`;

const signupSchema = {
    username: Joi.string().required().min(5).max(30).email(),
    password: Joi.string().required().min(5).max(15),
    repassword: Joi.ref("password")
};

const signinSchema = {
    username: Joi.string().required().min(5).max(30).email(),
    password: Joi.string().required().min(5).max(15),
    repassword: Joi.string().allow("")
};


function MobileAuthLayout(props) {
//Note 56px appears to be the nav bar height

    const {refreshAuth, authObj} = useAuthContext();
    const navigate = useNavigate();

    const [isSignup, setIsSignup] = useState(false);
    const [apiError, setApiError] = useState("");
    const [errors, setErrors] = useState({
        username:"", 
        password:"", 
        repassword:""
    });

    const [authData, setAuthData] = useState({
        username:"",
        password:"",
        repassword:""
    });

    const isMobile = MediaQuery();

    function isAuthDataEmpty(){
        if(isEmpty(authData.username) || isEmpty(authData.password)){
            console.log("handleSubmit true")
            return true
        }
        return false
    }

    useEffect(()=>{
        if(authObj.authenticated === true){
            navigate("/play")
        }
    }, [authObj])

    async function handleSubmit(){
        
        handleValidate()

        if(isAuthDataEmpty()) return //Prevent submit if no data in the authData object
        
        const isEmailErrors = isEmpty(errors.username)
        const isPasswordErrors = isEmpty(errors.password)
        const isRepasswordErrors = isEmpty(errors.repassword)

        if(!isEmailErrors) {console.log("handleSubmit 1"); return}
        if(!isPasswordErrors) {console.log("handleSubmit 2"); return}
        if(isSignup && !isRepasswordErrors) {console.log("handleSubmit 3"); return}

        const response = isSignup ? await handleSignup(authData) : await handleSignin(authData)
        if(response?.status == "Error") {setApiError(response.data); return}

        refreshAuth()
        console.log("handleSubmit Success")
    }

    function handleValidate(){
        const options ={abortEarly:false};
        const validateSignin = Joi.validate(authData, signinSchema, options)
        const validateSignup = Joi.validate(authData, signupSchema, options)
        const errors = isSignup ? validateSignup : validateSignin
        let newErrors = {};

        
        /* Validate each field seperately on blurr
        const anotherObj = errors?.error?.details?.filter(error =>{
            return error.path == "password"
        })
        console.log("anotherObj", anotherObj)

        const data = {...errors};
        data.password = [...anotherObj]
        */

        const errorsObj = errors?.error?.details?.map(error => {
            newErrors[error.path] = error.message?.replace(/".*"/,"")
        })

        setErrors(newErrors)
    }

    function handleChange(event){
        const data = {...authData};
        data[event.target.name] = event.target.value?.trim()
        setAuthData(data)
        console.log("event", data)
    }

    return (
        <Container>
        <Wrapper isMobile={isMobile}>

            <div style={{...header, borderRadius: isMobile ? 0 : 30}}>
                <MyText color="white" fontWeight="bolder" fontSize={36}>
                    sideQuest
                </MyText>
            </div>

            <div style={body}>

                <div style={{color:"red"}}>{apiError}</div>

                <Input
                    id="123"
                    style={inputStyle}
                    type="email"
                    name="username"
                    icon={<EmailTwoToneIcon/>}
                    placeholder={"email"}
                    value={authData.username}
                    onChange={handleChange}
                    onBlur={handleValidate}
                    error={errors.username}
                >
                </Input>

                <Input
                    id="password"
                    style={inputStyle}
                    name="password"
                    icon={<LockTwoToneIcon/>}
                    type={"password"}
                    placeholder={"password"}
                    value={authData.password}
                    onChange={handleChange}
                    onBlur={handleValidate}
                    error={errors.password}
                >
                </Input>

                {isSignup && 
                <Input
                    id="repassword"
                    style={inputStyle}
                    type={"password"}
                    name="repassword"
                    icon={<LockTwoToneIcon/>}
                    placeholder={"re-password"}
                    value={authData.repassword}
                    onChange={handleChange}
                    onBlur={handleValidate}
                    error={errors.repassword && "passwords dont match"}
                >
                </Input>}

                <div 
                    style={submitButtonStyle}
                    onClick={handleSubmit}
                >
                    submit
                </div>

                <div
                    onClick={()=> setIsSignup(!isSignup) }
                >
                    {isSignup ? "sign-in" : "sign-up"}

                </div>
            </div>

        </Wrapper> 
        </Container>
    );
}

export default MobileAuthLayout;

const header = {
    height:"30%", 
    width:"100%", 
    backgroundColor:"deeppink",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center", 
    alignItems:"center", 
}

const body = {
    height:"70%", 
    width:"100%", 
    borderTopLeftRadius:20,
    borderTopRightRadius:20,
    backgroundColor:"white",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-evenly", 
    alignItems:"center",
}

const inputStyle = {
    width:"75%",
}

const submitButtonStyle = {
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    margin:15,
    height:40, 
    width:"50%", 
    borderRadius:100,
    backgroundColor:"deeppink", 
    color:"white",
}

const desktopStyle = {
    height: "40%", 
    width:"60%"
}