import React from 'react';
import * as turf from "@turf/turf";
import {Source,Layer } from "react-map-gl";


function MarkerRadiusCreate({token_name, token_lat, token_long, index, radius, isSelected, token_id}) {

    //console.log("token Radius Render", index)
    console.log("token radius rendered")
    console.log("tokenID", token_id)
    const tokenID = token_id.toString()
    
    const options = {steps: 25, units: "meters"};
    const circle = turf.circle([token_long, token_lat], radius, options);

return(
    <>
        <Source /*key={token_name} id={token_name}*/ type="geojson" data={circle}>
            <Layer 
                //id={`Tokens-${token_name}`}
                type={'fill'}
                paint={{
                    'fill-color': isSelected ?'#FF69B4' : "grey",
                    'fill-opacity':0.6
                }}
            />    
        </Source>
    </>
    );
}

export default React.memo(MarkerRadiusCreate);