//import getLocation from "../location/GetUserLocation";
import client from "./BaseClient";

const URL = "/api/areaquest/open_recharge_prize/"
async function openRechargePrize(questID, userLocation) {

    try{
        const response = await client.post(URL, 
            {user_location:userLocation, 
            quest_id: questID}
        )
        if(response.data && response.status == 200){
            return response
        }
    }
    catch(error){
        if(error.response){  //Could also use status >=400 
            if(error.response.status == 406){return error.response}
            else return error.response
        } 
        else if(error.request){ //could also use status >= 500
            return error
        }
        else return error 
    }
}

export default openRechargePrize;