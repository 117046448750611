import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Input, Textarea, Card, FileSelector, Button, DateTimePicker, Picklist, Option, HelpText } from 'react-rainbow-components';
import httpService from '../../API/Client';
import URL_ROOT from "../URL_ROOT";
import  Joi from 'joi-browser';
import _ from 'lodash';
import MediaQuery from '../common/MediaQuery';
import QuestPickerv3 from './QuestPickerv3';
import CreateCardHeaderText from '../create/CreateCardHeaderText';
import { isMobile } from 'react-device-detect';
import { useAuthContext } from '../../context/AuthContext';

//TODO this should be moved to utils

const availQuestTypes = {
    public:[{label:"multi-location", name:"multi-location"}], 
    admin:["location", "hidden", "trigger", "trail"]
}

const StyledText = styled.div`
    color:grey;
    font-size:15px;
`;

const Container = styled.div`
    display:flex;
    flex-direction: ${props => props.isMobile ? "column" : "row"};
    align-items: ${props => props.isMobile ? "flex-start" : "center"};
    margin:10px;
`;

const ColumnTitle = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    text-align: left;
    flex:1
`;

const ColumnData = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    flex:5;
    padding-left:5%;
    width:100%;
`;

const StyledCardTitle = styled.div`
    font-size:30px;
    font-weight:bold;
`;

const ImageContainer = styled.div`
    display: flex;
    border-radius: 7.5px;
    border-color: lightgrey;
    border-style: solid; 
    margin-bottom: 10px;
    margin-top: 20px;
`;

const StyledImg = styled.img`
    height:100px;
    width:100px;
    border-radius:7.5px;
    object-fit: contain;
`;

const StatusContainer = styled.div`
    display: flex; 
    border-radius:10px;
    border-width:1px;
    padding:10px;
    justify-content: center;
    align-items: center;
    text-justify: center;
`;

const MEDIA_ROOT = "/media/"; //Used to check if image is from database or not 

//Change schema for quest review.....user cant make start date earlier than what was submitted but doesnt need to provide 24hrs. 
//May need a time stamp for when it was submitted for approval in the backend. 
//Add custom messages for each of the errors when time permits. 



function QuestDetailsLayout2({ questStatus, quest, doChange, doCancelChange, doDateChange, doImageChange, questCreate, refreshQuestData, setErrorState, setStripeModal}) {
    
    let minDate = new Date();
    minDate.setDate(minDate.getDate() + 1)
    const isMobile = MediaQuery();

    const {authObj} = useAuthContext() //Modify inputs for staff. 
    const isStaff = authObj?.isStaff?.toLowerCase() === 'true'
  
    //Style modification betweeen mobile and desktop 
    const deviceStyle = {
        containerWidth: isMobile ? "90%" : "70vw", 
        inputWidth: isMobile ? "100%" : 350,
        textBoxWidth: isMobile ? "100%" : "60%", 
        margintTop: isMobile ? 5 : 20, 
    }

    const schema = {
        id: Joi.allow(""),
        quest_name: Joi.string().required().min(5).max(30).label(" Quest name"),
        quest_description: Joi.string().allow("").max(150).label("Quest Description"),
        quest_start: isStaff ? Joi.allow("") : Joi.date().required().greater((Date.now() + 24 * 60 * 60 * 1000)).label("Start date"), //give 24 hours for approval 
        quest_end: isStaff ? Joi.allow("") : Joi.date().required().greater(Joi.ref('quest_start')).label("End date"), 
        quest_image: Joi.allow("")
    }
    
    const errorsObj = {
        quest_name:[], 
        quest_description:[],
        quest_start:[],
        quest_end:[]
    }

    const [edit, setEdit] = useState(questCreate ? true : false); //State that unlocks editing of the items. 
    const [originalData, setOriginalData] = useState({}); //Copy of quest data for reverting if changes cancelled
    const [errors, setErrors] = useState({errorsObj})

    const handleEdit = () => {
        setOriginalData({...quest})
        setEdit(!edit)
    };

    function handleCancel(){
        doCancelChange(originalData)
        setErrors(errorsObj)
        setEdit(false)
    }

    function handleValidate() {
        const options ={abortEarly:false};
        const validate = Joi.validate(quest, schema, options)
        let newErrors = errorsObj; 

        if(validate?.error?.details?.length > 0){
            validate?.error?.details.map((error) => newErrors[error?.path]?.push(error?.message))
        }
        setErrors(newErrors)
        }

    const isEmpty = Object.values(quest).every(x => x === null || x === ''); //Prevents validation before object initialized. Should be done seperately for each error type. 

    function someErrors(){
        return Object.values(errors).some(x => x.length > 0)
    }

    useEffect(()=>{
        if(isEmpty) return
        if(!edit) return
        handleValidate()
    }, [quest, edit])

    useEffect(()=>{
        if(isEmpty) return
        setErrorState?.("quest_details", someErrors())
    },[errors])
    
    async function handleSave(){
    
        const form = new FormData;
        form.set("id", quest.id)
        quest.quest_name && quest.quest_name !== originalData.quest_name && form.set("quest_name", quest.quest_name)
        quest.quest_start && quest.quest_start !== originalData.quest_start && form.set("quest_start", quest.quest_start)
        quest.quest_end && quest.quest_end !== originalData.quest_end && form.set("quest_end", quest.quest_end)
        quest.quest_description && quest.quest_description !== originalData.quest_description && form.set("quest_description", quest.quest_description)
        quest.quest_image && quest.quest_image !== originalData.quest_image && form.set("quest_image", quest.quest_image)
        
        const response = await httpService("PUT",`${URL_ROOT}/api/update_quest_details/`, form)

        if(response === "error") return

        setEdit(false)
        refreshQuestData()
    }

    return (
        <Card
            title={<CreateCardHeaderText>Quest</CreateCardHeaderText>}
            style={{backgroundColor:"white", width:deviceStyle.containerWidth, margin:20}}
            actions={
                !questCreate ? 
                <EditButton 
                    handleEdit={handleEdit} 
                    handleSave={handleSave} 
                    handleCancel={handleCancel} 
                    errors={errors} 
                    edit={edit}
                /> : 
                null}
            footer={ 
                
                <div>

                    {!questCreate && <StatusContainer>
                        {/*QuestStatus(isMobile, setStripeModal, questStatus)*/}     
                        <QuestStatus setStripeModal={setStripeModal} questStatus={questStatus}/>
                    </StatusContainer>}

                    <InputContainer 
                        title={"Quest Name*"} 
                        isMobile={isMobile}
                    >
                        <QuestNameInput 
                            quest={quest} 
                            doChange={doChange} 
                            edit={edit}    
                            errors={errors} 
                            deviceStyle={deviceStyle}
                        />
                    </InputContainer>
                  
                    <InputContainer title={"Type"} isMobile={isMobile}>
                        <QuestTypeSelector 
                            availQuestTypes={availQuestTypes} 
                            isMobile={isMobile} 
                            edit={edit}
                            questCreate={questCreate}/>
                    </InputContainer>

                    <InputContainer title={"Description*"} isMobile={isMobile}>
                        <QuestDescriptionInput 
                            edit={edit} 
                            quest={quest} 
                            doChange={doChange} 
                            errors={errors} 
                            deviceStyle={deviceStyle} 
                        />                    
                    </InputContainer>

                    <InputContainer title={"Start date and time*"} isMobile={isMobile}>
                        <StartDateTimePicker
                            edit={edit} 
                            quest={quest} 
                            minDate={!isStaff && minDate} 
                            doDateChange={doDateChange} 
                            errors={errors}
                            deviceStyle={deviceStyle}
                        />                                           
                    </InputContainer>
                    
                    <InputContainer title={"End date and time*"} isMobile={isMobile}>
                        <EndDateTimePicker 
                            edit={edit} 
                            quest={quest} 
                            minDate={!isStaff && minDate} 
                            doDateChange={doDateChange} 
                            errors={errors}
                            deviceStyle={deviceStyle}
                        />
                    </InputContainer>

                    <InputContainer title={"Image*"} isMobile={isMobile}>
                        <ImageSelector 
                            isMobile={isMobile} 
                            quest={quest} 
                            edit={edit} 
                            doImageChange={doImageChange} 
                            originalData={originalData}
                        />
                    </InputContainer>

                </div>}
        >
        </Card>
    );
}

export default QuestDetailsLayout2;



function EndDateTimePicker({edit, quest, minDate, doDateChange, errors, deviceStyle}){

    return(
      
        <DateTimePicker
            placeholder="End Date and Time"
            disabled={!edit}
            name="quest_end"
            value={quest?.quest_end}
            minDate={minDate}
            onChange={(event) => doDateChange(event,"quest_end")}
            error={quest.quest_end && (errors?.quest_end?.length > 0 ? errors.quest_end[0] : null)}
            type="datetime-local"
            className="rainbow-p-around_medium"
            style={{marginTop:deviceStyle.margintTop, width:deviceStyle.inputWidth}}
        />
    )
}

function QuestNameInput({quest, doChange, edit, errors, deviceStyle}){

    return(
        <Input
            placeholder="Quest name"
            type="text"
            name='quest_name'
            value={quest?.quest_name}
            onChange={(event) => doChange(event)}
            className="rainbow-p-around_medium"
            disabled={!edit}
            maxLength={30}
            error={errors?.quest_name?.length > 0 ? errors.quest_name[0] : null}
            style={{marginTop:deviceStyle.margintTop, width:deviceStyle.inputWidth}}
        />
    )
}

function InputContainer({title, children, isMobile}){
    return(
        <Container isMobile={isMobile}>

            <ColumnTitle>
                <StyledText>{title}</StyledText>
            </ColumnTitle>

            <ColumnData>
                {children}
            </ColumnData>
        </Container>
    )
}

function QuestDescriptionInput({edit, quest, doChange, errors, deviceStyle}){
    
    return(
        <Textarea
            id="example-textarea-1"
            rows={5}
            name="quest_description"
            disabled={!edit}
            value={quest?.quest_description}
            placeholder="Description"
            onChange={(event) => doChange(event)}
            error={errors?.quest_description?.length > 0 ? errors.quest_description[0] : null}
            style={{width:deviceStyle.textBoxWidth, marginTop:deviceStyle.margintTop}}
            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        />
    )
}




function QuestTypeSelector({availQuestTypes, isMobile, edit, questCreate}){

    const [typeSelection, setTypeSelection] = useState("");

    //TODO Add quest type to the database. 
    function makeQuestTypeLabelObj(){

        const obj = {
            name: "multi_location", 
            label: "Multi-Location"
        }

        return obj
    }

    function MobileDropDown({availQuestTypes, edit}){
        
        return(
            <Picklist 
                style={{width:"60%"}}
                value={typeSelection}
                disabled={!edit} 
                //onChange={(value)=> console.log("mobileDropDown 23", value)}
                onChange={(value)=> setTypeSelection(value)}    
            >
                {availQuestTypes.public.map(item => <Option label={item?.label} name={item?.name} value/>)} {/*TODO Check if the user is admin*/}
            </Picklist>   
        )
    }

    return(
        isMobile 
            ? 
            <MobileDropDown availQuestTypes={availQuestTypes} edit={edit}/> 
            :
            <QuestPickerv3 questCreate={questCreate}/>
    )
}

function StartDateTimePicker({edit, minDate, quest, doDateChange, errors, deviceStyle}){

    return(
        <DateTimePicker
            placeholder="Start Date and Time"
            type="datetime-local"
            disabled={!edit}
            minDate={minDate}
            value={quest?.quest_start}
            onChange={(event) => doDateChange(event,"quest_start")}
            error={quest.quest_start && (errors?.quest_start?.length > 0 ? errors.quest_start[0] : null)}
            className="rainbow-p-around_medium"
            style={{marginTop:deviceStyle.margintTop, width:deviceStyle.inputWidth}}
        />
    )
}

function ImageSelector({isMobile, quest, edit, doImageChange, originalData}){
    //Think its best to have both the mobile and desktop components housed in the same code

    const img = 
    quest?.quest_image?.["name"] ? URL.createObjectURL(quest.quest_image) :
    ((quest?.quest_image) ? (`${quest.quest_image}`) :`${originalData.quest_image}`);

    const container = {
        display:"flex",  
        flexDirection:"column", 
        alignItems:"center", 
        padding:10,
        width:isMobile && "100%",
        borderRadius:10,
        borderWidth:1, 
        borderColor:"#EEEEEE", 
        borderStyle:"solid", 
    }

    return(
        
        <div style={container}>

            <ImageContainer>
                {quest.quest_image && <StyledImg src={img}/>}
            </ImageContainer>

            <FileSelector
                disabled={!edit}
                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                style={{width:160}}
                placeholder={`Drag & Drop or Click to Browse`}
                onChange={(value) => doImageChange(value)}
            />

        </div>
    )
}

const EditButton = ({handleEdit, handleSave, handleCancel, errors, edit}) => {

    return(
        !edit ?
        <Button 
            label={edit === false ? "edit" : "save"}
            variant="outline-brand" 
            onClick={handleEdit}
            size={isMobile && "small"}
        />
        :
        <>
            <Button 
                label={"save"}
                size={isMobile && "small"}
                variant="brand" 
                onClick={handleSave}
                style={{marginRight:10}}
                disabled={ 
                    errors.quest_name?.length > 0 ||
                    errors.quest_description?.length > 0 ||
                    errors.quest_start?.length > 0 ||
                    errors.quest_end?.length > 0 ? true : false
                }
            />
        
            <Button 
                label={"Cancel"}
                variant="outline-brand" 
                size={isMobile && "small"}
                onClick={() => handleCancel()}
            />
        </>
    ) 
}

function QuestStatus({setStripeModal, questStatus}){

    //if(!questStatus) return //this sarted causing problems when quest status was removed from the render methods
    let text;
    let backgroundColor;
    let borderWidth;

    switch(questStatus){

        case("TEST"):
            text = "Quest is in sandbox mode"
            backgroundColor = "deeppink"
            borderWidth = 0;
        break;

        case("ACT"):
            text = "Your Quest is Approved"
            backgroundColor = "white"
            borderWidth = 1;

        break;

        case("PEND"):
            text = "Your quest is pending approval"
            backgroundColor = "#EEEEEE"
            borderWidth = 0;
        break;
    }

    const container = {
        width:"100%", 
        backgroundColor:backgroundColor, 
        padding:10, 
        borderRadius:10, 
        display:"flex", 
        justifyContent:"center",
        borderStyle:"solid", 
        borderWidth: borderWidth, 
        borderColor:"#EEEEEE"
    }

    return(
        <div style={container}>

            <div style={{fontSize:16, fontWeight:"bold", color:"black", display:"flex", flexDirection:"row", justifyContents:"center", alignItems:"center", textJustify:"center"}}>
                {text}
                {questStatus === "TEST" && <div style={{marginLeft:10}}><Button size="small" onClick={()=>setStripeModal(true)} style={{fontSize:10}}>Push Live</Button></div>}
            </div>
        </div>
    );
};