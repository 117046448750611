import React from 'react';

function NotificationBubble({number, top, right}) {

    const bubbleTest = {
        position:"absolute",
        display:"flex",
        justifyContent:"center", 
        alignItems:"center", 
        top: top ? top : "-17.5%",
        right: right ? right : "-7.5%",
        height:0, 
        width: "55%", 
        paddingBottom:"50%", 
        borderRadius:"50%",
        backgroundColor:"dodgerblue",
        fontSize:"50%", 
        fontWeight:"bold",
        color:"white", 
        borderColor:"white", 
        borderStyle:"solid", 
        borderWidth:1,
    }

    return (
        <div style={bubbleTest}>
            <div style={inner}>{number}
            </div>
        </div>
    );
}

export default NotificationBubble;


const inner = {
    height:"100%", 
    width:"100%", 
    position:"absolute",
    top:"50%", 
    Left:"50%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    transform: `translate(0%, -50%)`,
    textAlign:"center",
    textJustify:"center",
    zIndex:1001
    //backgroundColor:"black",  //Just for lining up the iinterior number component
}