import React, {createContext, useContext, useEffect, useState} from 'react';
import getAreaQuests from '../api/getAreaQuests';
import get from 'lodash/get';
import { set } from 'lodash';
import { useUserLocContext } from '../../../context/UserLocContext';
import * as turf from '@turf/turf'
import { useAuthContext } from '../../../context/AuthContext';


/*
TODO
1. Add a key-value for recharge countdown to associate quest in the main quest object. Trigger update every minute..Would need to modify raw quests
2. Add isUserWithin to as an item to each quest instead of only the selected quest.  Should auto update with userLocation context
=> This could be an expensive operation if done fore alot of locations. 
3. Add setTimer function to get periodic crowd status for crowd goals. 
4. Add SetTimer to refresh the quest data every so often.  
*/

export const QuestContext = createContext()

export function useQuestContext(){
    return useContext(QuestContext)
}

export function QuestContextProvider({children}){

    const [quests, setQuests] = useState(null);  //Raw quest data
    const [selectedQuest, setSelectedQuest] = useState(null) //ID of the selected quest
    const [refreshQuests, setRefreshQuests] = useState(false) //Function call to refresh the quest...not good practice...consider removing 
    const [isUserInside, setIsUserInside] = useState(false) //Check if user is inside one of the quest polygon
    let selectedQuestObj; //Raw quest data for selected quest...can be deleted once unraveled. 
    const [questObj, setQuestObj] = useState({})//Raw data for selected quest...This should be renamed. 
    const [selectedQuestRaw, setSelectedQuestRaw] = useState(null)
    const [bbox, setBbox] = useState(null) //This can be passed to the map - INCOMPLETE 

    const {userLocation, initialLocation, locationPermission} = useUserLocContext()
    const {isAuthed} = useAuthContext()

    function getBbox(polyfields){
        const bbox = turf.bboxPolygon()
    }

    useEffect(()=>{
        setSelectedQuestRaw(quests?.filter(quest=> quest?.id == selectedQuest?.id)?.[0])
    }, [selectedQuest])

    useEffect(()=>{
        //const obj = quests?.filter(quest=> quest?.id == selectedQuest?.id)?.[0]
        //setQuestObj(quests?.filter(quest=> quest?.id == selectedQuest?.id)?.[0])
    }, [selectedQuest])

    useEffect(()=>{
        if(!userLocation)return
        if(!userLocation?.isAccurate)return
        if(!selectedQuest)return
        setIsUserInside(isWithinPolygon([userLocation?.coordinates?.longitude, userLocation?.coordinates?.latitude]))
    }, [userLocation, selectedQuest])

    function isWithinPolygon(userLocation){ 
        //Add checks to determine if userLocation and polycoords are valid
        const point = turf.point(userLocation)
        const polyCoords = quests?.filter((quest)=> quest?.id == selectedQuest?.id)?.[0]?.quest_area?.features?.[0]
        const pointsInPolygon = polyCoords && turf.pointsWithinPolygon(point, polyCoords)
        const isUserWithin = pointsInPolygon?.features?.length > 0
        return isUserWithin
    }

    useEffect(()=>{
        if(locationPermission === "granted" && !initialLocation) return
        handleGetAreaQuests() 
    }, [refreshQuests, isAuthed, initialLocation])
    
    async function handleGetAreaQuests(){
        const result = await getAreaQuests(userLocation?.simple) //Need to make sure this works on the backend if n
        setQuests(result.data)
    }   

    useEffect(()=>{
        selectedQuestObj = quests?.filter(quest=> quest?.id == selectedQuest?.id)
    }, [selectedQuest, quests])

    function selectQuest(questID){
        setSelectedQuest({id:questID})
    }

    //*********This is not being used and is under testing
    /*

    useEffect(()=>{
        const obj = quests?.filter(quest=> quest?.id == selectedQuest?.id)?.[0]
        setQuestObj(obj)
    }, [selectedQuest])
    */

    const selectedQuestModified = {
        id: selectedQuestRaw?.id, 
        name:selectedQuestRaw?.name, 
        description: selectedQuestRaw?.description, 
        starts: selectedQuestRaw?.starts,
        ends: selectedQuestRaw?.ends, 
        image: selectedQuestRaw?.image,
        prizes: selectedQuestRaw?.goal?.[0]?.prizes, 
        winningEntrees:selectedQuestRaw?.goal?.[0]?.entrants?.filter(entree => entree?.prize?.id)?.map(entree => entree?.prize), 
        losingEntrees:selectedQuestRaw?.goal?.[0]?.entrants?.filter(entree => entree?.prize == null), 
        recharged: isRecharged(),  //Add a check for traffic
        traffic: {
            count: selectedQuestRaw?.goal?.[0]?.traffic_count,
            goal: selectedQuestRaw?.goal?.[0]?.traffic, 
            numEntrees: selectedQuestRaw?.goal?.[0]?.num_entrees
        }
    }

    function isRecharged(){
        if(!selectedQuestRaw?.goal?.[0]?.entrants) return null
        if(selectedQuestRaw?.goal?.[0]?.entrants.length <= 0) return null
        
        const latestEntree = selectedQuestRaw?.goal?.[0]?.entrants?.reduce((a, b) => a > b ? a : b, null)
        const latestDate = new Date(latestEntree?.updated)
        latestDate.setMinutes(latestDate.getMinutes() + selectedQuestRaw?.goal?.[0]?.recharge_time)
        return latestDate
    }

    function updateQuest(questObj){ //Changed to questObj1 to insulate for the overall changes. 
        const copy = [...quests]
        const index = quests?.findIndex(quest=> quest.id == questObj.id)
        if(index == -1) return
        copy[index] = questObj
        setQuests(copy)
    }

    function clearQuestContext(){
        setQuests([])
        setSelectedQuest(null)
        setSelectedQuestRaw({})
    }


    return(
        <QuestContext.Provider 
            value={{
                quests, 
                setQuests, 
                selectedQuest, 
                selectQuest, 
                updateQuest, 
                isUserInside, 
                selectedQuestRaw, 
                selectedQuestModified, 
                clearQuestContext
            }}
        >
            {children}
        </QuestContext.Provider>
    )

} 





