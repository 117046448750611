import client from "./BaseClient";


async function redeemEmailPrize(uuid) {

    const URL = `/api/areaquest/redeem_email_prize/`

    try{
        const response = await client.post(URL, {uuid})

        if(response.data && response.status == 200){
            return response
        }
    }
    catch(error){

        if(error.response){  //Could also use status >=400 
            if(error.response.status == 406){return error.response}
            else return error.response
        } 
        else if(error.request){ //could also use status >= 500
            return error
        }
        else return error 
    }
}

export default redeemEmailPrize ;