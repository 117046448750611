import React, {useState, useContext, useEffect, useCallback} from 'react';
import getQuestData from '../API/GetQuestData';
import { useAuthContext } from './AuthContext';
import {getCenter} from "geolib"

export const QuestContext = React.createContext()

export function useQuestContext(){
    return useContext(QuestContext);
}

export function QuestContextProvider({children}){

    const [quests, setQuests] = useState([]);
    const [tokens, setTokens] = useState([]);
    const [questLocations, setQuestLocations] = useState([]);
    const [selectedQuest, setSelectedQuest] = useState([]);
    
    const {authObj} = useAuthContext()

    //const tokens = quests?.map( quest => quest?.tokens).flat(1) // List of the tokens

    useEffect(()=>{
        //refreshQuestData()  ///Bring this one back
    }, [authObj])

    useEffect(()=>{
        setTokens(filterTokens)
    }, [quests])

    useEffect(()=>{
        setQuestLocations(makeQuestLocations())
    }, [quests])

    const filterTokens = useCallback(() => {

        const removeEnded = quests?.filter(quest => { // Removes tokens for ended quests
            return (new Date() - new Date(quest.quest_end)) < 0
        })

        const tokens = removeEnded?.map(quest=> 
            quest.tokens?.map(token => {
                token.quest_image =  quest.quest_image
                return token
            })
        ).flat(1)
        
        return tokens

    }, [quests])

    async function refreshQuestData(){
        const payload = await getQuestData()

        const added = payload?.map(quest => {quest.quest_token_loc = makeQuestLocs(quest); return quest})
        console.log("payload", added)

        setQuests(payload)
    }

    //** TOKEN STATE MANEGEMENT */
    function collectToken(questID, tokenID){
        const newData = [...quests]
        const questIndex = quests?.findIndex(quest => quest?.id === questID);
        const tokenIndex = quests?.[questIndex]?.tokens?.findIndex(token => token?.id === tokenID);
        newData[questIndex].tokens[tokenIndex].token_collected_count+=1
        newData[questIndex].collected_count = newData[questIndex].tokens.reduce((total, current) => total + current.token_collected_count, 0)
        setQuests(newData)
    }

    function revertCollectToken(origData){
        setQuests(origData)
    }


    //TODO: This is new and needs to be tested
    function makeQuestLocs(quest){
        
        let tokens = []

        quest?.tokens?.map(token => { 
            tokens.push(
                {
                    latitude: token?.token_lat, 
                    longitude: token?.token_long
                }
            )
        })

        return (
            {
                latitude: getCenter(tokens)?.latitude, 
                longitude: getCenter(tokens)?.longitude
            }
        )
    }






    //TODO: This doesnt need to be a nested function
    function makeQuestLocations(){

        function getCenterOfTokens(quest){

            let tokens = []

            quest?.tokens?.map(token => { 
                tokens.push(
                    {
                        latitude: token?.token_lat, 
                        longitude: token?.token_long
                    }
                )})

            return getCenter(tokens)
        }
        

        function filterEndedQuests(quest){
            //Removes ended quest from the map
            const now = new Date()
            const quest_end = new Date(quest?.quest_end)
            return now < quest_end
        }

        //TODO: Dont need new array for quest icons....can filter and map in the playlayout component
        const questLocations = quests?.filter(filterEndedQuests)?.map(quest=> {

            return (
                {
                    questID: quest.id,
                    questGoal: quest.goal[0].goal_goal_amount,
                    questName: quest.quest_name, 
                    questLat: quest.quest_lat,
                    questLong: quest.quest_long,
                    questTokenLat: getCenterOfTokens(quest)?.latitude, 
                    questTokenLong: getCenterOfTokens(quest)?.longitude,
                    questImage: quest.quest_image,
                    questViewed: quest.quest_viewed,
                    questNumTokens: quest.tokens?.length,
                    questNumCollected: quest.collected_count
                }
            )
        })
        return questLocations
    }

    const selectQuest = useCallback((tokenData) => {
        const index = quests.findIndex((quest) => quest.id === tokenData.quest_id)
        const data = {...quests[index]}
        setSelectedQuest(data)
    }, [quests]) //Removed selectedQuest from the dependency array..worked before...makes sure no issues. 

    const selQuestFromList = useCallback((quest) => {
        //console.log("selQuestFormList", quest, tokens)
        //const filteredTokens = tokens.filter(token => token.quest_id === quest?.id)
        //console.log("filteredTokens", filteredTokens)
        //const bounds = ZoomToMarker(filteredTokens);

        //console.log("bounds", bounds)
        setSelectedQuest(quest)
    }, [selectedQuest, quests])

    /*ENTRANT STATE MANAGEMNT */
    function checkWin(){
    }

    function refreshSelectedQuest(){
        //Refreshes the data for the selectedQuest
        const questID = selectedQuest.id;
        const index = quests?.findIndex(quest => quest.id === questID)
        const selQuest = quests[index]
        console.log("questID", questID, index, selQuest)
        setSelectedQuest(selQuest)
    }

    async function updatePrizes(goalID){
        const original = [...quests];
        const questIndex = quests?.findIndex(quest => quest?.goal?.[0]?.id === goalID)
        const data = [...quests];
        data[questIndex].goal[0].entries--
        setQuests(data)
        refreshSelectedQuest()
    }

    /*SELECTED QUEST STATE MANAGEMENT */
    const deselectQuest = () => {
        setSelectedQuest("")
    }

    function updateRaffleEntries(questID){
        console.log("updateRaffleEntries", questID)
        const data = [...quests];
        const index = data.findIndex(quest => quest?.id == questID)
        data[index].goal[0].entries++
        setQuests(data)
    }

    //This function will internally update quest_viewed for the tapped quest
    function updateQuestViewed(questID){
        const data = [...quests];
        const index = data?.findIndex(quest => quest?.id == questID)
        data[index].quest_viewed = true
        console.log("updateQuestViewed", data)
        setQuests(data)
    }

    return (
        <QuestContext.Provider value={{quests, tokens, selectedQuest, questLocations, selectQuest, revertCollectToken, deselectQuest, refreshSelectedQuest, refreshQuestData, collectToken, updatePrizes, selQuestFromList, updateRaffleEntries, updateQuestViewed}}>
            {children}
        </QuestContext.Provider>
    )
}


