import React from 'react';
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {Popup} from "react-map-gl";
import { Input, Textarea, Slider } from 'react-rainbow-components';


function CreateMarkerPopup({isEditableLocs, selectedMarker, updateMarker, updateMarkerRadius, deleteMarker, clearSelected}) {

    console.log("selectedMarker Create", selectedMarker)

    const containerStyle = {
        display:"flex", 
        flexDirection:"column",    
    }

    return (

        <StyledPopup 
            anchor="bottom"
            latitude={selectedMarker?.token_lat || 0}
            longitude={selectedMarker?.token_long || 0}
            onClose={clearSelected}
            offsetTop={-45}
            style={{fontSize:40}}
        >

            <div style={containerStyle}>
                
                <StyledInput
                    style={{width:"100%", position: "relative"}}
                    disabled={!isEditableLocs}
                    maxLength={30}
                    minLength={1}
                    error={selectedMarker?.token_name <= 0 && "required"}
                    placeholder="Enter Location name"
                    value={selectedMarker?.token_name || ""}
                    labelAlignment='left'
                    name='token_name'
                    onChange={(event)=>updateMarker(event)}
                />

                <StyledTextarea
                    name="token_description"
                    rows={2}
                    maxLength={50}
                    disabled={!isEditableLocs}
                    placeholder="description/details"
                    labelAlignment='left'
                    grow={true}
                    style={{width:"100%", margin:0, alignSelf:"flex-start"}}
                    value={selectedMarker.token_description}
                    onChange={(event)=>updateMarker(event)}
                />

                <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
                

                    <StyledSlider 
                        label="Check-in Radius (m)" 
                        labelAlignment='left'
                        hideLabel={true}
                        style={{width:"99%", padding:5, borderWidth:1, borderStyle:"solid", borderColor:"#CCCCCC", borderRadius:10}}
                        min={25}
                        max={1000}
                        disabled={!isEditableLocs}
                        value={selectedMarker.token_radius} 
                        onChange={(value)=>{updateMarkerRadius(value)}} 
                        required 
                    />

                    <div style={{fontSize:10, marginBottom:10, color:"grey"}}>
                        Check-in Radius (m)
                    </div>

                </div>

            <div 
                style={{display:"flex", position: "absolute", right:10, top:"35%"}}
                onClick={ isEditableLocs ? () => deleteMarker(selectedMarker) : null}
            >

                <FontAwesomeIcon icon={faTrash} style={{color:"grey"}}/>

            </div>

        </div>

    </StyledPopup>

    );
}

export default React.memo(CreateMarkerPopup);


//Modify the popup style
const StyledPopup = styled(Popup)` 
    .mapboxgl-popup-content {
        padding-bottom: 5px;
        padding-top: 10px;
        border-radius: 10px;
        user-select: text;
        cursor: text;
    };

    .mapboxgl-popup-close-button {
        font-size: 25px;
        color: grey;
    }
`

//Modify the size of the react-rainbow components
const StyledInput = styled(Input)`
    transform: scale(0.7);
`;

const StyledTextarea = styled(Textarea)`
    transform: scale(0.7);
`;

const StyledSlider = styled(Slider)`
    transform: scale(0.7);
`;