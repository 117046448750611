import React, {useState, useCallback, useEffect, useMemo, useRef} from 'react';
import { Card, ButtonGroupPicker, ButtonOption, Button, ButtonIcon, Modal } from 'react-rainbow-components';
import {useLocation} from "react-router-dom";
import QuestPrizeLayout from './layouts/QuestPrizeLayout';
import QuestDetailsLayout from './layouts/QuestDetailsLayout';
import MapBoxQuestCreatev2 from './MapBoxQuestCreatev2';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import styled from 'styled-components';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import _, { map } from 'lodash';
import httpService from '../API/Client';
import URL_ROOT from "./URL_ROOT";
import  Joi from 'joi-browser';
import TokenTable from './TokenTable';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import StripePayment from './StripePayment';
import MediaQuery from './common/MediaQuery';



/*
NOTES:
1. Currently get the data from QuestDashboardV2 component.   In future it would be more efficient to have 
minimal data passed to the dashboard and the details get pulled with an HTTP request based on the quest ID
2. Add useCallback to the function so that MapBoxQuestCreatev2 to prevent re-render on toggle
3. The main quest state for this is a prime candidate for useReducer to make the code more readable. 
4. Component tree for this should be Layout >> page >> Card(container) >> components (tables, map etc) >> Subcomponents
5. Dont need parent to handle state for the update mode...just required for the create mode. 
*/

const locationSchema = Joi.array().min(1).items({
    id: Joi.number().allow(""),
    token_created: Joi.date().allow(""),
    token_updated: Joi.date().allow(""),
    count: Joi.number().allow(""),
    id: Joi.number().allow(""),
    token_name: Joi.string().required().min(5),
    token_description: Joi.string().allow(""),
    token_radius: Joi.number().required().min(10).max(5000),
    token_lat: Joi.number().required().min(-90).max(90),
    token_long: Joi.number().required().min(-180).max(180)
});

const AddLocationContainer = styled.div`
    position: absolute;
    height:100px;
    width:100px;
    bottom: 4%;
    right: 5%;
`;

const StyledCardTitle = styled.div`
    font-size:30px;
    font-weight:bold;
`;

const goalObj = [
    {
        id:"",
        goal_award_style:"RAFF",
        goal_goal_amount:0,
        goal_raffle_date:"", //needs to come from the questDetails data
        goal_complete: false,
        goal_instant_odds:"",
        prize:[]
    }
]

function UpdateQuestLayout() {
    
    const location = useLocation();
    const questId = location.state.quest.id// Do i need this 
    
    const [goal, setGoal] = useState(goalObj);
    const [markers, setMarkers] = useState([]);
    const [isErrors, setIsErrors] =useState([]);// handles errors for what?
    const [quest, setQuest] = useState({})
    const [isEditableLocs, setIsEditableLocs] = useState(false);
    const mapView = useRef({
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 8
    })


    //STRIPE PAYMENT
    const [stripeModal, setStripeModal] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    function handleStripeModal(value) {
        console.log("Stripemodal", value)
        setStripeModal(value);
    }


    console.log("new new quest", quest)

    function setMapView(lat, long, zoom){
        mapView.current.latitude=lat
        mapView.current.longitude=long
        mapView.current.zoom=zoom
    }

    async function refreshQuestData() {
        console.log("refreshQuestData")

        const response = await httpService("GET",`${URL_ROOT}/api/get_quest_details/${questId}`, {} )
        if(response === "error") return
        setQuest(response.data[0])
    }

    useEffect(()=>{
        refreshQuestData()
    }, [])

    const [questDetails, setQuestDetails] = useState({
        id: quest?.id,
        //quest_type:quest?.quest_type ?? "",
        quest_name: quest?.quest_name ?? "",
        quest_description: quest?.quest_description ?? "",
        quest_start: quest?.quest_start ?? "", 
        quest_end: quest?.quest_end ?? "", 
        quest_image: quest?.quest_image ?? "",
    });

    useEffect(()=>{
        setQuestDetails({
            id: quest?.id ?? "",
            //quest_type:quest?.quest_type ?? "",
            quest_name: quest?.quest_name ?? "",
            quest_description: quest?.quest_description ?? "",
            quest_start: quest?.quest_start ?? "", 
            quest_end: quest?.quest_end ?? "", 
            quest_image: quest?.quest_image ?? ""
        })
    }, [quest])

    const handleDetailsChange = (event) => {
        console.log("handleDetailsChange")
        const copy = {...questDetails};
        copy[event.target.name] = event.target.value
        setQuestDetails(copy)
    }

    function handleDateChange(event, name){
        console.log("handleDateChange")

        const copy = {...questDetails};
        copy[name] = event.toISOString();
        setQuestDetails(copy)
    }

    const handleImageChange = (value) =>{
        console.log("handleImageChange")

        const copy = {...questDetails};
        copy.quest_image = value[0];
        setQuestDetails(copy)
    }

    function handleCancelChange(originalData){
        console.log("handleCancelChange")

        setQuestDetails(originalData)
    }

    const [selectedMarker, setSelectedMarker] = useState({});

    const selectMarker = useCallback ((index) => {
        //const index = _.findIndex(markers, marker)
        console.log("selectMarker")

        const selected = markers?.[index]
        setSelectedMarker(selected)
        }, [selectedMarker, markers]);


    function clearSelected (){
        console.log("clearSelected")

        setSelectedMarker({})
    }


    function handleMarkerUpdate(event){
        console.log("handleMarkerUpdate")

        const index = _.findIndex(markers, selectedMarker)
        const copy = [...markers];
        copy[index][event.target.name] = event.target.value 
        setMarkers(copy)
    }

    function handleMarkerRadiusUpdate(value){
        console.log("HandleMarkerRadiusUpdate")
        const index = _.findIndex(markers, selectedMarker)
        const valueSlide = value.target.value

        if( valueSlide <= 1000 ){
            const copy = [...markers];
            copy[index].token_radius = valueSlide
            setMarkers(copy)
        } return
    }

    function AddMarkerTable(){
        console.log("AddMarkerTable")

        const markerObj={
            id:Math.random(), //Necessary for the table to track unique
            token_name:"",
            token_description:"",
            token_radius:500,
            token_lat:50 + Math.random(),
            token_long:-100 + Math.random(),
        }
        const newData = [...markers];
        newData.unshift(markerObj)
        setMarkers(newData)
    }

    function addMarker(viewport){
        console.log("AddMarker")

        const newMarker = {
            id:Math.random(), //Necessary for the table to track unique
            token_name:"",
            token_description:"", 
            token_radius:500,
            token_lat:viewport.latitude, 
            token_long:viewport.longitude, 
        }
        const copy = [newMarker, ...markers];
        setMarkers(copy)
    }

    const handleOnDragUpdate = useCallback((event, index) => {
        console.log("handleOnDragUpdate")

        const copy = [...markers];
        copy[index].token_long = event.lngLat[0]
        copy[index].token_lat = event.lngLat[1]
        setMarkers(copy);
    }, [markers]);

    function handleDeleteMarker(marker){
        console.log("handleDeleteMarker")

        const index = _.findIndex(markers, selectedMarker)
        const copy = [...markers];
        copy.splice(index, 1)
        setMarkers(copy)
        setSelectedMarker({})
    }

    const handleMarkerTableNameUpdate = ({row, value}) => {
        console.log("handleMarkerTableNameUpdate")

        const concatValue = value.substr(0,25)
        const index = _.findIndex(markers, row);
        const newData = [...markers];
        newData[index]["token_name"] = concatValue
        setMarkers(newData)
    }
    
    const handleMarkerTableDecripUpdate = ({row, value}) => {
        console.log("handleMarkerTableDescripUpdate")

        const concatValue = value.substr(0,50)
        const index = _.findIndex(markers, row);
        console.log("handleMarkerTableUpdate index", index)
        const newData = [...markers];
        newData[index]["token_description"] = concatValue
        setMarkers(newData)

    }

    const handleMarkerTableLongUpdate = ({row, value}) => {
        console.log("handleMarkerTableLongUpdate")

        if( isNaN(value)){
            console.log("trip 1")
            return
        }        

        else if(value < -180 || value > 180){
            console.log("trip 3")
            return}

        const index = _.findIndex(markers, row);
        const newData = [...markers];
        newData[index]["token_long"] = parseFloat(value)
        setMarkers(newData)
    }

    const handleMarkerTableLatUpdate = ({row, value}) => {
        console.log("handleMarkerTableLatUpdate")


        if( isNaN(value)){
            console.log("trip 1")
            return
        }

        if(value < -90 || value > 90 ){
            console.log("trip 2")
            return
        }

        const index = _.findIndex(markers, row);
        const newData = [...markers];
        newData[index]["token_lat"] = parseFloat(value)
        setMarkers(newData)
    }

    {/*}
    const [viewport, setViewport] = useState({
        width: "45vw",
        height: "67vh",
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 8
    }); // Could move this back to the map 
*/}

    const handleMarkerTableRadiusUpdate = ({row, value}) => {
        console.log("handleMarkerTableRadiusUpdate")

        if(isNaN(value)){
            console.log("Radius is not a number")
            return}

        if(value < 25 || value > 1000){
            return}

        const index = _.findIndex(markers, row);
        const newData = [...markers];
        newData[index]["token_radius"] = parseInt(value)
        setMarkers(newData)
    }



    //PRIZE EXPIRES 

    //Set initial value for prizeExpire based on the first prize in the list returned from the server. 
    //Pass prizeExpire and setPrizeExpire into questPrize Layout. 
    const [prizeExpire, setPrizeExpire] = useState("");

    useEffect(()=>{
         setPrizeExpire(quest?.goal?.[0]?.prize?.[0]?.prize_expires)
    }, [quest])



    const [isList, setIsList] = useState("map");

    function handleIsListOnChange(value) {
        console.log("handleIsListOnChange")

        setIsList(value)
    }

    const handleTableMarkerDelete = (event, row) => {
        console.log("handleTableMarkerDelete", event, row)

        const index  = _.findIndex(markers, row)
        const newData = [...markers];
        newData.splice(index, 1)
        setMarkers(newData)    
    }

    const [origData, setOrigData] = useState([]);

    console.log("handleSetEditableLocs markers outside", markers)

    function handleSetEditableLocs(){
        console.log("handleSetEditableLocs markers", markers)

        const newArray = markers.map(a => ({...a}));
        const newArray2 = _.cloneDeep(markers) 
        console.log("handleSetEditableLocs newArray", newArray)
        setOrigData([...newArray])        
        setIsEditableLocs(!isEditableLocs)
    }

    async function handleSaveLocs(){
        console.log("handleSaveLocs")

        const createMarkers = markers.filter(marker => marker.id < 1 )
        console.log("createMarkers", createMarkers)
    
        const deleteMarkers2 = _.difference(origData.map(marker => {return[marker.id]}).flat(), markers.map(marker => {return[marker.id]}).flat())
        console.log("deleteMarkers", deleteMarkers2)
    
        const updateMarkers = markers.filter(marker1 => marker1.id > 1).filter(marker2 => {
            const index = origData.findIndex(item => item.id === marker2.id)
            const origObj = origData[index]
            return !_.isEqual(marker2, origObj)
        })

        const data = {
            questId:questDetails.id,
            create:createMarkers, 
            update:updateMarkers,
            delete:deleteMarkers2
        };
        
        const response = await httpService("POST", `${URL_ROOT}/api/update_quest_locations/`, {data})

        if (response === "error") return
       
        setIsEditableLocs(false)
        refreshQuestData()
    }

    function handleCancelLocs(){
        console.log("resetMarker cancel marker", markers)
        console.log("resetMarker cancel origData", origData)
        setMarkers([...origData])
        setIsEditableLocs(!isEditableLocs)
    }

    useEffect(()=>{
        setMarkers(quest.tokens)
    }, [quest])//Whats going on here

    useEffect(()=>{
        validate()
    }, [markers])
    
    function validate(){
        console.log("validate")

        const valOptions ={abortEarly:false};
        const validate = Joi.validate(markers, locationSchema, valOptions)
        const errors = validate?.error?.details?.length > 0;
        setIsErrors(errors)
        console.log("validate", validate)
    }

    const EditButton = () => {
        console.log("EditButton Render")

        //Worked before adding useMemo....any issues may be the result of it. 
        return(

             !isEditableLocs ?
            <Button 
                label={isEditableLocs === false ? "edit" : "save"}
                variant="outline-brand" 
                onClick={()=>handleSetEditableLocs()}
            />
            :
            <>
            <Button 
                label={"save"}
                disabled={isErrors}
                variant="brand" 
                onClick={handleSaveLocs}
                style={{marginLeft:10, marginRight:10}}
            />
        
            <Button 
                label={"Cancel"}
                variant="outline-brand" 
                //style={{padding:0, height:10}}
                onClick={handleCancelLocs}
            />
            {console.log("inside render")}
            </>
        ) 
    }

    const memoTest = useMemo(()=> <EditButton/>, [isEditableLocs, markers]) // not used...left in place as example of useMemo

    const MapListButton = () => {

        return(
            <ButtonGroupPicker
                value={isList}
                onChange={(value)=>handleIsListOnChange(value)}
                style={{marginRight:30}}
            >
                {console.log("button group picker")}
                <ButtonOption label={<FormatListBulletedRoundedIcon/>} name="list" />
                <ButtonOption label={<MapRoundedIcon/>} name="map"/>
            </ButtonGroupPicker>
        )
    }

    const mapListButton = useMemo(()=><MapListButton/>, [isList])  //not used...left in place as an example of use memo. 

//***************************************************************************************** */    

    return (
        <div style={{backgroundColor:"#EEEEEE", display:"flex", width:"100vw", alignItems:"flex-start", flexDirection:"column"}}>
            <QuestDetailsLayout questStatus={quest?.quest_status} quest={questDetails} doChange={handleDetailsChange} doCancelChange={handleCancelChange} doDateChange={handleDateChange} doImageChange={handleImageChange} refreshQuestData={refreshQuestData} setStripeModal={handleStripeModal}/>
            <QuestPrizeLayout prizeExpire={prizeExpire} setPrizeExpire={setPrizeExpire} quest={quest} refreshQuestData={refreshQuestData} goal={goal} setGoal={setGoal}/>

            <Card
                style={{width:"70vw", height:"80vh", backgroundColor:"white", marginBottom:30}}
                title={<StyledCardTitle>Locations</StyledCardTitle>}
                actions={
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                        <MapListButton/>
                        <EditButton/>
                    </div>
                }
                footer={
                    isList === "map" 
                        ? <MapBoxQuestCreatev2 
                            width="68vw" 
                            markers={markers}
                            //viewport={viewport}
                            isEditableLocs={isEditableLocs}
                            //setViewport={setViewport} 
                            setMapView={setMapView}
                            mapView={mapView}
                            addMarker={addMarker} 
                            onDragUpdate={handleOnDragUpdate} 
                            updateMarker={handleMarkerUpdate} 
                            updateMarkerRadius={handleMarkerRadiusUpdate}
                            deleteMarker={handleDeleteMarker} 
                            selectMarker={selectMarker}
                            selectedMarker={selectedMarker}
                            clearSelected={clearSelected}
                            questImage={questDetails.quest_image}
                            />
                            
                    : <div>
                        <TokenTable //Use reducer would be a good option for this
                            markers={markers}
                            isEditableLocs={isEditableLocs}
                            handleMarkerTableNameUpdate={handleMarkerTableNameUpdate}
                            handleMarkerTableDecripUpdate={handleMarkerTableDecripUpdate}
                            handleMarkerTableRadiusUpdate={handleMarkerTableRadiusUpdate}
                            handleMarkerTableLatUpdate={handleMarkerTableLatUpdate}
                            handleMarkerTableLongUpdate={handleMarkerTableLongUpdate}
                            handleTableMarkerDelete={handleTableMarkerDelete}
                            AddMarkerTable={AddMarkerTable}
                        />

                        <AddLocationContainer>
                            <ButtonIcon 
                                shaded variant="brand" 
                                size="large" 
                                icon={<AddLocationAltIcon/>} 
                                onClick={(event, data)=>AddMarkerTable(event, data)} 
                                disabled={!isEditableLocs}
                                />
                        </AddLocationContainer>
                        
                        {/*Need add marker button / tool tips above the columns / Need delete button / increment button for radius*/}
                    </div>
                    }
            >
            </Card>
                <Modal
                    id="stripe-modal"
                    title="Payment"
                    isOpen={stripeModal}
                    onRequestClose={()=> setStripeModal(false)}>
                    
                    <div style={{display:"flex", justifyContent: "center", alignItems:"center"}}>
                        <StripePayment stripe={stripe} elements={elements} questID={questDetails.id}/>
                    </div>
            </Modal>
        </div>
    );
}

export default UpdateQuestLayout;