import React from 'react';
import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import LocalActivityRoundedIcon from '@mui/icons-material/LocalActivityRounded';
import HelpCenterRoundedIcon from '@mui/icons-material/HelpCenterRounded';
import MyText from '../../MyText';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useQuestContext } from '../../../context/QuestContext';
import RaffleTimer from '../../common/RaffleTimer';

function DetailsCard({tokenClick, prizeClick, instructionsClick}) {

    const {selectedQuest} = useQuestContext()
    const goal = selectedQuest?.goal?.[0];
    const endDate = goal?.goal_raffle_date;
    const isRedeemable = goal?.goal_complete;
    const hasEntries = goal?.entries > 0;
    const numTokens = selectedQuest?.tokens?.length;
    const numTokensCollected = selectedQuest?.collected_count;
    console.log("endDate", endDate)

    const Button = (
        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", fontSize:10}}>
                <div style={{padding:5}}>Open in:</div>
            <div style={{height:18, width:"70%", borderRadius:4, fontSize:14, display:"flex", alignItems:"center", justifyContent:"center", fontWeight:"bolder" }}>
               <RaffleTimer endDate={endDate} scale={0.40} subTitle={false}/>
            </div>
        </div>
    )

    const openButton = {
        height:"20%", 
        width:"75%", 
        borderRadius:10, 
        backgroundColor: goal?.entries > 0 ? "deeppink" : "#CCCCCC",
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center", 
        color:"white", 
        margin:"10%"
    }

    const Button2  = (
        <div 
            style={openButton}
            onClick={hasEntries ? prizeClick : null}
        >
            <MyText fontSize={12} color="white">
                Open Prize
            </MyText>
        </div>
    )

    return (
        <div style={{display:"flex", justifyContent:"center"}}>

            <div style={cardContainer} onClick={tokenClick}>
                <RoomRoundedIcon style={{fontSize:30, color:"gold", filter: "drop-shadow(3px 5px 3px grey)"}}/>
                <MyText fontWeight="bold">{`${numTokensCollected}/${numTokens}`}</MyText>
                <div style={{fontSize:10, color:"grey"}}>Locations</div>
                <div style={{position:"absolute", right:0}}>
                    <ArrowForwardIosRoundedIcon style={{color:"#EEEEEE"}}/>
                </div>
            </div>

            <div style={cardContainer}>
                <LocalActivityRoundedIcon style={{fontSize:30, color:"deeppink", filter: "drop-shadow(3px 5px 3px grey)"}}/>
                <div style={bubbleCounter}>
                    <MyText fontSize={14} color="white">{selectedQuest?.goal?.[0]?.entries}</MyText>
                </div>
                {isRedeemable ? Button2 : Button}
            </div>

            <div 
                style={cardContainer}
                onClick={instructionsClick}
            
            >
                <HelpCenterRoundedIcon style={{fontSize:30, color:"#6600FF", filter: "drop-shadow(2px 3px 2px grey)"}}/>
                <div style={{fontSize:13, color:"grey"}}>Info</div>
                <div style={{position:"absolute", right:0}}>
                    <ArrowForwardIosRoundedIcon style={{color:"#EEEEEE"}}/>
                </div>
            </div>

        </div>
    );
}

export default DetailsCard;

const cardContainer = {
    height:100, 
    width:100, 
    borderRadius:15, 
    backgroundColor:"white", 
    margin:15, 
    display:"flex", 
    justifyContent:"center", 
    flexDirection:"column",
    alignItems:"center",
    borderStyle:"solid",
    borderColor:"#EEEEEE",
    borderWidth:1,
    position:"relative", 
    boxShadow:"3px 5px 3px #EEEEEE"
}

const bubbleCounter = {
    height: 25, 
    width:25, 
    borderRadius:"50%", 
    position:"absolute", 
    top:-5, 
    right:-5, 
    backgroundColor:"deeppink", 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center", 
    display:"flex"
}

