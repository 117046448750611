import React from 'react';
import styled from "styled-components";
import {Input} from "react-rainbow-components"
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import MyText from '../../MyText';

const Wrapper = styled.div`
    display:flex;
    height: calc(100vh - 56px);
    width:100vw;
    background:deeppink;
    flex-direction: column;
`;

function TestAuth(props) {
    return (
        <Wrapper>
        <div style={header}>
            <MyText color="white" fontWeight="bolder" fontSize={36}>
                sideQuest
            </MyText>
        </div>

        <div style={body}>

            <div style={{color:"red"}}>{{/*apiError*/}}</div>

            <Input
                id="123"
                style={inputStyle}
                type="email"
                name="username"
                icon={<EmailTwoToneIcon/>}
                placeholder={"email"}
                //value={authData.username}
                //onChange={handleChange}
                //onBlur={handleValidate}
                //error={errors.username}
            >
            </Input>

            <Input
                id="password"
                style={inputStyle}
                name="password"
                icon={<LockTwoToneIcon/>}
                type={"password"}
                placeholder={"password"}
                //value={authData.password}
                //onChange={handleChange}
                //onBlur={handleValidate}
                //error={errors.password}
            >
            </Input>

            {//isSignup && 
            <Input
                id="repassword"
                style={inputStyle}
                type={"password"}
                name="repassword"
                icon={<LockTwoToneIcon/>}
                placeholder={"re-password"}
                //value={authData.repassword}
                //onChange={handleChange}
                //onBlur={handleValidate}
                //error={errors.repassword && "passwords dont match"}
            >
            </Input>}

            <div 
                style={submitButtonStyle}
                //onClick={handleSubmit}
            >
                submit
            </div>

            <div
                //nClick={()=> setIsSignup(!isSignup) }
            >
                {/*{isSignup ? "sign-in" : "sign-up"}*/}

            </div>
        </div>

    </Wrapper>
    );
}

export default TestAuth;

const header = {
    height:"30%", 
    width:"100%", 
    backgroundColor:"deeppink",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center", 
    alignItems:"center"
}

const body = {
    height:"70%", 
    width:"100%", 
    borderTopLeftRadius:20,
    borderTopRightRadius:20,
    backgroundColor:"white",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-evenly", 
    alignItems:"center",
}

const inputStyle = {
    width:"75%",
}

const submitButtonStyle = {
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    margin:15,
    height:40, 
    width:"50%", 
    borderRadius:100,
    backgroundColor:"deeppink", 
    color:"white",
}