import React, {useEffect} from 'react';
import WebFont from 'webfontloader';


function NormalText(props) {

    useEffect(() => {
        WebFont.load({
          google: {
            families: ['Mitr']
          }
        });
       }, []);

    const textStyle = {
        fontFamily:'Mitr', 
        fontSize:props.fontSize, 
        color:props?.color ? props.color : "grey", 
        textAlign: props.align ? props.align : "center", 
        display:'-webkit-box', 
        WebkitBoxOrient: 'vertical',
        flexWrap:"wrap", 
        wordBreak:"break-word", 
        WebkitLineClamp: props.maxLines, 
        overflow: 'hidden'
    }

    return (
        <div style={textStyle}>
            {props.children}
        </div>
    );
}

export default React.memo(NormalText);