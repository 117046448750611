import React, {useState, useEffect} from "react";
import './App.css';
import WebFont from 'webfontloader';
import axios from "axios";
import { Route, Routes, Navigate} from 'react-router-dom';
import NavBarRB from "./components/NavBarRB";
import LogOut from "./components/LogOut";
import HomeLayout from "./components/layouts/HomeLayout";
import QuestDashboardV2 from "./components/create/QuestDashboardV2";
import UpdateQuestLayout from "./components/UpdateQuestLayout";
import LoginCard from "./components/LoginCard";
import CreateQuestLayout2 from "./layouts/CreateQuestLayout2";
import {Elements} from '@stripe/react-stripe-js'; //Stripe
import {loadStripe} from '@stripe/stripe-js'; //Stripe
import httpService from "./API/Client";
import URL_ROOT from "./components/URL_ROOT";
import RedeemLayout from "./layouts/RedeemLayout";
import PlayLayout from "./layouts/PlayLayout";
import QuestListLayout from "./layouts/QuestListLayout";
import { useQuestContext } from "./context/QuestContext";
import StyledAnimTest from "./components/play/StyledAnimTest";
import TestMyDrawer from "./components/play/TestMyDrawer";
import ZoomToMarker from "./components/play/ZoomToMarker";
import {UserLocContextProvider} from "./context/UserLocContext";
import AuthContextProvider, { useAuthContext } from "./context/AuthContext";
import { handleLogout as handleLogOutMethod } from "./auth/Handlers";
import SideDrawer from "./components/play/desktop/SideDrawer";
import MobileAuthLayout from "./components/play/mobile/MobileAuthLayout";
import MediaQuery from './components/common/MediaQuery';
import TestAuth from "./components/play/mobile/TestAuth";
import TestPrize from "./components/play/mobile/TestPrize";
import MyQuestDetailsDrawer from "./components/play/mobile/MyQuestDetailsDrawer";
import ComingSoonLayout from "./components/layouts/ComingSoonLayout";
import LoadingIcon from "./components/common/LoadingIcon";
import { BASE_URL } from "./API/GeneralVars";
import RaffleLoadingIcon from "./components/common/RaffleLoadingIcon";
import PrizeMedallion from "./components/common/PrizeMedallion";
import CreateSideBar from "./components/create/CreateDskSideBar";
import CreateQuestCard from "./components/create/CreateQuestCard";
import CreateQuestDetailsLayout from "./components/create/CreateQuestDetailsLayout";
import TestSideBar from './components/create/CreateDskSideBar';
import UpdateQuestLayoutV2 from "./components/edit quest/UpdateQuestLayoutV2";
import CreateQuestLayout from "./components/create/create/CreateQuestLayout";
import HomeLayoutV2 from "./components/layouts/HomeLayoutV2";
import ReactMap from "./pages/play/map/ReactMap";
import PlayPage from "./pages/play/PlayPage";
import { QuestContextProvider } from "./pages/play/context/questContext";
import { QuestContextProvider as QuestContextProviderOld } from './context/QuestContext';
import { RandomPrizeContextProvider } from "./pages/play/context/randomPrizeContext";
import TabBar from "./pages/play/components/common/TabBar";
import WalletPage from "./pages/play/components/common/WalletPage";
import SettingsPage from "./pages/play/components/common/SettingsPage";
import PrizeDetails from "./pages/play/components/common/PrizeDetails";
import RedeemPrize from "./pages/play/components/common/RedeemPrize";
import { WonPrizeContextProvider } from "./pages/play/context/wonPrizeContext";
import EmailPrizePage from "./pages/play/components/common/EmailPrizePage";
import useIsMobile from "./pages/play/utils/useIsMobile";
import useIsTablet from "./pages/play/utils/useIsTablet";
import { tabBarHeight } from "./pages/play/utils/stdVariables";


/* 
TODOS
  1. Move context to index.js so that quest data can be refreshed in app.js with usecontext
  2. Use bundle splitting around the routes for play and create. 
*/

const stripePromise = loadStripe('pk_test_YKBaV6N8RE4ZDiOTVlvhljs8') //Stipe
const AUTH_TOKEN = "Token 87e74b1af23ed3f214ed38bc86c58dc814d9f836";//Dont think this is being used 
axios.defaults.baseURL = 'http://6b40-142-165-85-130.ngrok.io/'; //Dont think this is being used. 
axios.defaults.headers.common['Authorization'] = AUTH_TOKEN; //Better than adding the header to all functions


function App() {
  
  const {authObj, refreshAuth} = useAuthContext();
  //const {refreshQuestData} = useQuestContext() //This was hidden on March 21, 2023

  const isTablet = useIsTablet()
  const isMobile = useIsMobile()

  function handleLogOut() { //TODO Remove this and replace with useAuthContext in the components requiring it. 
    handleLogOutMethod()
    refreshAuth()
  }

  function NotFound(){

    return(
      <h1>Page Not Found</h1>
    )
  }

  //const Play = lazy(() => import('./layouts/PlayLayout')); //Not breaking up webpack at the moment
  
  //TODO: This should be moved to a seperate hook. 
  const [clientSecret, setClientSecret] = useState(null);
  const options = clientSecret //Stripe

  const getSecret = async () => {
    const response = await httpService("GET", `${BASE_URL}/api/create_payment/`);  
    setClientSecret({clientSecret:response?.data?.clientSecret})
  }

  useEffect(()=>{
    getSecret()
  }, [])

  useEffect(() => { 
    WebFont.load({
      google: {
        families: ['Shippori Antique B1']
      }
    });
    //not used font: 'Secular One','ABeeZee', "Fredoka One","Titan One","Palanquin Dark", "Odor Mean Chey", 'Modak', 'Rowdies', 'Oi'
  }, []);


  if(!options){
    return (
      <div>
        <LoadingIcon/>
      </div>
    )
  }

  return (
    
    <div>

      {/*isMobile ? <TabBar/> : <NavBarRB/>*/}

        {/*<Routes>
          <Route path="/maptest" element={<TabBar/>}/>
          <Route path="*" element={<NavBarRB/>}/>
        </Routes>*/}
        
        {/*<NavBarRB/>*/}

        <div >
          <Elements stripe={stripePromise} options={options}>

          {/*<QuestContextProviderOld>*/}
          {/*<UserLocContextProvider>*/} {/*TODO Remove this outside of play*/}

          <Routes>
            <Route path="/logout" element={<LogOut doLogOut={handleLogOut}/>}/>
            {/*<Route path="/test21" element={isMobile ? <MobileAuthLayout/> : <LoginCard getAuth={refreshAuth}/>}/>
            <Route path="/auth" element={<MobileAuthLayout/>}/>
            <Route path="/create" element={authObj.authenticated ? <QuestDashboardV2 doLogOut={handleLogOut}/> : <MobileAuthLayout/>}>
              <Route path="quest" element={<CreateQuestDetailsLayout/>}/>
              <Route path="quest/update" element={<UpdateQuestLayout/>}/>
              <Route path="build_quest" element={<CreateQuestLayout/>}/>
            </Route>
            {/*<Route path="/create" element={authObj.authenticated ? <QuestDashboardV2 doLogOut={handleLogOut}/> : <LoginCard getAuth={refreshAuth}/>}/>*/}
            {/*<Route path="/create" element={<ComingSoonLayout/>}/>*/}
            {/*<Route path="/redeem/:id" element={<RedeemLayout/>}/>*/}
            {/*<Route path="/test5" element={<QuestDetails/>}/>*/}
            {/*<Route path="update" element={<UpdateQuestLayout/>}/>*/}
            {/*<Route path="update" element={<UpdateQuestLayoutV2/>}/>

            {/*<Route path="/play" element={<PlayLayout/>}>

            {/*<Route path="/play" element={
              <React.Suspense fallback={<LoadingIcon/>}>
                 <Play/>
               </React.Suspense>
            }>*/

            /*<Route path="questlist" element={<QuestListLayout/>}/>
            </Route>*/}

            {/*<Route path="/test7" element={authObj.authenticated ? <CreateQuestLayout2 doLogOut={handleLogOut}/> : <LoginCard getAuth={refreshAuth}/> }/>
            <Route path="/make_quest" element={authObj.authenticated ? <CreateQuestLayout doLogOut={handleLogOut}/> : <LoginCard getAuth={refreshAuth}/> }/>
            <Route path="/test_drawer" element={<MyQuestDetailsDrawer/>}/>
            <Route path="/zoom" element={<ZoomToMarker/>}/>
            {/*<Route path="/test8" element={<CreateQuestCard/>}/>*/}
            {/*<Route path="/test9" element={<CreateQuestDetailsLayout/>}/>
            <Route path="/deskdrawer" element={<SideDrawer/>}/>

            




            <Route path="/test" element={<PrizeMedallion/>}/>
            {/*<Route path="/quest" element={<CreateQuestDetailsLayout/>}/>*/}
            <Route path="/loading" element={
              <React.Suspense fallback={<>...</>}>
                <LoadingIcon/>
              </React.Suspense>}/>

            {/*<Route path="/" element={<HomeLayoutV2/>}/>*/}
            {/*<Route path="/home" element={<HomeLayoutV2/>}/>*/}
            <Route path="*" element={<NotFound/>}/>

            {/*TODO render blank component for mapTest*/}
            <Route path="/" element={<></>}/>
            <Route path="/home" element={<></>}/>
            <Route path="/play" element={<></>}/>
            <Route path="/wallet" element={<></>}/>
            <Route path="/settings" element={<></>}/>
            <Route path="/prize" element={<></>}/>
            <Route path="prize/email" element={<></>}/>
            <Route path="prize/qr" element={<></>}/>
            <Route path="/authenticate" element={<></>}/>
            <Route path="/redeem/:uuid/:user" element={<></>}/>
          </Routes>

          {/*</UserLocContextProvider>*/}
          {/*</QuestContextProviderOld>*/}


          {/*PLAY APP ROUTES*/}
          <UserLocContextProvider>
            <QuestContextProvider>
            <RandomPrizeContextProvider>
            <WonPrizeContextProvider>
              {isMobile || isTablet ? <TabBar/> : <NavBarRB/>}
              <Routes>
                <Route path="/" element={<Navigate to="/play"/>}/> {/*Set the default home page*/}
                <Route path="/home" element={<HomeLayoutV2/>}/>
                <Route path="/play" element={<PlayPage/>}/>
                <Route path="/authenticate" element={<MobileAuthLayout/>}/>
                <Route path="/wallet" element={<WalletPage/>}/>
                <Route path="/settings" element={<SettingsPage/>}/>
                <Route path="prize" element={<PrizeDetails/>}/>
                <Route path="prize/qr" element={<PrizeDetails/>}/> {/*TODO this needs to be nested properly*/}
                <Route path="prize/email" element={<EmailPrizePage/>}/> {/*TODO this needs to be nested properly*/}
                <Route path="/redeem/:uuid/:user" element={<RedeemPrize/>}/>
              </Routes>
            </WonPrizeContextProvider>
            </RandomPrizeContextProvider>
            </QuestContextProvider>
          </UserLocContextProvider>

        </Elements>
        </div>

      </div>
  );
}

export default App;

