import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import styled, {keyframes} from "styled-components";
import 'react-spring-bottom-sheet/dist/style.css'; //Required to style the bottomsheet
import { useQuestContext } from '../../../context/QuestContext';
import RaffleTimer from '../../common/RaffleTimer';
import { Accordion, AccordionSection } from 'react-rainbow-components';
import {ReactComponent as MapMarker} from "../../../Images/Token Marker - No Drop Shadow.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faGift, faTicketAlt, faCheckCircle, faHeartBroken } from '@fortawesome/free-solid-svg-icons';
import CheckWinApi from '../../../API/CheckWinApi';
import RaffleLoadingIcon from '../../common/RaffleLoadingIcon';


const rotate = keyframes`
  from {
    transform: scale(0.3);
  }

  to {
    transform: scale(1);
  }
`;

const Modal = styled.div`
    height:250px;
    width:250px;
    background:white;
    animation: ${rotate} 0.15s ease-in;
    display: flex; 
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; 
    border-radius:15px;
    padding: 10px;
    box-shadow: 1px 2px 9px grey;
`


function BottSheetDetailsV3({handlePrizeIcon}) {

    console.log("Bottomsheet rendered")
    const {selectedQuest, updatePrizes} = useQuestContext()
    const [isOpen, setIsOpen] = useState(false);
    const [result, setResult] = useState(null);
    const [isCheckWinLoading, setIsCheckWinLoading] = useState(false);
    const [open, setOpen] = useState(true);
    //const [showWinIcon, setShowWinIcon] = useState(false);
    const portal = document.getElementById('portal');
    const isSingleLoc = selectedQuest?.tokens?.length === 1;


    function closeDrawer(){
        setOpen(false)
    }

    useEffect(()=>{
        if(selectedQuest != null && Object.keys(selectedQuest).length > 0){
            setOpen(true)
        } else{setOpen(false)}
    }, [selectedQuest])


    function NumEntries(){

        return(
            <div style={style}>
                {selectedQuest?.goal?.[0]?.entries}
            </div>
        )
    }

    function TokenListItem({token}){

        const iconColumn = {
            height:"100%", 
            width:"10%", 
            display:"flex", 
            padding:3,
            justifyContent:"center",
            //backgroundColor:"green", 
        }

        const detailsColumn = {
            height:"100%", 
            width:"85%", 
            display:"flex", 
            justifyContent:"flex-start",
            flexDirection:"column",
            //backgroundColor:"red"
        }

        const container = {
            display: "flex",
            position:"relative",
            width: "100%", 
            flexDirection:"row", 
            alignItems:"center",
            justifyContent:"flex-start",
            alignItems:"flex-start",
            padding:10,
            //backgroundColor:"gold"
        }

        return(

            <div style={container}>
                <div style={iconColumn}> 
                    {token?.token_collected_count > 0 && <FontAwesomeIcon icon={faCheckCircle} style={{color: "deeppink", fontSize:16}}/>}
                </div>
                <div style={detailsColumn}>
                    <div style={{color:"black", fontWeight:"bold"}}>{token?.token_name}</div>
                    <div>{token?.token_description}</div>
                </div>
            </div>
        )
    }

    function CheckWinPopup(){

        const container = {
            position: "fixed", 
            top:0,
            bottom:0, 
            left:0, 
            right:0,
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            backgroundColor:"rgb(220, 220, 220, 0.55)"
        }

        const card = {
            height: 300, 
            width: 300,
            display:"flex", 
            position: "absolute",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"space-around",  
            borderRadius:15,
            padding:10,
            backgroundColor:"white", 
            boxShadow: '1px 2px 9px grey'
        }

        const button = {
            height:40, 
            width:"50%",
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            borderRadius:50, 
            backgroundColor:"deeppink", 
            fontWeight:"bolder"
        }

        const imageContainer = {
            position:"absolute", 
            display: "flex", 
            justifyContent:"center", 
            alignItems:"center", 
            top:-40, 
            height:80, 
            width:80, 
            borderRadius:50,
            borderWidth:5, 
            borderColor:"gold", 
            borderStyle:"solid",
            backgroundColor:"white", 
        }

        const resultText = {
            fontSize:24, 
            color:"deeppink", 
            fontWeight:"bold",
            textAlign: "center", 
            marginTop:15
        }

        const prizeText = {
            fontSize:20, 
            fontWeight:"bolder", 
            color: "dodgerblue",
            display:"flex", 
            flexDirection: "column",
            alignItems:"center"
        }

        const tipText = {
            fontSize:14, 
            color:"grey", 
            display:"flex", 
            textAlign:"center"
        }

        return(
            ReactDOM.createPortal(
                isOpen && 

                    <div style={container}>

                        {isCheckWinLoading ? <RaffleLoadingIcon/> :

                        <Modal>
                
                            <div style={{...imageContainer, fontAlign:""}}>
                                <FontAwesomeIcon 
                                    icon={result?.entrant_winner ? faGift : faHeartBroken} 
                                    style={{fontSize:32, color:"deeppink"}}/>
                            </div>

                            <div style={resultText}>
                                {result?.entrant_winner ? "Congrats! You won" : "Sorry, this one wasn't a winner"}
                            </div>

                            {result?.entrant_winner && <div style={prizeText}>

                                {result?.entrant_prize?.prize_name}

                            </div>}

                                <div style={tipText}>
                                    {result?.entrant_winner ? "We'll put this prize in your wallet for you to redeem later" : "Better luck next time"}
                                </div>


                            <div style={button}  onClick={() =>checkWinClose()}>OK</div>
                            
                        </Modal>
                        }

                    </div>,
                portal
            )
        )
    }

    function selectGoalCard(){
        
        const isRedeemable = selectedQuest?.goal?.[0]?.entries > 0 && selectedQuest?.goal?.[0]?.goal_complete === true; 
        const goalAwardStyle = selectedQuest?.goal?.[0]?.goal_award_style;
        const isGoalAwarded = selectedQuest?.goal?.[0]?.goal_complete;

        switch(isRedeemable){

            case(true):
                if(goalAwardStyle == "RAFF") return <RedeemableRaffleCard/>
            
            case(false):                
                if( goalAwardStyle == "RAFF") return <NotRedeemableRaffleCard/>
        }
    }


    function DidntWinCard() {

        return (
            <div style={{...goalCard, backgroundColor:"#EEEEEE"}}>

                <FontAwesomeIcon 
                    icon={faStar} 
                    style={{fontSize:17, color:"grey"}}
                />

                <div style={{paddingLeft:10, color:"black"}}>
                    Sorry, better luck next time.
                </div>

            </div>
        )
    }

    function NotRedeemableRaffleCard(){
        
        const isGoalAwarded = selectedQuest?.goal?.[0]?.goal_complete;

        return(
            <>
                { isGoalAwarded ? <DidntWinCard/> : 
                
                <div style={goalCard}>

                    <FontAwesomeIcon 
                        icon={faStar} 
                        style={{fontSize:17, color:"deeppink"}}
                    />

                    <div 
                        style={{paddingLeft:10, color:"black"}}>
                            Raffle in: 
                    </div> 

                    <RaffleTimer 
                        scale={0.7} 
                        endDate={selectedQuest?.quest_end} 
                        subTitle={true} 
                        raffleStatus={false} 
                        colour="#f9f9f9"
                    />

                </div>}

                <div style={{...goalCard, marginTop:4, marginBottom:15}}>

                    <FontAwesomeIcon 
                        icon={faTicketAlt} 
                        style={{fontSize:17, color:"deeppink"}}
                    />

                    <div style={{color:"black", paddingLeft:10, display:"flex", alignItems:"center"}}>

                        You have <NumEntries/> raffle entry(s)

                    </div> 

                </div>
            </>
        )
    }

    function RedeemableRaffleCard(){

        return(
            <>
                <div style={{...goalCard, backgroundColor:"gold"}} /*onClick={()=>setIsOpen(true)}*/ onClick={()=>checkWin()}>

                    <FontAwesomeIcon 
                        icon={faStar} 
                        style={{fontSize:17, color:"deeppink"}}
                    />

                    <div>
                        <div style={{paddingLeft:10, color:"black"}}>
                            Raffle Complete! 
                        </div>
                        <div style={{paddingLeft:10, color:"grey", fontSize:14}}>
                            Check if you won
                        </div>
                    </div> 

                </div>

                <div style={{...goalCard, marginTop:4, marginBottom:15}}>
                    <FontAwesomeIcon icon={faTicketAlt} style={{fontSize:17, color:"deeppink"}}/>
                    <div style={{color:"black", paddingLeft:10, display:"flex", alignItems:"center"}}>You have <NumEntries/> raffle entry(s)</div> 
                </div>
            </>
        )
    }


    function HowToPlay(){

        return(
            <>
                <div style={header}>How to Play</div>
                        
                <Accordion style={{width:"90%"}}>
                    <AccordionSection 
                        style={accordionSectionStyle} 
                        label={"Visit these locations to earn raffle entries"}
                        icon={<MapMarker height={20} fill={"deeppink"}/>}
                    >
                        {selectedQuest?.tokens?.map(token=> (<TokenListItem token={token}/>))}
                    </AccordionSection>
                </Accordion>
            </>
        )
    }


    function YouCouldWin(){

        return(
            <>
                <div style={{...header}}>You Could Win</div>

                <Accordion style={{width:"90%"}}>
                    <AccordionSection 
                        style={accordionSectionStyle} 
                        icon={<FontAwesomeIcon icon={faGift} style={{color:"deeppink"}}/>} 
                        label={"You could win these prizes"}
                    >
                            {selectedQuest?.goal?.[0]?.prize?.map(prize=> (<PrizeListItem prize={prize}/>))}
                    </AccordionSection>
                </Accordion>
            </>
        )
    }


    function Details(){

        return (
            <>
                <div style={header}>Details</div>

                {selectedQuest?.quest_description?.length > 0 && 

                    <div style={descriptionContainer}>
                        {selectedQuest?.quest_description}
                    </div>
                }
            </>
        )
    }


    async function checkWin(){

        if(isCheckWinLoading === true) return //prevents multiple taps at once

        setIsCheckWinLoading(true) 
        setIsOpen(true)
        const goalID = selectedQuest?.goal?.[0]?.id;
        const result = await CheckWinApi(goalID);         
        setResult(result.data)
        setIsCheckWinLoading(false)
           
    }

    function checkWinClose(){

        console.log("checkWinClose start")
        setIsOpen(false)   
        updatePrizes(selectedQuest?.goal?.[0]?.id)
        setResult(null)
        if(result?.entrant_winner !== true) return
        handlePrizeIcon()
    }

    function PrizeListItem({prize}){

        const prizeCardContainer = {
            display: "flex",
            position:"relative",
            width: "100%", 
            alignItems:"center",
            justifyContent:"center",
            padding:10,
        }

        const prizeCardName = {
            width:"80%", 
        }

        const prizeCardQuantity = {
            display:"flex", 
            flexDirection:"column",
            alignItems:"center",
        }

        return(

            <div style={prizeCardContainer}>
                
                <div style={prizeCardName}>{prize?.prize_name}</div>

                <div style={prizeCardQuantity}>
                    <div style={{fontSize:14, fontWeight:"bolder", color:"deeppink"}}>{prize?.prize_quantity}</div>
                    <div style={{fontSize:12, color:"grey"}}>Available</div>
                </div>

            </div>
        )
    }

    return (

        <div style={container}> {/*ensure the details sheet renders on top of the list sheet*/}
            
            <Details/>
            
            {!isSingleLoc && <HowToPlay/>}

            <YouCouldWin/>

            {selectGoalCard()}

            <CheckWinPopup/>

        </div>        
    );
}

export default BottSheetDetailsV3 = React.memo(BottSheetDetailsV3);

const container = {
    //zIndex:100, 
    display:"flex", 
    flexDirection:"column", 
    width:"100%", 
    alignItems:"center", 
    backgroundColor:"#f9f9f9", 
    paddingTop:10
}

const header = {
    width:"90%", 
    fontSize:15, 
    fontWeight:"bolder", 
    paddingTop:15    
}

const descriptionContainer = {
    width:"90%", 
    backgroundColor:"white",
    borderRadius:10,    
    padding:10,
    fontSize:14,
    borderWidth:1,
    borderStyle:"solid", 
    borderColor:"#EEEEEE",
    display:"flex", 
    flexDirection:"column",
    justifyContent:"center",
    boxShadow: '1px 2px 9px #CCCCCC', 
}

const accordionSectionStyle = {
    boxShadow: '1px 2px 9px #CCCCCC', 
    color:"grey", 
}

const goalCard = {
    backgroundColor:"white", 
    width:"90%", 
    borderRadius:10, 
    display:"flex", 
    alignItems:"center", 
    padding:10, 
    fontWeight:"bolder", 
    paddingLeft:45, 
    borderWidth:1, 
    borderStyle:"solid", 
    borderColor:"#DDDDDD", 
    boxShadow: '1px 2px 9px #CCCCCC'
}

const style = {
    height:30, 
    width:30, 
    borderRadius:10, 
    backgroundColor:"#f9f9f9", 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center",
    margin:5,
    fontWeight:"bold", 
    fontSize:19,
    color:"deeppink"
}
