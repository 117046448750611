import styled from "styled-components";
    
    const ModalBody = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: flex-start;
    padding:10; 
    background: dodgerblue;
        overflow: scroll;
        &::-webkit-scrollbar {
            width: 0px;
            border: 0px white;
        }
    `;

    export {ModalBody}

