import React, {useEffect} from 'react';
import WebFont from 'webfontloader';


function HeadingText(props) {

    useEffect(() => {
        WebFont.load({
          google: {
            families: ['Mitr', 'Modak', 'Rowdies', 'Oi']
          }
        });
       }, []);


    const textStyle = {
        display:"flex",
        fontFamily:'Modak', 
        fontSize:props.fontSize, 
        color: props.color ? props.color : "black", 
        textAlign: props.align ? props.align : "center", 
        display:'-webkit-box', 
        WebkitBoxOrient: 'vertical',
        flexWrap:"wrap", 
        wordBreak:"break-word", 
        WebkitLineClamp: props.maxLines, 
        overflow: 'hidden',
        whiteSpace: props.maxLines > 1 ? "normal" : "nowrap",        
    }
    

    return (
        <div style={textStyle}>
            {props.children}
        </div>
    );
}

export default HeadingText;