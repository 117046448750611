import React from 'react';
import MyText from '../../MyText';
import CheckMarkBubble from './CheckMarkBubble';

function TokenListItem({token}) {

    const isCollected = token?.token_collected_count > 0; 

    return (
        <div style={{display:"flex", width:"90%", flexDirection:"column", backgroundColor:"white", alignItems:"flex-start", padding:20}}>
            <div style={{display:"flex", flexDirection:"row"}}>
                {isCollected && <CheckMarkBubble/>}
                <div style={{display:"flex", flexDirection:"column", marginLeft: isCollected ? 0 : 20}}>
                    <MyText align="left" fontSize={20} fontWeight="bolder">{token?.token_name}</MyText>
                    <MyText align="left" fontSize={14} color="grey">{token?.token_description}</MyText>
                </div>
            </div>
        </div>
    );
}

export default TokenListItem;