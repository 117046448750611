import React from 'react';
import {Marker} from "react-map-gl";
import MapDefault from "../Images/MapDefault.png";
import {ReactComponent as MapMarkerSVG2} from "../Images/QuestMapMarkerV1.svg";
import styled from "styled-components"; 
import HeadingText from './HeadingText';
import { useQuestContext } from '../context/QuestContext';
import  isEmpty  from 'lodash/isEmpty';
import setQuestViewedApi from '../API/SetQuestViewedApi';


const MarkerPopup = styled.div`
    padding:2px;
    text-align:center; 
    scale: 0;
    box-shadow:2px 3px 5px grey;
    background : white; 
    width: ${props => `${props.width}px`};
    border-radius: ${props => `${props.lines*5}px`};
    position: absolute; 
    top: ${props => props.lines === 1 ? "-65px" : "-75px"};
    right:${props => `${-props.width/2}px`}; 
    transform: scale(1.0);
    transition: all 0.4s ease;
`;

const CollectBadge = styled.div`
    position: absolute; 
    height:  15px; 
    width: 15px; 
    border-radius:10px;
    border-width: 2px;
    border-color: white;
    border-style: solid;
    background: deeppink; 
    top: ${props => props.isSelected ? "-42px" : "-32px"};
    right: -22px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    transform: ${props => props.isSelected ? "scale(1.0)" : "scale(0.6)"};
`;


function QuestLocationMarkerV1({quest, handleTap}) {
    
    console.log("questMarker rerendered")
    const {selectedQuest, updateQuestViewed, quests} = useQuestContext()
    const isSelected = quest?.questID === selectedQuest?.id;
    const SIZE = 60; 
    const quest_ID = {quest_id: quest.questID} //Need to modify so it works with the tap function. 
    const showQuestTitle = isSelected || isEmpty(selectedQuest) && true

    console.log("QuestLocationMarker", quest)

    async function handleMarkerTap(questID){
        //dont need to revert if the call is unsuccessfucl
        handleTap(quest_ID)
        if(quest?.questViewed) return
        updateQuestViewed(questID)
        setQuestViewedApi(questID)
    }

    const imageContainer = {
        height: isSelected ? 20 : 10, 
        width: isSelected ? 20 : 10, 
        borderRadius: 15, 
        backgroundColor:"white",
        position:"absolute", 
        top: isSelected ? -30 : -22.5, 
        left: isSelected ? -8.5 : -1.5,
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
    }
    
    const imageStyle = {
        height: isSelected ? 20 : 12, 
        width: isSelected ? 20 : 12,   
        borderRadius:10,
        objectFit:"contain",
        top:-10,
    }

    function MarkerPopUp({quest}){

        const leng = quest?.questName?.length;
        let width;
        let lines;

        if(leng <= 25){width = leng * 7.2; lines = 1}
        else{width = 150; lines = 2 }

        return(
            <MarkerPopup width={width} lines={lines}>
                <HeadingText maxLines={2} fontSize={12} color="black">{quest?.questName}</HeadingText>
            </MarkerPopup>
        )
    }

    return (
  
        <Marker
            latitude={quest?.questLat}
            longitude={quest?.questLong}
            key={quest?.questID}
            onClick={() => handleMarkerTap(quest?.questID)}
        >
            <div style={{height:0, width:0}}>
                
                <MapMarkerSVG2 viewBox='0 0 80 80' height={SIZE} fill={"deeppink"} style={{cursor:"pointer", transform: `translate(${-SIZE / 2}px,${-SIZE}px) ${isSelected ? "scale(1)" : "scale(0.5)"}`, position:"relative", top:15, left:6 }}>
                </MapMarkerSVG2>

                    <div style={imageContainer}>
                        <img
                            style={imageStyle}
                            src={quest?.questImage ? quest?.questImage : MapDefault}
                        />
                    </div>
            </div>

            { showQuestTitle && <MarkerPopUp quest={quest}/>}
            {quest?.questViewed ? null : <CollectBadge isSelected={isSelected}/>}

        </Marker>
            
    );
}

export default React.memo(QuestLocationMarkerV1);

