import React, {useState, useEffect} from 'react';
import {Input, Button} from "react-rainbow-components"
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import styled from "styled-components";
import Joi from "joi-browser";
import httpService from '../API/Client';
import URL_ROOT from './URL_ROOT';
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import sideQuestLogo from "../Images/sideQuestLogo.png"
import { handleSignin as handleSigninMethod, handleSignup as handleSignupMethod } from '../auth/Handlers';
import { useAuthContext } from '../context/AuthContext'


//******These function dont belong here.....this should only be for rendering the login card.  */

const signupSchema = {
    username: Joi.string().required().min(5).max(30).email(),
    password: Joi.string().required().min(5).max(15),
    re_password: Joi.ref("password")
};

const signinSchema = {
    username: Joi.string().required().min(5).max(30)/*.email()*/,
    password: Joi.string().required().min(5).max(15),
    re_password: Joi.string().allow("")
};

function LoginCard({getAuth}) {

    const navigate = useNavigate()
    const [auth, setAuth] = useState("signin");
    const {authObj, setAuthenticated, refreshAuth} = useAuthContext();

    const [errors, setErrors] = useState({
        signup:[],
        signin:[]
    });

    const [authData, setAuthData] = useState({
        username:"",
        password:"",
        re_password:""
    })

    function handleSigninChange(event){
        const newData = {...authData};
        newData[event.target.name] = event.target.value

        setAuthData(newData)
    }

    function handleValidate(){
        const options ={abortEarly:false};
        const validateSignin = Joi.validate(authData, signinSchema, options)
        const validateSignup = Joi.validate(authData, signupSchema, options)
        const newData = _.cloneDeep(errors)
        newData.signin = validateSignin?.error?.details
        newData.signup = validateSignup?.error?.details
        setErrors(newData)
    }

    async function handleSignin(){
        await handleSigninMethod(authData)
        refreshAuth()
    }

    function handleSignupChange(event){
        const newData = {...authData};
        newData[event.target.name] = event.target.value
        setAuthData(newData)
    }

    //Same as declaring a const variable that re-calcs on every render...but can be more selective. 
    useEffect(()=>{
        handleValidate()
    }, [authData, auth])

    async function handleSignup(){

        await handleSignupMethod(authData)
        refreshAuth()
    }
    
    return (
       <div style={container}>
           <div style={sideQuestIcon}>

                <img
                    src={sideQuestLogo}
                    style={sideQuestIcon}
                />

           </div>
           {auth === "signup" ?
           <div style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                <Card>
                    {/*<div style={cardColumn}> //Hid until OAuth implimented
                        <Button label="Signup with Facebook" style={buttonLeft} variant="outline brand"/>
                        <Button label="Signup with Twitter" style={buttonLeft} variant="outline brand"/>
                        <Button label="Signup with Google" style={buttonLeft} variant="outline brand"/>
                    </div>*/}
                    <div style={column2}>
                        <Input 
                            style={input} 
                            label="email"
                            name="username"
                            value={authData.username} 
                            type="email" 
                            isCentered 
                            icon={<EmailRoundedIcon style={icon}/>}
                            onChange={handleSignupChange}
                        />
                        <Input 
                            style={input} 
                            label="password"
                            name="password" 
                            type="password" 
                            value={authData.password}
                            isCentered 
                            icon={<LockRoundedIcon style={icon}/>}
                            onChange={handleSignupChange}
                        />
                        <Input 
                            style={input} 
                            label="re-password"
                            name="re_password" 
                            type="password" 
                            value={authData.re_password}
                            isCentered 
                            icon={<LockRoundedIcon style={icon}/>}
                            onChange={handleSignupChange}
                        />
                        <Button 
                            label="Sign-up" 
                            style={buttonRight} 
                            variant="brand"
                            onClick={handleSignup}
                            disabled={errors?.signup?.length >0}
                        />
                        <Button label="Agree to our terms" style={forgotPass} variant="base"/>
                    </div>
                </Card>
                <Button style={{marginTop:15, color:"deeppink"}} label="Sign-in" variant="base" size="large" onClick={()=>setAuth("signin")}/>

            </div>
            :
            <div style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
            <Card>
                {/*<div style={cardColumn}> //Hid until OAuth implimented
                    <Button label="Signup with Facebook" style={buttonLeft} variant="outline brand"/>
                    <Button label="Signup with Twitter" style={buttonLeft} variant="outline brand"/>
                    <Button label="Signup with Google" style={buttonLeft} variant="outline brand"/>
                </div>*/}
                <div style={column2}>
                    <Input 
                        style={input} 
                        label="email" 
                        type="email"
                        name="username" 
                        value={authData.username}
                        isCentered 
                        icon={<EmailRoundedIcon style={icon}/>}
                        onChange={handleSigninChange}
                    />
                    <Input 
                        style={input} 
                        label="password" 
                        type="password"
                        name="password" 
                        value={authData.password}
                        isCentered 
                        onChange={handleSigninChange}
                        icon={<LockRoundedIcon style={icon}/>}
                    />
                    <Button 
                        label="Sign-in" 
                        style={buttonRight} 
                        variant="brand"
                        onClick={handleSignin}
                        disabled={errors?.signin}
                        />
                    <Button 
                        label="Forgot your password?" 
                        style={forgotPass} 
                        variant="base"
                    />
                </div>
            </Card>
            <Button style={{marginTop:15, color:"deeppink"}} label="Sign-up" variant="base" size="large" onClick={()=>setAuth("signup")}/>
            </div>
            }
        </div>  
    );
}

const Card = styled.div`
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    width: 45vw;
    background: white;
    border-radius: 20px;
    display:flex; 
    flex-direction: row;
    padding:2%;
    padding-top:2%;
`;

const container = {
    width:"100vw",
    height:"100vh",
    backgroundColor:"white", 
    display:"flex", 
    flexDirection:"column",
    justifyContent:"center", 
    alignItems:"center"
};

const cardColumn = {
    display:"flex", 
    flexDirection: "column",
    justifyContent:"center",
    alignItems:"center",
    flex:1
};

const column2 = {
    display:"flex", 
    flexDirection: "column",
    flex:1,
    justifyContent:"center",
    alignItems:"center",
};

const input = {
    marginBottom:"3%", 
    width:"80%"
};

const sideQuestIcon = {
    height:200, 
    width:250,
    marginBottom: 0, 
    objectFit:"contain"
}

const buttonRight = {
    marginBottom:"2%",
    width:"80%",
    marginTop:"2%", 
    backgroundColor:"deeppink"
};

const buttonLeft = {
    marginBottom:"3%",
    width:"80%",
    marginTop:"3%",
    color:"deeppink", 
    borderColor:"grey"
};

const forgotPass = {
    color:"grey"
};

const icon = {
    color:"deeppink"
};


export default LoginCard;