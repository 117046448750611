import React from 'react';
import {Marker} from "react-map-gl";
import MapDefault from "../Images/MapDefault.png";
import {ReactComponent as MapMarkerSVG2} from "../Images/MapMarkerSVG-V2-1.svg";
import styled from "styled-components"; 
import { useQuestContext } from '../context/QuestContext';
import setQuestViewedApi from '../API/SetQuestViewedApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faTicketAlt} from '@fortawesome/free-solid-svg-icons';


/*const MarkerPopup = styled.div`
    padding:2px;
    text-align:center; 
    scale: 0;
    box-shadow:2px 3px 5px grey;
    background: ${props => props.isSelected ? "deeppink" : "white"}; 
    width: ${props => `${props.width}px`};
    border-radius: ${props => `${props.lines*5}px`};
    position: absolute; 
    top: ${props => props.lines === 1 ? "-65px" : "-75px"};
    right:${props => `${-props.width/2}px`}; 
    transform: scale(1.0);
    transition: all 0.4s ease;
`;
*/

const CollectBadge = styled.div`
    position: absolute; 
    height:  20px; 
    width: 20px; 
    border-radius:10px;
    border-width: 3px;
    border-color: white;
    border-style: solid;
    background: deeppink; 
    top: -93px;
    right: -53px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    transform: scale(0.6);
`;


function QuestLocationMarkerV2({quest, handleTap}) {
    
    console.log("questMarker rerendered", quest)
    const {selectedQuest, updateQuestViewed, quests} = useQuestContext()
    const isSelected = quest?.questID === selectedQuest?.id;
    const SIZE = 110; 
    const quest_ID = {quest_id: quest.questID} //Need to modify so it works with the tap function. 
    const isSingleLoc = quest?.questNumTokens === 1;

    //This are the default values in the database. 
    const questLocationNotProvided = quest?.questLat == 65 && quest.questLong == -100
    console.log("questLocationNotProvided", quest, questLocationNotProvided)

    const imageContainer = {
        height: 32, 
        width: 32, 
        borderRadius: 20, 
        backgroundColor:"white",
        position:"absolute", 
        top: -85.5, 
        left:  40.5,
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center",
        borderColor:"deeppink", 
        borderWidth:2,
        borderStyle:"solid",
        objectFit:"contain",
    }
    
    const imageStyle = {
        height: 28, 
        width: 28,   
        borderRadius:15, 
        objectFit:"contain",

    }

    const questTitle = {
        position: "absolute", 
        textAlign: "center",
        top: -55.5, 
        left: 15.5,
        fontSize:10,
        fontWeight:"bold",
        overflow: 'hidden',
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width:85,
    }

    const container = {
        display:"flex", 
        justifyContent:"center",
        textAlign:"center",
        cursor:"pointer", 
        transform: `translate(${-SIZE / 2}px,${-SIZE}px) ${"scale(0.55)"}`, 
        position:"relative", 
        top:15, 
        left:6, 
    }

    async function handleMarkerTap(questID){
        //dont need to revert if the call is unsuccessfucl
        handleTap(quest_ID)

        if(quest?.questViewed) return

        updateQuestViewed(questID)
        
        setQuestViewedApi(questID)
    }


    function ProgressBar({quest}){

        const ratio = Math.min(quest?.questNumCollected/quest?.questGoal, 1) || 0;

        const background = {
            height:8, 
            borderRadius:10,
            borderWidth:1,
            borderStyle:"solid", 
            borderColor:"white", 
            position: "absolute", 
            top: -40.5, 
            left: 50.5,
        }

        return(
            <div>
                <div style={{...background, backgroundColor:"#EEEEEE", width:30,}}/>
                {ratio > 0 && <div style={{...background, backgroundColor:"deeppink", width:30 * ratio}}/>}

            </div>
        )
    }

    function HintComponent(){

        const textStyle = { 
            fontSize: 8, 
            paddingLeft:5,
            textAlign: "top",
            alignSelf:"center"
        }

        const questTypeIcon = {
            position: "absolute", 
            top: -42.5, 
            left: 35.5,
            fontSize:12,
            color: isSelected ? "white" : "deeppink",
        }

        const iconStyle = {
            color:isSelected ? "white" : "deeppink", 
            fontSize:12
        }

        const container = {
            position: "absolute", 
            display:"flex", 
            top: -40.5, 
            left: 38.5,
        }

        if(isSingleLoc){ //TODO: Check if raffle goal.  
            return (
                <div style={container}>
                    <FontAwesomeIcon style={iconStyle} icon={faTicketAlt} />
                    <div style={textStyle}>Raffle</div>
                </div>
            )
        }

        else return(
            <>
                <FontAwesomeIcon style={questTypeIcon} icon={faMapMarkerAlt}/>
                <ProgressBar quest={quest}/>
            </>
        )
    }


    return (
  
        <Marker
            latitude={questLocationNotProvided ? quest?.questTokenLat : quest?.questLat}
            longitude={questLocationNotProvided ? quest?.questTokenLong : quest?.questLong}
            key={quest?.questID}
            offsetLeft={-31}
            offsetTop={20}
            onClick={() => handleMarkerTap(quest?.questID)}
        >
            <div style={{height:0, width:0}}>
                
                <MapMarkerSVG2 viewBox='0 0 180 90' height={SIZE} fill={isSelected ? "deeppink" : "white"} style={container}>                
                </MapMarkerSVG2>
            
                <div style={imageContainer}>
                    <img
                        style={imageStyle}
                        src={quest?.questImage ? quest?.questImage : MapDefault}
                    />
                </div>

                <div style={questTitle}>
                    {quest?.questName}
                </div>

                <HintComponent/>

            </div>

            {quest?.questViewed ? null : <CollectBadge isSelected={isSelected}/>}

        </Marker>
            
    );
}

export default React.memo(QuestLocationMarkerV2);

