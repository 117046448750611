import React, {useContext, useEffect, useRef, useState} from 'react';
import gift_unopened from "../../Images/gift_unopened.json";
import gift_open from "../../Images/gift_open.json";
import Lottie from '../Lottie';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import StyledAnimTest from './StyledAnimTest';
import reactDom from 'react-dom';
import CheckWinApi from '../../API/CheckWinApi';
import HeadingText from "../HeadingText";
import NormalText from "../common/NormalText";
import { QuestsContext } from '../../context/QuestsContext';
import ErrorIcon from '@mui/icons-material/Error';
import { useQuestContext } from '../../context/QuestContext';


function LottieWinAnimationV2({isOpen, closeModal}) {

    const{selectedQuest, updatePrizes} = useQuestContext()
    const goalID = selectedQuest?.goal?.[0]?.id;
    
    const [openAnimation, setOpenAnimation] = useState(false);
    const [complete, setComplete] = useState(false);
    const [hasWon, setHasWon] = useState(null)
    const apiData = useRef({}); 
    //const [isOpen, setIsOpen] = useState(false)

    async function handleClick(){
        console.log("handleClick")
        setOpenAnimation(true)
        try {apiData.current = await CheckWinApi(goalID);
            
            if(apiData.current?.data?.entrant_winner === true){
                setHasWon(true)
            } else{
                setHasWon(false)
            }
        
        }catch{
            
        }
    }

    useEffect(()=> {
        if(isOpen === true){
            handleClick()
        }
    }, [isOpen])

    function handleClose(){
        closeModal()
        updatePrizes(goalID) //This should be done after successful api call...but re-rendering is causing issues. 
    }


    function WinComponent(){
        return(
            console.log("WinComponent Ref", apiData?.current),
            <div 
                style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}
                onClick={handleClose}
            >
                <StarRoundedIcon style={{color:"gold"}} sx={{fontSize:60}}/>
                <NormalText>Congrates!</NormalText>
                <NormalText>You've won</NormalText>
                <HeadingText color="gold" fontSize={26} maxLines={2}>{apiData?.current?.data?.entrant_prize?.prize_name}</HeadingText>
            </div>
        )
    }

    function LoseComponent(){
        return (
            <div 
                style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}
                onClick={handleClose}
            >
                <SentimentDissatisfiedRoundedIcon style={{color:"grey"}} sx={{fontSize:60}}/>
                <NormalText>Better luck next time!</NormalText>

            </div>
            )
    }

    function ErrorComponent(){
        return (
            <div 
                style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}
                onClick={closeModal} //Dont need to update the entrant count if errors out
            >
                <ErrorIcon style={{color:"red"}} sx={{fontSize:60}}/>
                <div>Ooops, we've encountered a problem.</div>
            </div>
            )
    }
    
    return (
        <div>

            {isOpen && reactDom.createPortal(
                <div style={modalWrapper}>
                    <div style={container}>
                        <Lottie animation={gift_open} divID="gift_open" loop={false} onComplete={()=>setComplete(true)}/>
                        {complete && <StyledAnimTest>
                            {hasWon ? <WinComponent/> : ( !hasWon ? <LoseComponent/> : <ErrorComponent/>)}
                        </StyledAnimTest>}
                    </div>
                </div>, document.body)
            }
        </div>
    );
}

export default LottieWinAnimationV2 = React.memo(LottieWinAnimationV2)

const modalWrapper = {
    position: "fixed", 
    top:0, 
    bottom:0, 
    left:0, 
    right:0, 
    backgroundColor:"rgb(20,20,20,0.5)",
    zIndex:1001, 
}

const container = {
    display:"flex", 
    height:"100%", 
    width:"100%", 
    justifyContent:"center", 
    alignItems:"center"
}